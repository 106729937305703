.block-dashboard-company.block {
	.box-actions {
	    display: flex;
	    margin-bottom: 15px;
	    justify-content: space-between;
	}
}



/* To be Added to customer.scss */
.account-pages {
	.modal-container {
		@include visuallyhidden;
	}
}

.data-grid-filters-wrap {
    display: flex;
    margin-bottom: 30px;
}
.data-grid-filters-wrap .action {
  margin-right: 25px;
}
//
//  Variables
//  _____________________________________________



$links-container__z-index: 99;

//  Icons
$icon-circle-down: '\e93a';
$icon-circle-up: '\e93d';
$icon-customer-lead: '\e902';
$icon-move-vertical: '\e90b';
$icon-organization: '\e906';
$icon-person: '\e907';

$tree-icon__height: 20px;
$tree-icon__width: 20px;

//  Colors
$company-button__color: #ccc;
$company-button__hover__color: #ccc;
$exist-notification-message__color: #ccc;
$links-container__color: #ccc;
$popup-input__hover__color: #ccc;
$tree-icon__color__base: #ccc;
$tree-icon__color__dark: #ccc;
$tree-icon__color__light: #ccc;
$tree-leaf__background-color: #ccc;
$tree-leaf-clicked__background-color: #ccc;
$tree-leaf-clicked__color: #000;
$tree-tooltip__color: #ccc;
$nav-delimiter__border-color: #ccc;

$primary__color__light: #ccc;
$indent__s: 15px;
$indent__xs: 15px;
$indent__base: 15px;
$indent__m: 15px;
$indent__l: 15px;

//
//  Common
//  _____________________________________________

.jstree {
    & > ul ul {
        li {
            background: none;
            border-left: 1px dashed $primary__color__light;
            margin-left: 37px;
            padding: 5px 0;

            &.jstree-last {
                background: none;
                border-left: none;
                left: .1rem;
                padding-bottom: 0;
                position: relative;

                &:after {
                    border-left: 1px dashed $primary__color__light;
                    content: '';
                    height: 2.3rem;
                    left: -.1rem;
                    position: absolute;
                    top: 0;
                }
            }

            &.jstree-leaf {
                > .jstree-icon {
                    &.jstree-ocl {
                        background: none;
                        display: inline;
                        padding-right: 25px;
                        width: 2.5rem;

                        &:after {
                            right: 0;
                            top: 1.7rem;
                            width: 100%;
                        }
                    }
                }
            }

            > .jstree-icon {
                &.jstree-ocl {
                    position: relative;

                    &:after {
                        border-top: 1px dashed $primary__color__light;
                        content: '';
                        height: .1rem;
                        position: absolute;
						left: 0.4px;
						top: 10px;
						width: 1.3rem;
						margin: auto;
                    }
                }
            }
        }
    }

    li {
        & > .jstree-icon {
            padding-right: 1.5rem;
        }

        a {

            > .jstree-themeicon-custom {
                margin-left: $indent__s;
                margin-right: .7rem;
            }

            > .jstree-icon {
                &.icon-customer {
                	/*
                    .lib-icon-font-symbol(
                        $_icon-font-content: $icon-person
                    );
					*/
                    &:before {
                        font-size: 1.8rem;
                    }
                }

                &.icon-company {
                	/*
                    .lib-icon-font-symbol(
                        $_icon-font-content: $icon-organization
                    );
					*/
                    &:before {
                        font-size: 1.7rem;
                    }
                }
            }

            > .field-tooltip {
                color: $tree-tooltip__color;
                left: 100%;
                margin-left: $indent__xs;
                top: .4rem;
            }

            .field-tooltip-content {
                white-space: normal;
            }
        }

        &:not(.jstree-leaf) {
            > .jstree-icon {
                position: relative;
                top: 15px;
                z-index: 5;

                &:before {
					@include font-icon("\e90b");
					font-size: 1.9rem;
					line-height: 1.1;
					display: inline-block;
					vertical-align: middle;                    	
                    cursor: pointer;
                    text-align: center;
                }
            }
        }

        &.jstree-open {
            > .jstree-icon {
            	/*
                .lib-icon-font-symbol(
                	$_icon-font-content: $icon-circle-up;
                	@include font-icons("\e93a");
                );
                */
                @include font-icon-before($icon-circle-down);

                &:before {
                    color: $tree-icon__color__light;
                    font-size: 12px;
                    left: 25px;
                    position: relative;
                    width: 25px;
                    height: 25px;
                    line-height: 20px;
                    border: 2px solid #ccc;
                    border-radius: 50%;
                    background: #fff;
                }
            }
        }

        &.jstree-closed {
            > .jstree-icon {
            	/*
                .lib-icon-font-symbol(
                $_icon-font-content: $icon-circle-down
                );
                */
                @include font-icon-before($icon-circle-up);

                &:before {
                    color: $tree-icon__color__light;
                    font-size: 0.9rem;
                    left: 1.4rem;
                    position: relative;
				    width: 25px;
				    height: 25px;
				    line-height: 20px;
				    border: 2px solid #ccc;
				    border-radius: 50%;
				    background: #fff;
                }
            }
        }
    }

    > ul {
        > li {
            background: none;

            > a.company-admin {
                margin-left: -.2rem;
                padding: 0 15px 0 0;
				
                &:before {
                    display: none;
                }

                > .jstree-icon {

                }
            }

            &:not(.root-collapsible) {
                > .jstree-icon {
                    &.jstree-ocl {
                        display: none;
                    }
                }
            }
        }
    }
    .icon-customer {
        @include font-icon-before($icon-customer-lead);

        margin-right: $indent__s;
        position: relative;
        z-index: 5;

        &:before {
            display: inline-block;
            vertical-align: middle;
            font-size: 11px !important;
            border: 2px solid #000;
            border-radius: 50%;
            padding: 5px;
        }        
    }
    .jstree-leaf {
        > .jstree-icon.jstree-ocl {
            display: none;
        }
    }

    a {
        > .jstree-icon {
		    display: inline-block;
		    text-decoration: none;
		    background: none;
		    /*
            &:before {
            	content: "";
				font-size: 2.8rem;
				line-height: 1.1;
				display: inline-block;
				vertical-align: middle;
            }
            */
        }
    }

    &.jstree-draggable {
        a {
            &:before {
            	@include font-icon-before("\e90b");
				font-size: 2.8rem;
				line-height: 1.1;
				display: inline-block;
				vertical-align: middle;

                height: 1.8rem;
                margin-left: -.4rem;
                overflow: hidden;
                width: 1.9rem;
            }

            &.jstree-clicked {
                &:before {
                    color: $tree-leaf-clicked__color;
                }
            }
        }
    }

    i {
        font-style: normal;
    }

    .block-dashboard-company & {
        .jstree-clicked {
            background-color: $tree-leaf-clicked__background-color;
            color: $tree-leaf-clicked__color;

            > .jstree-icon {
                &:before {
                    color: $tree-leaf-clicked__color;
                }
            }
        }
    }

    .jstree-anchor {
        .jstree-checkbox {
            background-color: transparent;
            background-image: url(../images/icon-tree.png);
            background-position: top right;
            background-repeat: no-repeat;
            height: 16px;
            margin: 5px 8px -2px 4px;
            position: relative;
            width: 16px;
        }

        &.jstree-clicked {
            > .jstree-checkbox {
                background-position: top center;
            }
        }

        > .jstree-checkbox {
            &.jstree-undetermined {
                background-position: top left;
            }
        }
    }
}

.jstree-checkbox-selection {
    .jstree-anchor {
        display: inline-block;
        text-decoration: none;
    }
}

//
//  Jstree default (!) styles start
//  ---------------------------------------------

.jstree-node,
.jstree-children,
.jstree-container-ul {
    display: block;
    list-style-type: none;
    list-style-image: none;
    margin: 0;
    padding: 0;
}

.jstree-node {
    white-space: nowrap;
}

.jstree-anchor {
    display: inline-block;
}

#jstree-marker {
    position: absolute;
    top: 0;
    left: 0;
    margin: -5px 0 0 0;
    padding: 0;
    border-right: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid;
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
}

#jstree-dnd {
    line-height: 16px;
    margin: 0;
    padding: 4px;
}

#jstree-dnd .jstree-icon,
#jstree-dnd .jstree-copy {
    display: inline-block;
    text-decoration: none;
    margin: 0 2px 0 0;
    padding: 0;
    width: 16px;
    height: 16px;
}

#jstree-dnd .jstree-ok {
    background: green;
}

#jstree-dnd .jstree-er {
    background: red;
}

#jstree-dnd .jstree-copy {
    margin: 0 2px 0 2px;
}

//
//  Jstree default styles end
//  ---------------------------------------------

.form-edit-role,
.block-dashboard-company {
    .block-title {
        margin-top: $indent__base;
    }

    .box-actions {
        button.action {
            //@extend .abs-account-actions;
            background: none;
            border: 0;
            color: $company-button__color;
            padding: 0 $indent__m $indent__s 0;
            position: relative;

            &:hover,
            &:active {
                background: none;
                border: 0;
                box-shadow: none;
                color: $company-button__hover__color;
            }

            &:not(:focus) {
                box-shadow: none;
            }

            &:after {
                position: absolute;
                right: -.2rem;
                top: .2rem;
                width: .1rem;
            }

            &.delete {
                &:after {
                    display: none;
                }
            }

            &._hidden {
                display: none;
            }
        }
    }
}

.form-edit-account {
    .fieldset {
        &.info {
            margin-right: $indent__base;
        }

        &.company {
            clear: both;
            float: left !important; // Unfortunately required here
            margin-bottom: $indent__base;
        }

        &.job_title {
            clear: both;
            float: left !important; // Unfortunately required here
        }
    }
    .actions-toolbar {
        clear: both;
    }
}

.company-account-index {
    .columns {
        .column.main {
            .block:not(.widget) {
                //@extend .abs-account-blocks;

                .block-title {
                    //@extend .abs-login-block-title;
                    font-size: 18px;
                }
            }
        }
    }
}

.message {
    &.company-warning {
        //.lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;

        &._hidden {
            display: none;
        }
    }
}
.additional-fields {
    margin-top: $indent__base;

    &._hidden {
        display: none;
    }
}

.modal-container {
    display: none;
}

.data-grid-filters-wrap {
    margin-bottom: $indent__s;
    //margin-top: -$indent__s * 1.5;

    .action {
        //@extend .abs-action-button-as-link;
        margin-right: $indent__m;
    }
}

.modal-popup {
    .action {
        &._hidden {
            display: none;
        }
    }
}

.popup-tree {
    .modal-footer {
        .action-primary {
            //.lib-button-primary();
            box-shadow: none;
        }
    }
}

.company-profile-payment-methods {
    //font-size: 1.6rem;
    //line-height: 24px;
    display: flex;
    justify-content: space-between;
    //.lib-vendor-prefix-display(flex);

    .payment-methods-label {
        font-weight: 700;
        margin-bottom: $indent__s;
    }

    .payment-methods-list {
        list-style: none;
    }
}

.wrap-text {
    margin-bottom: $indent__l;
}

.form-add-user {
    .field {
        &.email {
            .exist-notification-message {
                color: $exist-notification-message__color;
                font-size: 14px;
                padding-top: $indent__xs;
            }
        }
    }
}
.requisition-list-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.requisition-list-description {
    display: block;
    width: 100%;
}
.company-account-create {
     .buying-group-number {
        display: flex;
        flex-direction: column-reverse;
    }
}
//
//  Desktop
//  ---------------------------------------------

@include media-breakpoint-up(md) {
    .modal-popup {
        .actions-toolbar {
            .secondary {
                .action {
                    background: none;
                    border: none;
                    color: $company-button__color;
                    
                    //margin: .7rem 1.5rem;
                    padding: 0;

                    &:active,
                    &:not(:focus) {
                        box-shadow: none;
                    }
                }
            }
        }

        input,
        textarea {
            &:hover {
                border-color: $popup-input__hover__color;
            }
        }

        &.popup-tree {
            .modal-inner-wrap {
                .modal-footer {
                    border-top: none;
                    text-align: left;
                }
            }
        }
    }

    .popup-tree {
        .fieldset {
            margin-bottom: 0;
        }

        .modal-footer {
            .primary {
                margin-right: $indent__s;
            }

            .secondary {
                background: none;
                border: none;
                color: $company-button__color;
                
                margin: .7rem 1.5rem;
                padding: 0;

                &:active,
                &:not(:focus) {
                    box-shadow: none;
                }
            }
        }
    }

    .page-title {
        .edit-company-profile {
            left: -$indent__s;
            position: relative;
        }
    }

    .company-profile-index {
        .column {
            &.main {
                .block {
                    .box-account-information,
                    .box-address-information,
                    .box-contact-information {
                        .box-content {
                            //@extend .abs-account-block-font-size;
                            line-height: 24px;
                        }
                    }
                }
            }
        }
    }

    .company-profile-edit {
        .column.main {
            .form-edit-company {
				display: flex;
				flex-wrap: wrap;

                .fieldset,
                .actions-toolbar {
                    width: 52%;
                }
            }
        }
    }

    .company-account-index {
        .columns {
            .column.main {
                .block:not(.widget) {
                    .box-content {
                        strong {
                            display: inline-block;
                            width: 17%;
                        }
                    }
                }
            }
        }

    }

    .company-account-create {
        .columns {
            .main {
                .form-create-account {
                    //width: 51%;
                    @include make-container(); 
                    @include make-col-ready();
                    margin: 40px 0 0;   
                    max-width: unset !important;
                    .fieldset-fullname {
                        .fields {
                            .field {
                                &:last-child {
                                    margin: 0 0 $indent__s;
                                }
                            }
                        }
                    }
                    .fieldset.info {
                        margin: 0 40px;
                    }
                    .company-legend {
                        font-size: 22px;
                    }
                }
            }
        }
		.fieldset > .legend {
		    display: block;
		    margin: 0 0 20px;
		    padding: 0 0 10px;
		    width: 100%;
		    box-sizing: border-box;
		    float: left;
		    font-weight: 300;
		    line-height: 1.2;
		    font-size: 1.8rem;
		    border-bottom: 1px solid #c6c6c6;
		}       
		.sub-title {
			display: block;
			text-align: center;
    		font-size: 16px;
		}
        .actions-toolbar {
            margin-left: 40px;
        }
       
    }

    .company-users-index {
        .long-text-field {
            //.lib-wrap-words();

        }
    }

    .jstree {
        li {
            a {
                position: relative;
                top: 18px;
                margin-left: 10px;
            }
        }
    }
}

//
//  Mobile
//  ---------------------------------------------

@include media-breakpoint-down(md) {
    .links-container-title {
        display: none;
    }

    .company-profile-index {
        &.account {
            .page-main {
                .page-title-wrapper {
                    .page-title {
                        display: inline-block;
                        margin-right: $indent__s;
                    }
                }
            }
        }

        .company-profile-payment-methods {
            //.lib-vendor-prefix-flex-direction(column);
			flex-direction: column;
            .payment-methods-list {
                font-size: 1.4rem;
                padding-left: 0;
            }
        }
    }

    .company-account-index {
        .column.main {
            .block:not(.widget) {
                .block-title {
                    //@extend .abs-add-clearfix-mobile-m;

                    > .action {
                        //.lib-css(margin-top, $indent__xs);
                        float: right;
                    }
                }

                .block-content {
                    strong {
                        float: left;
                        margin-right: 5px;
                    }

                    .box-content {
                        span {
                            display: block;
                            overflow: hidden;
                            vertical-align: top;
                        }
                    }

                    .box {
                        //.lib-css(margin-bottom, $indent__base);

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .company-users-index,
    .company-role-index {
        .table-wrapper {
            .table {
                &:not(.cart) {
                    > tbody {
                        > tr {
                            td {
                                &:last-child {
                                    &:before {
                                        content: '';
                                        padding: 0;
                                    }
                                }

                                > div {
                                    display: inline;
                                }
                            }

                            &.data-grid-tr-no-data {
                                td {
                                    &:before {
                                        content: '';
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .admin__data-grid-pager {
            margin-top: $indent__base;
        }

        .toolbar-amount {
            position: relative;
        }
    }

    .table-wrapper {
        .table:not(.cart) {
            &> tbody {
                &> tr {
                    td {
                        &.actions {
                            &.col {
                                &:before {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .modals-wrapper {
        .modal-popup {
            .modal-header {
                padding-top: 2rem;
            }

            .modal-title {
                border-bottom: none;
                font-size: 14px;
            }
        }
    }

    .popup-tree {
        .modal-footer {
            .action,
            .action-primary {
                display: block;
                margin-bottom: $indent__s;
                width: 100%;
            }
        }
    }

    .jstree {
        li {
            a {
                > .field-tooltip {
                    float: right;
                    margin-right: -4rem;
                    position: static;

                    .field-tooltip-content {
                        left: 0;
                        max-width: 90%;
                        right: auto;
                        top: 4.5rem;
                        z-index: 20;

                        &:before,
                        &:after {
                            left: 6.1rem;
                            right: auto;
                        }
                    }
                }
            }
        }
    }
}

//
//  Mobile for modal
//  ---------------------------------------------

@include media-breakpoint-down(md) {
    .modals-wrapper {
        .modal-popup {
            .modal-inner-wrap {
                width: 90%;
                height: 320px;
                margin: 0 auto;
            }
            h3{
                font-size: 18px;
            }
        }
    }
}

//
//  Quotes page
//  ---------------------------------------------

.quote-name {
    display: block;
    width: 100%;
}
.quote-view-links .action {
    margin-right: 20px;
    text-decoration: underline;
}
.quote-view-buttons {
    display: flex;
}
.quote-status,
.return-status,
.order-status {
  border: 2px solid #cccccc;
  border-radius: 3px;
  display: inline-block;
  margin: 3px 0 0;
  padding: 2px 10px;
  text-transform: uppercase;
  vertical-align: top;
}
.quote-table-items.table {
	td.qty .label {
	    display: none;
	}	
	.actions-toolbar {
	    margin: 0;
	}	
	th, td {
		padding: 10px 0;
	}
}

//
//  Spinner
//  ---------------------------------------------

.admin__data-grid-loading-mask {
    background: rgba(255, 255, 255, 0.5);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 4;
	.spinner {
	    background: url(../images/loader-2.gif) 50% 50% no-repeat;
	    display: inline-block;
	    font-size: 4rem;
	    height: 1em;
	    left: 50%;
	    margin-left: -2rem;
	    margin-right: 1.5rem;
	    position: absolute;
	    top: 50%;
	    width: 1em;
	}    
}
//
//  company account index
//  ---------------------------------------------
.company-account-index {
    .page-title-wrapper {
        margin-bottom: 25px;
        h1 {
            @include media-breakpoint-down(md) {
                font-size: $font-xl;
            }
        }
    }
    .columns {
        @include make-container(); 
    }
    .column{
        &.main {
            @include make-row(); 
        }
    }
    .block {
       @include make-col-ready();
        text-align: left;
        margin-bottom: $margin;
        @include media-breakpoint-up(md) {
            @include make-col(6);
        }
        @include media-breakpoint-down(md) {
            @include make-col(12);
        }
        
    }
    .company-address-container {
        margin: 10px 0;
    }
    
}

#company-tree {
    > ul ul li {
        margin-left: 22px;
        padding: 0 0 10px;
    }

    li a {
        margin: 0;
        padding: 10px;
        > .jstree-themeicon-custom {
            margin-left: 0;
            margin-right: 10px;
        }            
    }
}