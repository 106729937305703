
//  Full Width row appearance styles
//  ________________________________

[data-content-type='row'][data-appearance='full-width'] {
    > .row-full-width-inner {
        @include make-container();

            padding: 0 !important;

    }

    @include media-breakpoint-down(md) {
        margin-top: 0 !important;
    }
}

//  Full Bleed row appearance styles
//  ________________________________

[data-content-type='row'][data-appearance='full-bleed'] {}

//  Contained row appearance styles
//  _______________________________

[data-content-type='row'][data-appearance='contained'] {  
    @include make-container();
    @include media-breakpoint-down(md) {
        padding:0 !important;
    }
}

@include media-breakpoint-down(sm) {
    [data-content-type='row'][data-appearance='full-width'] {
        background-attachment: scroll !important;
        margin-bottom: 0 !important;
        // padding: 57px 15px 60px !important;
        &.banner-bg {
            padding: 0 !important;
        }
        &.home-catalog-container {
            padding: 20px 14px!important;
        }
        &.about-bottom-container {
            padding: 20px 0 !important;
            @include media-breakpoint-down(md) {
                padding: 0 !important;
            }
        }
        &.home-product-bg {
            padding: 0 !important;  
        }
        &.home-blog {
            padding: 75px 0 0 !important;
            background: transparent !important;      
            figure {
                margin: 0;
            }
        }
    }
    [data-content-type='row'][data-appearance='full-bleed']:not(.home-slider-wrap) {
        background-attachment: scroll !important;
        min-height: 375px;
        margin-bottom: 40px !important;
        @include media-breakpoint-down(sm){
            min-height: unset !important;
            margin-bottom: 0 !important;
        }
        &.look-book-container {
            min-height: unset !important;
        }
    }
    [data-content-type='row'][data-appearance='contained'] {
        [data-element='inner'] {
            background-attachment: scroll !important;
            @include media-breakpoint-down(md) {
                margin: 0 auto  !important;
            }
        }
    }
}
@include media-breakpoint-between(sm,md) {
    [data-content-type='row'][data-appearance='full-bleed'].look-book-container {
        height: 1285px !important;
    }
}
@include media-breakpoint-between(md,lg) {
    [data-content-type='row'][data-appearance='full-bleed'].look-book-container {
        height: auto !important;
    }
    .home-product-bg .pagebuilder-column {
        background: #eee;
    }
}

@include media-breakpoint-down(md) {
    .home-product-bg .row-full-width-inner {
       max-width: 100% !important;
    }
}
/////////////////
//
//  columns
//  _____________________________________________
.pagebuilder-slider.slick-slider.slick-dotted {
    @include media-breakpoint-down(md) {
        margin:0 0 10px !important;  
    }
}
.pagebuilder-column {
    @include media-breakpoint-down(md) {
    }
}
.first-brands-text p {
    font-family: $font_family-primary-bold;
    font-size: 12px;
    color: #b8b8b8;
    @include media-breakpoint-down(sm) {
        padding:0 0 24px!important;  
        display: block;
        width: 100%;
        text-align:center;
    }
}
.brands {
    @include media-breakpoint-down(sm) {
        max-width: 100% !important;
        .pagebuilder-column-group {
            margin-top: 0;
            justify-content: center;
        }
    }

}
.brand-image {
    @include media-breakpoint-down(sm) {
        max-width: 50%;
        margin-bottom: 22px !important;
    }
}
@include media-breakpoint-down(sm) {
    .pagebuilder-column {
        background-attachment: scroll !important;
        flex-basis: 100%;
    }
    .pagebuilder-column-group {
        flex-wrap: wrap;
        position: relative;
    }
}
/////////////////
@include media-breakpoint-up(sm) {
    .pagebuilder-mobile-only {
        display: none !important;
    }
}

@include media-breakpoint-down(xs) {
    .pagebuilder-mobile-hidden {
        display: none !important;
    }
}

//////////
// Banner
////////////////
[data-appearance='collage-centered'] {
    .pagebuilder-banner-wrapper {
        .pagebuilder-overlay {
            margin-left: auto;
            margin-right: auto;
        }
    }
}
[data-appearance='collage-left'] {
    .pagebuilder-banner-wrapper {
        .pagebuilder-overlay {
            margin-right: auto;
        }
    }
}

[data-appearance='collage-right'] {
    .pagebuilder-banner-wrapper {
        .pagebuilder-overlay {
            margin-left: auto;
        }
    }
}
//
[data-content-type='banner'] > [data-element='link'],
[data-content-type='banner'] > [data-element='empty_link'] {
    color: inherit;
    text-decoration: inherit;

    &:hover {
        color: inherit;
        text-decoration: inherit;
    }
}

.pagebuilder-banner-wrapper {
    background-clip: padding-box;
    border-radius: inherit;
    overflow-wrap: break-word;
    word-wrap: break-word;

    .pagebuilder-overlay {
        -moz-transition: background-color 500ms ease;
        -o-transition: background-color 500ms ease;
        -webkit-transition: background-color 500ms ease;
        padding: 30px;   
        transition: background-color 500ms ease;

        &.pagebuilder-poster-overlay {
            align-items: center;
            display: flex;
            justify-content: center;
        }

        &:not(.pagebuilder-poster-overlay) {
            height: 100%;
            display: flex;
            align-items:center;
        }
    }

    [data-element='content'] {
        min-height: 50px;
    }

    .pagebuilder-banner-button {
        -moz-transition: opacity 500ms ease;
        -o-transition: opacity 500ms ease;
        -webkit-transition: opacity 500ms ease;
        margin: 20px 0 0 0;
        max-width: 100%;
        text-align: inherit;
        transition: opacity 500ms ease;
        word-break: break-word;
    }
}
@include media-breakpoint-down(sm) {
    .pagebuilder-banner-wrapper {
        background-attachment: scroll !important;

        .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
            max-width: none;
        }
    }
}
.pagebuilder-banner-wrapper {
    .pagebuilder-poster-content {
        width: 100%;
    }
}
.pagebuilder-collage-content{
    background: #ffb02f;
    min-height: 430px;
    width: 445px;
    position: absolute;
    bottom:39px;
    padding: 19px 39px 33px 32px;
    font-weight: normal;
    text-align: left;
    color: $secondary-colour;
    font-family: $font_family-primary;
    @include media-breakpoint-down(md) {
        margin-top: 0;
        min-height: 220px;
        margin-left: 0 !important ;
        width: 348px;
        padding: 20px 16px 0;
        bottom:22px;
    }
    h2{
        font-size: 32px;
        line-height: 1.41;
        padding-bottom: 14px; 
        letter-spacing: 1px;  
        text-transform: uppercase;
        font-weight: normal;
        font-family: $font_family-primary;
        @include media-breakpoint-down(md) {
            font-size: 24px;
            margin-bottom: 32px;
        }
    }
    span {
        padding-bottom:20px;
        display: block;
        line-height: 1.88;
        font-weight: normal;
        font-family: $font_family-primary;
    }
    p{
        display: block;
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}
//
// block
/////////////
[data-content-type$='block'] {
    .block {
        p:last-child {
            margin-bottom: 1rem;
            margin-top: 0;
        }
    }
}
//
// Map
/////////////
[data-content-type='map'] {
    height: 300px;
}
////////sponser logo/////////
.sponser-logo {
    display: block;
    @include media-breakpoint-down(md) {
        padding: 0 !important;
        margin: 0 !important;   
    }
}
//////////////
// Slide
/////////////
[data-appearance='collage-centered'] {
    .pagebuilder-slide-wrapper {
        .pagebuilder-overlay {
            margin-left: auto;
            margin-right: auto;
        }
    }
}
[data-appearance='collage-left'] {
    .pagebuilder-slide-wrapper {
        .pagebuilder-overlay {
            margin-right: auto;
            padding:0;
        }
    }
}
[data-appearance='collage-right'] {
    .pagebuilder-slide-wrapper {
        .pagebuilder-overlay {
            margin-left: auto;
        }
    }
}
[data-content-type='slide'] {
    min-height: inherit;
    overflow: hidden;

    > [data-element='link'],
    > [data-element='empty_link'] {
        color: inherit;
        min-height: inherit;
        text-decoration: inherit;

        &:hover {
            color: inherit;
            text-decoration: inherit;
        }
    }
    .pagebuilder-slide-wrapper {
         @include media-breakpoint-down(md) {
            padding:0 !important;
            min-height: 375px !important;
            position: relative;
        }
        .pagebuilder-overlay {
            @include media-breakpoint-down(md) {
            padding:33% 14px 0 ;
            }
        }
    }
}

.pagebuilder-slide-wrapper {
    border-radius: inherit;
    min-height: inherit;
    overflow-wrap: break-word;
    word-wrap: break-word;

    .pagebuilder-overlay {
        -moz-transition: background-color 500ms ease;
        -o-transition: background-color 500ms ease;
        -webkit-transition: background-color 500ms ease;
        border-radius: inherit;
        padding: 30px;
        transition: background-color 500ms ease;

        &.pagebuilder-poster-overlay {
            align-items: center;
            display: flex;
            justify-content: center;
            min-height: inherit;
            @include media-breakpoint-down(sm) {
                min-height: 200px !important;
            }
        }

        &:not(.pagebuilder-poster-overlay) {
            height: 100%;
            display: flex;
            align-items:center;
        }
    }

    [data-element='content'] {
        min-height: 50px;
    }

    .pagebuilder-slide-button {
        -moz-transition: opacity 500ms ease;
        -o-transition: opacity 500ms ease;
        -webkit-transition: opacity 500ms ease;
        margin:0;
        max-width: auto !important;
        width: auto;
        text-align: inherit;
        transition: opacity 500ms ease;
        word-break: break-word;
        color: $secondary-colour;
        padding: 0 18px;
        height: 40px;
        line-height: 37px;
        border: 1px solid;
        font-size: 16px;
        font-family: $font_family-primary-bold;
        border: 2px solid $secondary-colour;
        margin: 0 auto;
        margin-top: 10px;   
        @include media-breakpoint-down(md) {
            margin-bottom: 15px;
            font-size: 14px;
            height: 35px;
            width: 135px;
            line-height: 30px;
        }
    }
}
.pagebuilder-slide-wrapper {
    .pagebuilder-poster-content {
        width: 100%;
    }
}

@include media-breakpoint-down(sm) {
    .pagebuilder-slide-wrapper {
        .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
            max-width: none;
        }
    }
}



///////////////
// slider
///////////
.home-product-slider {
    .slick-dots {
        @include media-breakpoint-down(sm) { 
            bottom: 24px !important;
        }
    }
}
div[data-content-type='slider'] {
    > div:not(:first-child) {
        display: none;
    }
    &.slick-initialized {
        display: block;
        //margin-bottom: 25px !important; // required due to inline styles added by Slick
        @include media-breakpoint-down(sm) {
            min-height: 200px !important;
        }
    }
    .slick-dots {
        bottom: 72px;
        text-align: left;
        position: absolute;
        width: initial;
        @include media-breakpoint-down(md) {
            top: 48%;
            left: 30px !important;
            bottom: 0;
        }
        li {
            button {
            font-size: 0;
            padding:0;
                &:before {
                    content: "";
                    display: block;
                    color: #ffb02f;
                    border: 2px solid #fff;
                    border-radius: 50%;
                    width: 16px;
                    height: 16px;
                    opacity: 1;
                    line-height: 15px;
                    padding:0;
                    background: transparent;
                }
            }
            &.slick-active {  
                button {
                    &:before {
                        content: "";
                        display: block;
                        background: #fff;
                        border: 2px solid transparent;
                        border-radius: 50%;
                        width: 16px;
                        height: 16px;
                        opacity: 1;
                        line-height: 15px;
                    }
                }
            }
        }
    }
    .slick-list,
    .slick-track,
    .slick-slide {
        min-height: inherit;
        @include media-breakpoint-down(sm) {
            min-height: 200px !important;
        }
        > div {
            min-height: inherit;
            overflow: hidden;
            width: 100%;
            @include media-breakpoint-down(md) {
                min-height: 375px !important;
            }
        }
    }
}

.pagebuilder-product-slider {
    .product-items{
        position:static;
    }
}
.pagebuilder-column-group {
    position: relative;
    flex-wrap: wrap;
    @include make-row();
}
.blog-home-container {
    .pagebuilder-column-group {
        @include media-breakpoint-up(md) {
            margin-top: -60px;
        }
    }
}
a.button {   
    /*
    -moz-appearance: button;
    -webkit-appearance: button;
    appearance: button;
    color: initial;
    padding: 10px;
    text-decoration: none;
    */
}
///////////////
// tabs
div[data-content-type='tabs'] {
    .tabs-navigation {
        display: block;
        font-size: 0;
        margin: 0 0 -1px;
        padding: 0;

        li:first-child {
            margin-left: 0 !important;
        }

        li.tab-header {
            
            border: 1px solid #ccc;
            border-bottom: 0;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            display: inline-block;
            margin: 0;
            max-width: 100%;
            overflow-wrap: break-word;
            position: relative;
            word-wrap: break-word;
            z-index: 1;

            &:not(:first-child) {
                margin-left: -1px;
            }

            a.tab-title {
                border-right: 0;
                
                cursor: pointer !important;
                display: block;
                font-size: 14px;
                font-weight: 600;
                padding: 1.4rem 2rem;
                position: relative;
                transition: all .3s;
                vertical-align: middle;
                white-space: normal;

                span.tab-title {
                    display: block;
                }

                &:hover {
                    text-decoration: none;
                }
            }

            &:last-child {
                border-right: 1px solid #ccc;
            }

            &.ui-state-active {
                background: #fff;
                z-index: 19;

                a.tab-title {
                    position: relative;
                    transition: all .3s;
                }
            }
        }
    }

    .tabs-content {
        border: 1px solid #ccc;
        overflow: hidden;
        position: relative;
        z-index: 9;

        [data-content-type='tab-item'] {
            min-height: inherit;

            &:not(:first-child) {
                display: none;
            }
        }
    }

    &.tab-align-left {
        .tabs-content {
            border-top-left-radius: 0 !important;
        }
    }
    &.tab-align-right {
        .tabs-content {
            border-top-right-radius: 0 !important;
        }
    }
}
@include media-breakpoint-down(sm) {
    [data-content-type='tab-item'] {
        background-attachment: scroll !important;
    }
}

/// Video
div[data-content-type='video'] {
    .pagebuilder-video-inner {
        box-sizing: border-box;
        display: inline-block;
        width: 100%;
    }

    .pagebuilder-video-container {
        border-radius: inherit;
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;
    }

    iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

