//
//  Lib -> Components -> Modals
//  _____________________________________________

//
//  Variables
//  ---------------------------------------------

$modal__background-color                         : #fff !default;
$modal__box-shadow                               : 0 0 12px 2px rgba(0, 0, 0, 0.35) !default;

$modal-popup__indent-vertical                    : 5rem !default;
$modal-popup__padding                            : 20px !default;
$modal-popup__width                              : 45% !default;
$modal-popup__z-index                            : 9999 !default;

$modal-slide__first__indent-left                 : 14.8rem !default;
$modal-slide__indent-left                        : 4.5rem !default;
$modal-slide__padding                            : 20px !default;
$modal-slide__z-index                            : 9999 !default;

$modal-slide-header__padding-vertical            : 2.1rem !default;

$modal-popup-confirm__width                      : 50rem !default;

$modal-popup-image-box__border-color             : #ccc !default;
$modal-popup-image-box__max-width                : 78rem !default;

$modal-popup-image-box-preview-image__max-height : 54rem !default;
$modal-popup-image-box-preview__max-width        : 50% !default;

//
//  Utilities
//  ---------------------------------------------
@mixin lib-css(
    $_property,
    $_value,
    $_prefix: 0
) {
    @if $_value == 0 {
        $_value: strip-unit($_value);
    }

    @if $_value != '' and $_value != false {
        @if type-of($_value) == list {
            $_isFalse: false;
            @each $_item in $_value {
                @if $_item == false {
                    $_isFalse: true;
                }
            }
            @if $_isFalse == false {
                #{$_property}: $_value;
            }
        }
        @else {
            #{$_property}: $_value;
        }
    }
}

@mixin lib-modal() {
    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.3s, opacity 0.3s ease;

    &._show {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.3s ease;

        .modal-inner-wrap {
            transform: translate(0, 0);
        }
    }

    .modal-inner-wrap {
        @include lib-css(background-color, $modal__background-color);
        opacity: 1;
        pointer-events: auto;
        border: 1px solid #000;
    }
}
//
//  Flex layout
//  ---------------------------------------------

@mixin lib-vendor-prefix-display(
    $_value: flex
) {
    display: $_value;
}

@mixin lib-vendor-prefix-flex-grow(
    $_value: 0
) {
    flex-grow: $_value;
}

@mixin lib-vendor-prefix-flex-shrink(
    $_value: 1
) {
    flex-shrink: $_value;
}

@mixin lib-vendor-prefix-flex-basis(
    $_value: auto
) {
    flex-basis: $_value;
}

@mixin lib-vendor-prefix-flex-wrap(
    $_value: wrap
) {
    flex-wrap: $_value;
}

@mixin lib-vendor-prefix-flex-direction(
    $_value: column
) {
    flex-direction: $_value;
}

@mixin lib-vendor-prefix-order(
    $_value: 0
) {
    order: $_value;
}

@mixin lib-vendor-box-align(
    $_value: stretch
) {
    align-items: $_value;
}

@mixin lib-vendor-prefix-appearance(
    $value: none
) {
    appearance: $value;
}

@mixin lib-vendor-prefix-column-count(
    $_value
) {
    column-count: $_value;
}

@mixin lib-modal-slide() {
    @include lib-css(left, $modal-slide__first__indent-left);
    @include lib-css(z-index, $modal-slide__z-index);

    &._show {
        .modal-inner-wrap {
            transform: translateX(0);
        }
    }

    .modal-inner-wrap {
        height: 100%;
        overflow-y: auto;
        position: static;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
        width: auto;
        text-align: left;
    }
}

@mixin lib-modal-popup() {
    @include lib-css(z-index, $modal-popup__z-index);
    left: 0;
    overflow-y: auto;

    &._show {
        .modal-inner-wrap {
            transform: translateY(0);
        }
    }

    .modal-inner-wrap {
        @include lib-css(margin, auto);
        @include lib-css(width, $modal-popup__width);
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        box-sizing: border-box;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        transform: translateY(-200%);
        transition: transform 0.2s ease;

    }
}

body {
    &._has-modal {
        height: 100%;
        overflow: hidden;
        width: 100%;
    }
}

//  Modals overlay
.modals-overlay {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.modal-slide,
.modal-popup {
    @include lib-modal();
    display: flex;
    align-items: center;
}

.modal-slide {
    @include lib-modal-slide();
    &._inner-scroll {
        .modal-inner-wrap {
            overflow-y: visible;
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-prefix-flex-direction(column);
        }

        .modal-header,
        .modal-footer {
            @include lib-vendor-prefix-flex-grow(0);
            @include lib-vendor-prefix-flex-shrink(0);
            margin: 0 auto;
        }

        .modal-content {
            overflow-y: auto;
            text-align: center;
        }

        .modal-footer {
            margin-top: auto;
        }
    }

    .modal-header,
    .modal-content,
    .modal-footer {
        @include lib-css(padding, 0 $modal-slide__padding $modal-slide__padding);
    }

    .modal-header {
        @include lib-css(padding-bottom, $modal-slide-header__padding-vertical);
        @include lib-css(padding-top, $modal-slide-header__padding-vertical);
    }
}

.modal-popup {
    @include lib-modal-popup();

    //  If applied, switching outer popup scroll to inner
    &._inner-scroll {
        overflow-y: visible;

        .ie11 &,
        .ie10 &,
        .ie9 & {
            overflow-y: auto;
        }

        .modal-inner-wrap {
            max-height: 90%;

            .ie11 &,
            .ie10 &,
            .ie9 & {
                max-height: none;
            } 
        }

        .modal-content {
            overflow-y: auto;
            text-align: left;
        }
    }

    .modal-header,
    .modal-content,
    .modal-footer {
        @include lib-css(padding-left, $modal-popup__padding);
        @include lib-css(padding-right, $modal-popup__padding);
        font-size: 16px;
        text-align: left;
    }

    .modal-header,
    .modal-footer {
        @include lib-vendor-prefix-flex-grow(0);
        @include lib-vendor-prefix-flex-shrink(0);
    }

    .modal-header {
        @include lib-css(padding-bottom, $modal-popup__padding);
        @include lib-css(padding-top, $modal-popup__padding);
        display: flex;
        margin-left: auto;
        justify-content: space-between;
        width: 100%;
        .action-close {
            padding: 0 20px;
            margin-left: auto; 
            height: 16px;           
            span {
                color: #000;
                font-size: 0;
                border:0;
                padding: 0;
                line-height: normal;
                height: initial;
                &:before{
                    @include font-icon('\e90b');
                    font-size: 16px;
                    display: inline-block;
                }
                &:hover {
                    background:transparent;
                    color: #000;
                }
            }
        }
    }

    .modal-footer {
        @include lib-css(padding-bottom, $modal-popup__padding);
        @include lib-css(padding-top, $modal-popup__padding);
        margin: 0 auto;
        display: flex;
        text-align: center;
        width: 100%;
        justify-content: space-between;
        //padding-top: 0;
        flex-direction: row-reverse;   
    }
    .modal-content {
        p {
            font-size: 14px;
        }
    }
    .actions-toolbar {
        display: flex;
        justify-content: space-between;
        > .primary {
            display: flex;
            button:first-child:not(:last-child) {
                margin-right: 13px;
            }      
        }    
    } 
    .modal-footer-actions {
        text-align: right;
    }
}
.modal-popup.popup-reorder-quote .modal-inner-wrap {
    height: auto !important;       
}
//
//  Mobile
//  _____________________________________________

//  Mobile transform to modal-slide
@media (max-width: 992px) {
    .modal-popup {
        &.modal-slide {
            @include lib-modal-slide();
            left: 0;
            .modal-inner-wrap {    
                margin: 0;
                max-height: none;
            } 
        }
        &.popup-reorder-quote .modal-inner-wrap {
            height: auto !important;
        }        
    }
}