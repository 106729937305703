/* ============================================ *
* Footer
* ============================================ */

// Variables 


// Footer Options 
$footer_cols: 4;
$footer_social_icons: true;
$footer_languages: true;
$footer_backToTop: true;

// Footer Section
$footer_background-color: #fff;
$footer_border: 2px solid #dddd;
$footer_padding-top: 60px;
$footer_font-size: 12px;
$footer_title-font-size: 20px;
$footer_color: #9b9b9b;
$footer_title-color: #4a4a4a;
$footer_links-color: $footer_color;
$footer_links-hover-color: $footer_color;

// Footer Last
$footer_last_font-size: 12px;


/* ============================================ *
* Global
* ============================================ */
.footer-container {
  @include make-fluid-container;
  padding: 0;
  background: $footer_background-color;
  color: $footer_color;
  font-size: $footer_font-size;
  text-align: left;
}

.footer {
  @include make-container;
  padding: 44px 0 0;
  @include media-breakpoint-down(sm) {
    padding: 32px 0 0;
  }
  a {
    color: $primary-colour;
  }
  .icon-facebook , .icon-twitter {
    font-size: 14px;
    @include media-breakpoint-down(md) {
      font-size: 26px;
    }
  }
}

.footer-row {
  @include make-row;
  @include media-breakpoint-down(md) {
    padding:0 30px;
    margin: 0;   
  }
}
.footer-row-last{
  display: flex;
  margin-top: 30px;
  @include media-breakpoint-down(md) {
    display: block;
    margin-top: 0;
  }
}
.logo-footer {
  @include media-breakpoint-down(md) {
    text-align: center;
  }
  .mobile-only{
    margin: 0 auto;
  }
 
}
.mobile-footer-links {
  display: none;
  @include media-breakpoint-down(md) {
    display: block;
    font-size: 16px;
    font-family: $font_family-primary-bold;
    padding: 22px 0;
    color: $primary-colour;
    &:before {
      @include font-icon('\e934');
      position: absolute; 
      right: 4px;
      top: 25px;
      font-size: 14px;
    }
    &.links-open {
      &:before {
        @include font-icon('\e93a');
        font-size: 14px;
      }
    }
  }
}
.footer-col {
  @include make-col-ready;
  border-right: 2px solid #dddd;
  padding: 13px 86px 13px;  
  @include media-breakpoint-down(md) {
    border-right: 0;
    border-bottom: 2px solid #dddd;
    padding: 0;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(3);
  }
  &:first-child{
    padding: 42px 46px 0;
    @include media-breakpoint-down(sm) {
      padding: 0 ;
      max-width: 100% !important;
    }
  }
   &:last-child{
    border: 0;
    @include media-breakpoint-down(md) {
      border-bottom: 2px solid #dddd;
    }
  }

  ul {
    padding: 0;
    list-style: none;
    display: block;
    @include media-breakpoint-down(sm) {
      margin: 27px 0;
    }
    @include media-breakpoint-between(sm,md) {
      padding: 25px 0;
    }
    &.open-list{
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  li {
    padding-bottom: 20px;
    display: flex;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      border-bottom:2px solid transparent;  
      line-height: 12px;
    }
    &:hover{
      a {
        border-bottom: 2px solid;
        
      }
    }
  }
  


  .h3 {
    border-bottom: $footer_border;
    padding-bottom: 15px;
    position: relative;
    font-size: $footer_title-font-size;
    color: $footer_title-color;
    
    @include media-breakpoint-down(md) {
      display: block;
      padding-top: 15px;
      margin-bottom: 0;
      cursor: pointer;

      &:after {
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        background: #fff;
        position: absolute;
        right: 0;
        top: 21px;
      }

      &.active {
        &:after {
          background: #f00;
        }
      }
    }
  }

  // mobile
  @include media-breakpoint-down(md) { 
    &:first-child {
     .h3 {
      border-top: $footer_border;
      }
    }
  }
}

/* ============================================ *
* Socials 
* ============================================ */
@if $footer_social_icons {
  .footer-socials {
    margin: 37px 0 30px ;
    @include media-breakpoint-down(md) { 
      margin: 0 auto;
      text-align: center;
    }
    li {
      display: inline-block;
      margin-bottom: 0;
      margin-right: 15px;
      a {
        font-size: 14px;
        width: 16px;
        color: $primary-colour;
        height: 16px;
        @include media-breakpoint-down(md) {
          color: $primary-colour;
          font-size: 26px;
         }
      }
    }
  }
}


/* ============================================ *
* Footer Last 
* ============================================ */

.footer-last {
  padding: 15px 0 30px;
  margin-top: 15px;
  font-size: 10px;

  @include media-breakpoint-up(md) {
    padding: 30px 0 0;
    margin-top: 30px;
    border-top: $footer_border;
  }
  @include media-breakpoint-between(sm,md){
    border-top:0 !important;
  }
  .footer-last-block {
    display: block;
    @include media-breakpoint-up(md) {
      @include make-col(4);
      margin-bottom: 0;
      font-size: 10px;
    }
    &.last {
      margin-bottom: 0;
      margin-left: auto;
    }
  }
}
    
/* ============================================ *
* Footer Last Blocks 
* ============================================ */

.footer-copyright {
  text-align: left;
  @include media-breakpoint-down(md) {
    text-align: center;
    order: 2;
    max-width: 100%;
    margin: 60px auto 15px;
    color: #ccc;
    display: block;
  }
}

.footer-payments {
  text-align: center;
  @include media-breakpoint-down(md) {
    order: 1;
  }
}

.footer-website {
  text-align: right;
  @include media-breakpoint-down(md) {
    order: 3;
    text-align: center;
    max-width: 100%;
    margin: 20px auto 15px;
    color: #ccc;
    display: block;
  }

  a {
    font-family: $font_family-primary-bold;
    font-size: 10px;
    color: #9b9b9b;
    text-decoration: underline;
  }
}

/* ============================================ *
 * ScrollToTop
 * ============================================ */
@if $footer_backToTop {
  .scrollToTop  {
    width: 48px;
    height: 48px;
    background: #fff;
    overflow: hidden;
    -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    visibility: hidden;
    white-space: nowrap;
    color: #fff;
    z-index: 10;
    text-align: center;
    margin: -50px auto;
    display: block;
    line-height: 48px;
    @include font-icon-before('\e93b');
    cursor: unset;
    @include media-breakpoint-down(md) {
      margin: -20px auto;
    }
    &:before{
      font-size: 32px;
      color: $primary-colour;
    }
    &.active {
      opacity: 1;
      visibility: visible;
    }
    &:hover{
      @include font-icon-before('\e93a');
    }
  }
  .scrollToTopright{
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background: #656565;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    display: inline-block;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    bottom: 75px;
    right: 30px;
    visibility: hidden;
    white-space: nowrap;
    color: #fff;
    line-height: 37px;
    z-index: 10;
    text-align: center;
    transition: all ease-in 0.3s;
    // @include font-icon-before('\e942');
    @include font-icon-before('\e93b');
   
    &.active {
       opacity: 1;
       visibility: visible;
       color: #fff;
       display: flex;
       align-items: center;
       justify-content: center;
      //  display: none;
    }
    &::before{
      font-size: 24px;
    }
  }

} @else {
  // .scrollToTop {
  //   @include visuallyhidden;
  // }

}
.after-footer{
    .text-center{
        text-align:center;
    }
}
/******** remove item from b cart**********/
/*
.modal-inner-wrap{
  .action-dismiss ,.action-accept {
    height: 40px;
    line-height: 28px;
    margin: 0 2px;
    background: $primary-colour;
    &:hover {
      background: $secondary-colour;
      span {
        color: $primary-colour ;
      }
    }
  }
}
*/