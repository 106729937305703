/* ============================================ *
 * Breadcrumbs
 * ============================================ */
// Breadcrumb size

$breadcrumb_font-size: 14px;
$breadcrumb_font-colour: $font_colour-default;

.breadcrumbs {
	@include make-container;
	margin-top: $margin;
	margin-bottom: 18px;
	text-align: left;
	position: relative;
	z-index: 99;
	li {
		display:inline-block;
		font-size: $breadcrumb_font-size;
		a {
			color: #b8b8b8;
			font-size: 10px;
			text-decoration: none;
			position: relative;
			margin-right: 20px;
			line-height: 13px;
			border-bottom: 1px solid #b8b8b8;
			&:after {
				@include font-icon('\e938');
				display: block;
				position: absolute;
				top: 2px;
    			font-size: 10px;
    			bottom: 0;
    			right: -17px;
			}
		}
		strong {
			color: #b8b8b8;  
			font-size: 10px;
			line-height: 13px;
			border-bottom: 1px solid #b8b8b8;
		}
		&:not(:last-child)::after {
			
		}
		&:last-child {
			strong { 
				// font-weight: normal;
			}
			&:after{
				display: none;
			}
		}
	}
}
