label, .label {
  font-size:$label-size;
}
input {
  font-size:$input-size;
}
select {
  font-size:$select-size;
}
textarea {
  font-size:$textarea-size;
}
img {
  width: auto;
  height: auto;
}