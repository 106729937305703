/* ============================================ *
 * Tables
 * ============================================ */
table   { 
	border:0;
	empty-cells:show;
	font-size:100%;
	width: 100%;
	border-collapse: collapse;
	> caption {
	    border: 0;
	    clip: rect(0, 0, 0, 0);
	    height: 1px;
	    margin: -1px;
	    overflow: hidden;
	    padding: 0;
	    position: absolute;
	    width: 1px;
	}
	th { 
		border-bottom: $border-width $border-type $border-light-colour;
		padding: 0 0 10px;
		white-space: nowrap;
		
	}
	tr {
		&.even {
			background-color: lighten( $secondary-colour, 95% );
		}
	}
	td {
		padding: 10px;
		border-bottom: $border-width $border-type $border-light-colour;
	}
}
caption,th,td { vertical-align:top; text-align:left; font-weight:normal; }


/* Data Table */
.data.table { width:100%; border-collapse: collapse; }
.data.table thead { /* background-color:#f2f2f2; */ }
.data.table tbody {}
.data.table tfoot {}
.data.table tr { float: none; }
.data.table tr.first {}
.data.table tr.last {}
.data.table tr.odd {}
.data.table tr
.data.table tbody.odd {}
.data.table tbody.odd td { border-width:0 1px; }
.data.table tbody.even { background-color:#f6f6f6; }
.data.table tbody.even td { border-width:0 1px; }
.data.table tbody.odd tr.border td,
.data.table tbody.even tr.border td { border-bottom-width:1px; }
.data.table th .tax-flag { white-space:nowrap; font-weight:normal; }
.data.table td.label,
.data.table th.label { font-weight:bold; background-color:#f6f6f6; }
.data.table td.value {}

