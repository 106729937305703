/* ============================================ *
 * Generals
 * ============================================ */
body{
    min-height: 100vh;
    min-height: -webkit-fill-available;
}
.nav-before-open{
    @include media-breakpoint-down(md){
       
            //position: fixed;
        
    }
}
/*For IE close button*/
input[type=text]::-ms-clear {
    display: none;
}
.main{
    -ms-flex: 1;
    -webkit-flex: 1;
    flex: 1;
}
/* ============================================ *
 * Header
 * ============================================ */
.page-header {
    background:$header-background-colour;
    position: relative; // DO NOT CHANGE 
    .header-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: stretch;
        align-content: center;
        @include media-breakpoint-up(md) {
            padding: 0 40px;
            flex-wrap: nowrap;     
        }
      
        @include media-breakpoint-down(sm){
            padding: 0 10px;
            height: 80px;
            align-items: center;
        } 
        @media only screen and (min-width: 992px) and (max-width: 1340px) { 
             padding: 0 20px; 
        }
    }
    .search-autocomplete {
        width: 100% !important;
    
        @include media-breakpoint-down(md){
            top: 63px;
            left: 0;
            right: 0;

        }
    }
}
#maincontent {
    @include media-breakpoint-up(md) {
        padding-top: 90px;
    }
}
.checkout-index-index #maincontent {
    padding: 0;
}
body:not(.cms-home) {
    .page-header {
        border-bottom: 2px solid #b8b8b840;
    }
}

.before-header ,.after-header {
    @include clearfix;
    clear: both;
} 

.header-top-links {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    clear: both; 
    margin-bottom: 15px;
    font-family: $font_family-primary-bold; 
    font-size: 12px;

    @include media-breakpoint-down(md) {
        display: none;
    }

    li {
        margin-right: 14px;
        white-space: nowrap;
       
        &:last-child {
            margin-right: 0;
        }
    } 

    a {
        display: inline-block;
        border-bottom: 2px solid $primary-colour;
        line-height: 12px;
        padding-bottom: 1px;
        font-family: $font_family-primary;
    }
}
.sign-account {
    padding-right:20px;
}



.header {     
    display: flex;
    flex-direction: column;
    padding: 0;

    @include media-breakpoint-up(md) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background:#fff;
        z-index: 9999;
    }

    .skip.contentarea {
        border: 0 none;
        clip: rect(0px, 0px, 0px, 0px);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    } 
    .logo {
        @include make-col-ready;
        @include make-col(4);
        text-align: center;
        display: flex;
        // logo vertical aligment
        align-items: center;
        justify-content: center;
        // margin-right: auto;

        @include media-breakpoint-up(sm) {
            //max-width: 160px;
            @include make-col(2);
            padding: 24px 0;
            justify-content: flex-start;
        }
         @media only screen and (min-width: 992px) and (max-width: 1340px)  {
             @include make-col(1.5);
        }

        @include media-breakpoint-between(sm,md){
            // max-width: 10% !important;
            // max-width: 100px !important;
        }
        @include media-breakpoint-down(sm) {
            @include make-col(5);
            align-items: center;
            justify-content: flex-start;
            padding: 0;

        }

        .h1,
        h1 {
            display: flex;
            align-items: center;
        }
        img {
            max-width: 159px;
            @include media-breakpoint-down(md) {
                max-width: 100px;  
            }
        }
    }
}
.header-nav {
    @include make-col-ready;
    @include media-breakpoint-up(md) {
        @include make-col(7);
        display: flex;
        align-items: center;
        padding-top: 15px;
    }
    @media (min-width: 992px) and (max-width: 1340px){
        @include make-col(8.5);
    }
    @include media-breakpoint-up(sm) {
        @include make-col(8);
    }
    @include media-breakpoint-up(lg){
        margin-right: 20px;
    }
   
    
}

.header-controls {
    display: flex;
    flex-wrap: wrap;
    @include make-col(5);

    @include media-breakpoint-up(md) {
        order: 9;
        justify-content: flex-end;            
        @include make-col(3);           
        margin: 0;
        padding:6px 0 17px;
    }
    
    @include media-breakpoint-between(sm,md) {
        position: absolute;
        right: 15px;
        top: 5px;
    }
    @include media-breakpoint-down(sm) {
        margin-left: auto;
    }
    @media only screen and (min-width: 992px) and (max-width: 1340px)  {
        @include make-col(2); 
        padding-right: 10px; 
    }

    // @include media-breakpoint-between(sm,md){
    //     padding : 0 !important;
    //     @include make-col(2);  
    // }

    ul:last-child {
        display: flex;
        justify-content: flex-end;
        position: relative;
        width: 100%;
        @include media-breakpoint-down(md) {
            justify-content: flex-end;
            align-content: flex-end;
            padding-right: 30px;
            flex-grow: 1;
            position: static;
            padding-right: 0;
        }
    }

    li.control-link {
        display: flex;
        //justify-content: center;
        padding: 0 ;
        text-align: center;
        -webkit-touch-callout: none;
            -webkit-user-select: none;
             -khtml-user-select: none;
               -moz-user-select: none;
                -ms-user-select: none;
                    user-select: none;

        @include media-breakpoint-down(md) {
            @include make-col(3);
            line-height: 35px;
            align-items: center;
            padding: 20px 24px;
    
        }

        &.wishlist-header{
            // padding-left: 34px !important;
           
        }

        &.link-account {
            position: relative;
        }
        &.not-login{
            a{
                text-decoration: none;
            }
        }
       
        &:not(.link-search) {
            justify-content: center;
            padding-left: 24px;
            display: flex;
            align-items: center;  
            &.not-login {
                li {
                    display: none;
                    &.authorization-link {
                        display: block;
                    }
                    &:last-child {
                        display: block;
                    }
                }
            } 
            &.link-contact{
                @include media-breakpoint-down(md) {
                    display: none;  
                }
            }
            &.wishlist-header  {
                 @include media-breakpoint-down(md) {
                    // display: none;  
                }
            } 
            &.link-cart {
                padding-left: 16px;
                @include media-breakpoint-down(md) {
                    padding: 0 !important;
                }
            }            
            a.icon-wishlist{ 
                @include font-icon-before('\e92c'); 
                font-size: 18px;
                &:hover{
                    @include font-icon-before('\e92a');
                }              
            }
            &.active {
                .icon-account {
                    @include font-icon-before('\e903');
                    line-height: 35px;
                    @include media-breakpoint-down(md) {
                        @include font-icon-before('\e90b');     
                    }
                }
            }
        }

        &.link-search {
            width: 100%; 
            max-width: 190px;
            @include media-breakpoint-down(md) {
                max-width: auto;
                margin-top: 0;
                padding: 0;
            }
            @include media-breakpoint-down(sm){
                display: flex;
                justify-content: center;
            }
            @include media-breakpoint-between(sm,md){
                display: flex;
                justify-content: center;
            }
            .mobile-active{
                width: 100%;
                @include media-breakpoint-up(md) {
                    display: block;
                }
                @include media-breakpoint-down(md) {
                    display: none;
                    right: 0;
                    left: 0;
                    position: absolute;
                    top: 75px;
                    background: #000;
                    // opacity: 0.7;
                    height: 64px;
                    z-index: 999;
                }
            }
            .mobile-search-icon{
                display: none;
                @include media-breakpoint-down(md) {
                    display: block;
                    font-size: 16px;
                    font-weight: bold;
                }
                &.search-open{
                    width: 35px;
                    height: 34px;
                    border-radius:50%;
                    font-size: 12px;
                    background:$primary-colour;
                    &:before{
                        @include font-icon('\e90b');
                        color: $secondary-colour;
                    }
                }
            }
        }
        .controls {
            cursor: pointer;
            font-size: $font-md;
            display: flex;
            line-height: 1;
       }

       span.text{
            
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        span.icon{
            font-size: 16px;
            &.icon-account{
                @include font-icon-before('\e904'); 
                @include media-breakpoint-up(md) { 
                    &:hover{
                        @include font-icon-before('\e903');
                    }
                }
            }
            @include media-breakpoint-down(md) { 
                line-height: 35px;
                &.icon-cart {
                    line-height: 21px;
                }
            }
        }
    }
}


/* ============================================ *
 * Header Search
 * ============================================ */
.header-search { 
    @include clearfix;
    display: block;
    border: 2px solid #000;
    width: 193px;
    @include media-breakpoint-up(md) {
        width: 162px;
    }
    @include media-breakpoint-down(md) {
        border: 0;
    }

    .minisearch {
        display: flex;
        padding:0;
    }

    .field.search {
        @include make-col-ready;
        padding-right: 0;
        margin: 0;
        position: static;

        @include media-breakpoint-up(md) {
            @include make-col(9);
            padding: 0;
        }

        @include media-breakpoint-down(sm){
            position: inherit;
        }

        .control {
            @include media-breakpoint-up(md) {
                position: relative;
            }
            
            text-align: left;
        }
    }
    div#search_autocomplete{
        @include media-breakpoint-down(sm) {
            top: 64px;
            left: 0;
            width: 100% !important;
        }
    }

    label {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    input {
        border: 0 none;
        height: 32px;
        line-height: 30px;
        padding-left: 17px;
        font-size: 10px;
        font-family: $font_family-primary;
       
        color: $primary-colour;

        @include media-breakpoint-down(md) {
            border: 0 none;
            height: 64px;
            line-height: 64px;
            padding: 0;
            text-align: left;
            float: left;
            width: 100%;
            font-size: 14px;
            background: transparent;
            color: $secondary-colour;

            &::placeholder {
                color: $secondary-colour;
            }
        }
    }

    .actions {
        position: relative;

        @include media-breakpoint-down(md) {
            position: absolute;
            right: 14px;
            top: 15px;
            z-index: 99999;
        }

        button {
            background: none;
            border: 0 none;
            cursor: pointer;
            width: 100%;

            .icon-search {
                background: transparent;
                color: $primary-colour; 
                border: 0;
                height: 33px;
                line-height: 35px;
                &:hover {
                    @include font-icon-before('\e923');
                }                
                &:before {
                    @include font-icon('\e925');
                    @include media-breakpoint-down(md) {
                        color: $secondary-colour;
                    }
                }    
            }
        }
    }

    .search-icon {
        width: 20px;
        height: 32px;
        position: absolute;
        bottom: 0;
        left: 15px;
        @include media-breakpoint-down(md) {
            position: static;
            color: $secondary-colour;
        }

        .icon {
            padding: 0;
        }
    }
    .search-close {
        display: none;

        @include media-breakpoint-up(md) {
            display: flex;
            @include make-col-ready;
            @include make-col(1);
            position: relative;
            cursor: pointer;

            span {
                width: 15px;
                height: 15px;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
            }
        }
    }
}


/* ============================================ *
 * Header Cart
 * ============================================ */
.minicart-wrapper {
    padding: 4px 0 2px 5px;
    transition: all ease 0.2s;
    .empty-msg {
        margin-top: 35px;
        display: block;
        color: $primary-colour;
        font-weight: bold;
        font-size: 16px;
    }

    .action.showcart {
        display: flex;
        line-height: 1;
        span.icon {
            @include font-icon-before('\e909');
            &:hover {
                @include font-icon-before('\e908');
            }
        }
        span.items-coutnter-num {
            padding: 4px;
            width: 100%;
        }
    }

    .action.showcart.active {
        span.items-coutnter-num {
            padding: 4px;
            width: 100%;
            color: white;
        }
    }

    .counter {
        padding-top: 3px;
        padding-left: 5px;
    }

    .ui-dialog {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        bottom: 0;
        z-index: 99999;
        margin-top: 19px;
        background-color: $secondary-colour;
        width: 300px;
        height: 408px;
        transition: all .2s ease-in-out;
        overflow: auto;
        outline: none;
        border: 1px solid #000;    

        @include media-breakpoint-down(md) {
            margin: 0 auto;
            right: 0;
            top: 50px;
            right: 0;
            z-index: 999;
        }
        
        &.active {
            display: flex;
        }
    }

    &.active {
        background: #000;
        color: #fff;
        position: relative;

        .icon-cart {
            color: #fff;
        }
    }

    .checkout-btn {
        @include media-breakpoint-down(sm) {
            margin-top: 20px;
        }
    }
}

// when cart is open remove page scroll - 
.noscroll {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
    left: 0;
    top: 0;
}

#header-cart-over {
    position: fixed;
    z-index: 900;
    top: 0;
    left: 0;
    display: block;
    visibility: hidden;
    width: 100%;
    height: 100%;
    -webkit-transition: all .5s cubic-bezier(.645, .045, .355, 1);
    -moz-transition: all .5s cubic-bezier(.645, .045, .355, 1);
    transition: all .5s cubic-bezier(.645, .045, .355, 1);
    opacity: 0;
    background: $primary-colour;

    &.active {
        visibility: visible;
        -webkit-transition: all .5s cubic-bezier(.645, .045, .355, 1);
        -moz-transition: all .5s cubic-bezier(.645, .045, .355, 1);
        transition: all .5s cubic-bezier(.645, .045, .355, 1);
        opacity: 0.2;
    }
}
/* ============================================ *
 * Header Account Links
 * ============================================ */
#header-links { 
    display: none;
    position: absolute;
    top: 100%;
    right: 20px;
    background: $primary-colour;
    z-index: 995;
    text-align: left;

}
ul.header.links {
    display: none;
    position: absolute;
    top: 110%;
    left: -42px;  

    @include media-breakpoint-down(md) {
        display: none !important;
        top: 52px;
    }

    width: 150px;
    background: #fff;
    z-index: 9999;
    text-align: left;
    font-size: $font-sm;
    border: 1px solid #ccc;

    &:after,
    &:before {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
        border-width: 10px;
        margin-left: -10px;
    }

    &:before {
        border-color: rgba(0, 0, 0, 0);
        border-bottom-color: #ccc;
        border-width: 11px;
        margin-left: -11px;
    }

    li {
        width: 100%;
        display: block;
        a {
            display: block;
            padding: 5px 10px;
            &:hover {
                background: #eee;
            }
        }
        &:last-child {
            a {
                //padding-bottom: 0;
            }
        }
        span.counter.qty {
            display: none;
        }
    }
    .requistion-list-item , .company-structure-item{
        display: none;
    }
}

.link-account {
   
    .controls {
        /*@include media-breakpoint-down(md) {
            width: 35px;
            height: 35px;
        }*/
    }

    &.active {
        ul.header.links {
            display: block;
        }
        .icon-account {

            @include media-breakpoint-down(md) {
                width: 35px;
                height: 34px;
                border-radius: 50%;
                font-size: 12px;
                background: $primary-colour;
                &:before {
                    color: $secondary-colour;
                    font-size: 13px;
                    line-height: 34px;
                }
            }
        }
    }
}

/* ============================================ *
 * Header login Form
 * ============================================ */
.header-login-form {
    @include media-breakpoint-up(lg) {
        display: none !important;
    }

    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    border-top: 1px solid #ccc;

    &.active {
        display: block;
        padding: 88px 14px 19px;  
        @include media-breakpoint-down(md){
            background: #fff;
            // right: -60px;
            // right: -103%;
            // left: unset;
            // width: 100%;  
            // min-width: 100vw;
            // height: calc(100vh - 57px);
            // z-index: 999;
            // @include make-container();
            // display: flex;
            // flex-direction: column;
            // justify-content: flex-start;
            position: fixed;
            top: 75px;
            left: 0;
            right: 0;
            bottom: 0;
            height: calc(100vh - 57px);
            z-index: 999;
            input{
                max-width: 100%;
            }
        }
        
    }

    .required {
        text-align: left;
        font-size: 12px;
        padding-bottom: 30px;

        .label {
            margin: 0 !important;
            padding-left: 10px;
        }
    }
    .login-title{
        font-family: $font_family-primary-bold; 
        font-size: 32px;
    }
    input {
        border-width: 2px !important;
        font-family: $font_family-primary-bold;
        height: 60px;
        width: 346px !important;
        margin: 0 auto;

        @include media-breakpoint-up(sm) {
            width: 100% !important;
        }

        &.input-text.active {
            color: $primary-colour;
        }
        
    }

    .mage-error {
       
        color: #ff4a4a;
    }
    .block-footer {
        // bottom: 80px;
        display: flex;
        margin-top: 50px;
        // left: 14px;
        // right: 14px;
        // position: absolute;
        a {
            text-align: left;
            margin-right: auto;
            text-decoration: underline;
            @include media-breakpoint-down(sm){
                @include font-size(12);
            }

            &:last-child {
                margin-left: auto;
                margin-right: 0;
                text-align: right;
            }
        }

    }
    
    .login.button {
        margin: 0 auto;
        display: block;
        text-align: center;

        span {
            text-align: center;
            width: 77px;
            height: 35px;
            line-height: 30px;
            padding: 0;
        }
    }

    .logout-link a {
        font-size: 20px;
        clear: both;
        display: block;
        width: auto;
        text-decoration: underline;
        margin: 0 auto;
        line-height: 40px;
    }
}
.floating-contact-block {
    position: fixed;
    bottom: 30px;
    right: 30px;
    background: #656565 ;
    padding: 10px;
    border-radius: 40px;
    box-shadow: 5px 2px 8px 2px #bfbfbf6e;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    z-index: 19;
}