input,
select,
textarea,
button {
	vertical-align:middle;
	color:#000;
	outline: 0;
}
input.input-text,
select,
textarea { background:#fff; border:1px solid #cdcdcd; }


input.input-text {
	margin: 0;
	line-height: 40px;
	height: 42px;
	padding: 0 10px;
	outline: 0;
	outline: none;
}
input:focus {
	outline: 0;
	outline: none;
}
input.input-text:focus,input.input-text.active{ border-color: #4a4a4a !important; }
textarea {
	padding:6px; margin:0; border: 1px solid #cdcdcd;
	font-size: 12px;
    width: 100%;
    min-height: 200px;
	&:focus,&.active {
		border-color: #9b9b9b !important;
	}
}
input[name=email],
input[type=text] {
    border: 1px solid #cdcdcd;
    padding: 0 10px;
    line-height: 40px;
	height: 42px;
}
select { }
select option { }


select {
    display: block;
    margin: 0;
    padding: 5px 20px 5px 5px;
    height: 42px;
    width: 100%;
    font-size: 12px !important;
    font-family: sans-serif;
    color: #444;
    line-height: 1.3;
}
select::-ms-expand {
    display: none;
}
select:hover {
    border-color: #888;
}
select:focus {
    border-color: #000;
    color: #000;
    outline: none;
}
select::-moz-focus-inner {
  border: 0;
}
select option {
    font-weight:normal;
}

.mage-error {
    color: #f00;
}

select.multiselect option { border-bottom:1px solid #cdcdcd; padding:2px 5px; }
select.multiselect option:last-child { border-bottom:0; }
textarea { overflow:auto; }
input.radio { margin-right:3px; }
input.checkbox { margin-right:3px; margin-left: 1px; }
input.qty,
input.item-qty {
	//width:60px;
	//max-width:60px;
    text-align: center;
    max-width: 42px;
    height: 42px;
    line-height: 42px;
	//padding:0 5px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

fieldset {
	border: 0;
    padding: 0;

	.field {
 		border: 0;
    	padding: 0;
    	margin-bottom: 20px;
	}
}
.fieldset {
    border: 0;
    margin: 0;
    padding: 0;
    outline: none;
	> .legend {
    	margin: 0 0 20px;
      margin-bottom: 20px;
	    padding: 0 0 10px;
	    width: 100%;
	    float: left;
			display: block;
	}
	.field {
		&:not(.choice) label {
			width: 100%;
			display: block;
			margin-bottom: 10px;
		}
		&:not(.choice) input {
			display: block;
			width: 100%;
		}

		._with-tooltip {
			position: relative;
			padding-right: 40px;
		}

		.note {
			display: block;
			margin-top: 5px;
			font-size: $font-sm;
		}
		&.newsletter {
			line-height: 1;
    		input {
    			width: auto;
    			float: left;
    			margin-right: 10px;
    		}
    	}
	}
	/*.required , ._required {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .label {
            width: auto;
            order: 2;
        }
        .control {
            width: auto;
            order: 1;
            display: block;
            overflow: hidden;
        }
        input {
            width: 20px;
            height: 20px;
            margin-right: 20px;
        }
    }*/
}
.field-tooltip {
	cursor: pointer;
	position: absolute;
	right: 4px;
	top: 6px;
	&:before {
		text-align: center;
		width: 30px;
		height: 30px;
		line-height: 30px;
		border: $border-width $border-type #ccc;
		border-radius: 50%;
		background: #eee;
		color: #ccc;
	}
	.action-help {

	}
	.field-tooltip-content {
		background: #f4f4f4;
		border: 1px solid #999999;
		border-radius: 1px;
		font-size: 14px;
		padding: 12px;
		width: 270px;
		display: none;
		left: 38px;
		position: absolute;
		text-transform: none;
		top: -9px;
		word-wrap: break-word;
		z-index: 2;


	}
	&._active {
		.field-tooltip-content {
			display: block;
		}

		}
	&:hover {
		.field-tooltip-content {
			display: block;
		}
	}
}
.fieldset>.field.required>.label:after, .fieldset>.fields>.field.required>.label:after, .fieldset>.field._required>.label:after, .fieldset>.fields>.field._required>.label:after {
    content: '*';
    color: #e02b27;
    font-size: 14px;
    margin: 0 0 0 5px;
    line-height: 1.86;
    vertical-align: middle;
}
.page-layout-checkout>.loading-mask img {
	width: 170px !important;
    height: 110px !important;
}
#remember-me-box .tooltip.wrapper {
    width: 100%;
    display: block;
    margin: 8px 0 0;
}

/* ============================================ *
 * Search autocomplete
 * ============================================ */

.search-autocomplete {
	ul {
	    flex-wrap: wrap;
	    text-align: left;
	}
	li {
	    width: 100%;
	    display: flex;
	    justify-content: space-between;
	    line-height: 30px;
	    padding: 0 10px;
	    background: #fff;
	    border: 1px solid;
		&:not(:last-child) {
		    border-bottom: 0;
		}
	}
}
