
/* ============================================ *
 * Default
 * ============================================ */
button,.button { 
	overflow:visible;
	width:auto;
	border:0;
	padding:0;
	margin:0;
	background:transparent;
	cursor:pointer;
	display: block;
    > span { 
		// float: left;
		display: inline-block;
		width: 100%;
		padding: 0 $button-padding;
		
		white-space:nowrap;
		
		// Text Styles
		text-align:center;
		text-transform: $button-text-transform;
		
		color: $button-color;
		font-size: $button-font-size;
		height: $button-height;
		line-height: $button-line-height;
		font-family: $font_family-primary-bold;
		// Custome Styles
		border: $button-border-size solid $button-border-color;
		background: $button-background;
		
		transition: all ease 0.3s;
		//transition: all ease-in 0.3s;
		@include media-breakpoint-down(md) {
			font-size: 14px;
		}
	}

	&.disabled,
    &[disabled] {
    	//cursor: default;
    	//pointer-events: none; // Disabling clicks
    	> span {
			//background: $button-disabled-background;
			//border-color: $button-disabled-border-color;
			//color: $button-disabled-font-color;
    	}
    }

    &:hover {
    	> span {
    	 	border-color: $button-hover-border-color;
    	 	color: $button-hover-color;
    	 	background: $button-hover-background;
    	}
    }

	&.small {
		max-width: $button-small-max-width;
		font-size: $button-small-font-size;
		width: 100%;
	}
	&.medium {
		max-width: $button-medium-max-width;
		font-size: $button-medium-font-size;
		width: 100%;
	}
	&.large {
		max-width: $button-large-max-width;
		font-size: $button-large-font-size;
		width: 100%;
	}
	&.active {
		> span {
			color: $button-hover-color;
			background: $button-hover-background;
		}
		&:hover {
			> span {
				color: $button-color;
				background: $button-background;
			}
		}
	}
	&.solid,&.primary {
		> span {
			border-color: $button-color;
		    color: $button-color;
		    background: $button-background;
		    &:hover {
		    	color: $button-hover-color;
				background: $button-hover-background;
		    }			
		}		
	}
    // Custome Buttons
    .btn-checkout span {

    }
    
    button.btn-checkout.no-checkout {

    }
}


/* ============================================ *
 * Split Toggle Button
 * ============================================ */
.split.button {
    user-select: none;
    position: relative;
    span:after {
    	@include font-icon("\e93c");
    	margin-left: 5px;
    	font-size: 13px;
    }
    span.action.new:after{
    	display: none;
    }
    .items {
        max-width: 300px;    	
        margin-top: -2px;
        max-height: 0;
        overflow: hidden;
        transition: all ease-in 0.3s;
		position: absolute;
		left: 0;
    	right: 0;
	    background: #fff;
	    z-index: 99;        
    }
    .item {
        &:not(:last-child) > span {
            border-bottom: 0;
        }
        > span {
	        display: block;
	        padding: 0 10px;
	        border: 2px solid #000;
	        line-height: 30px;
	        font-size: 14px;
	        > span {
	        	font-size: 14px;
	        }
        }  
        &:hover {
			color: #fff;
			> span {
				color: #fff;
				background: #000;
			}
        }      
    } 
    &.active {
        .items {
        	@include media-breakpoint-down(sm) {
        		max-height: 100%;
    			display: block;
    			overflow: auto;
        	}

        }        
    }   
}