/* ============================================ *
 * My wishlist
 * ============================================ */
 

.wishlist-index-index , .magento_invitation-index-send  {
    color: $primary-colour;
    .page-title {
        @include media-breakpoint-down(sm) {
            margin-top: 2px;
        }
    }
    .modal-popup .modal-inner-wrap{
        width: 40%;
        h1{
            font-size: 40px;
        }
        .modal-header{
            margin-left: 0;
        }
        .action-close span{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .product-items {
        @include make-row;
        margin-top: 0;
        .product-item {
            background-color: #fff;
            position: relative;
            text-align: center;
            @include make-col-ready;
            @include make-col(4);
            text-align: center;
            margin-bottom: 10px;
            margin-top: 0;  
            @include media-breakpoint-down(sm) {
                border-top: 1px solid #e8e8e8;
                padding: 20px;
                @include make-col(12);
            }
        }
    }
    .wishlist-toolbar {
        label {
            line-height: 25px;
            &:before ,&:after {
                left: 55px !important;
            }
        }
        .pager {
            display: flex;
            width: 100%;
        }
        .toolbar-amount {
            margin-right: auto;
            display: flex;
            justify-content: flex-start;
            width: 100%;
        }
        .limiter {
            margin-left: auto;
            display: flex;
            width: 100%;
            justify-content: flex-end;
            line-height: 23px;
        }
        .selectric-wrapper {
            min-width: 77px;
        }
        .selectric {
            margin: 0 10px;
        }
    }
    .product-item-tooltip{
       display: none;
       position: relative;
    }
    .product-item-inner {
        display: block;
        position: relative;
        overflow: hidden;

        .comment-box{
            label{
                display: none;
            }
        }
    }
    
    .product-image-wrapper {
        img {
            max-height: 240px;
        }
    }
    .product-item-link {
        font-size: 1rem;
        text-transform: uppercase;
        display: block;
        word-wrap: normal;
        width: 100%;
        text-align: center;
        min-height: unset;
        margin: 0;
        @include media-breakpoint-down(md) {
            text-align: left;
        }
    }
    .product-item-name {

        @include media-breakpoint-down(sm) {
             margin-bottom: 7px;
            a {
                max-width: 200px;
            }
        }
        a {
            color: $primary-colour;
            font-weight: normal;
            padding: 0;
            text-align: left;
            text-decoration: none;
            @include media-breakpoint-up(sm){
                min-height: 50px;
            }
        }
    }
    .price-box span.special-price{
        .price{
            font-weight: bold !important;
            line-height: 1 !important;
            font-size: 14px !important;
        }
    }
    .product-reviews-summary {
        display: none;
    }
    textarea {
        width: 100%;
        padding-top: 8px;
        padding-left: 10px;
        line-height: 25px;
        height: 42px;
        overflow: hidden;
        min-height: 42px;
    }
    .box-tocart {
        .product-item-actions {
            max-width: 100%;
            .actions-primary {
                width: 100%;
            }
        }
        .fieldset {
            flex-direction: row;
        }
        .qty {
            display: block;
            label {

            }
        }
    }
    .product-item-actions {
        margin-top: 5px;
        a {
            color:$primary-colour;
            text-align: left;
        }
        button {
            width: 100%;
        }
        .edit {
            width: 50%;
            order: 1;
            @include media-breakpoint-down(md) {
               width: 100%;
               float: none;
               display: block;
               margin-top: 12px;
           }    
        } 
        .delete{
            width: 50%;
            order: 2;
            text-align: left;
             @include media-breakpoint-down(md) {
                 width: 100%;
                 float: none;
                 display: block;
                 text-align: left;
           } 
            &:before{
                @include font-icon('\e90b');
                font-size: 7px;
                border: 1px solid $primary-colour;
                border-radius: 50%;
                padding: 3px;
                text-align: center;
            }
        }  
        .move {
            order: 3;
            text-align: left;
            width: 49%;
            padding: 0;
            display: none;
        }
        .copy {
            order: 4;
            text-align: left;
            display: none;
        }
    }
    .actions-toolbar {
        // overflow: hidden;
        border-top: 1px solid #e5e5e5;
        padding: 10px 0 0 0;
        margin: 0;
        align-items: baseline;
        //@include make-row;
        > .primary {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-end;
            flex-direction: column;
            min-height: 100px;
            
            @include make-col(6);
            
            @include media-breakpoint-down(sm) {
                flex-direction: column;
                @include make-col(12);
            }
            @include media-breakpoint-between(md,md) {
                overflow: auto;
            }
            .primary {
                margin-left: auto; 
            }
            .update, .share {
                @include make-col(4);
                padding: 0 5px; 
            }

            .tocart_cont {
                text-transform: uppercase;  
                margin-top: 0px; 
                justify-content: flex-end;
                display: flex;
                width:100%;
            }
            .tocart {
                @include media-breakpoint-up(md) {
                    max-width: 250px; 
                }
                display: block;
                width: 100%;
                
            }
        }
    }
    .secondary { 
        @include make-col(2);
        text-align: right;
        @include media-breakpoint-down(sm) {
            @include make-col(12);
            text-align: center;
            float: none;
        }
    }
    .selectric .label {
        @include media-breakpoint-down(sm) {
            padding: 0 10px;
        }
    }
    .product-item-info {
        &:hover {
            .product-item-actions {
                display: flex;
            }
        }
    }

    .select-prod{
        display: flex;
        align-items: flex-start;
    }
    .product-item-link{
        line-height: 16px;
    }
    .price-box .old-price{}

    #edit-wishlist-form {
    .field.choice {
        display: flex;
        align-items: center;
        margin: 20px auto;
        label{
            margin-left: 10px;
        }
    }
}
.modal-inner-wrap {
    .modal-footer {
        button{
            // display: none;
            &:first-child {
                display: block;
            }
        }
    }
}
 
}
.block-wishlist-management {
    position: relative;
    margin-bottom: 20px;
    .wishlist-toolbar {
        float: unset !important;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: flex-start;
        flex-direction: column-reverse;
        font-family: "CeraPro-Bold", Times, serif;
        @include media-breakpoint-down(md) {
            width: 100%;
            margin-top: 0px;
        }

        label {
            line-height: 60px;
        }
        .wishlist-toolbar-select {
            //display: none;
        }
        .wishlist-toolbar-actions {
            // display: none;
        }
    }
    .wishlist-select {
        border-bottom: 1px solid #e8e8e8;
        display: table;
        margin-bottom: 15px;
        width: 100%;
        span.wishlist-name{
            display: table-cell;
            margin-right: 10px;
            padding: 5px 10px 10px;
            vertical-align: top;
            white-space: nowrap;
            width: 5%;
            display: none;
        }
        .wishlist-name-label {
            font-weight: 600;
        }
        .wishlist-name-current {
            display: none;
        }
    }
    .wishlist-select-items {
        display: table-cell;
        // padding-right: 160px;
        padding-right: 0;
        vertical-align: top;
         @include media-breakpoint-down(sm) {
            text-align: center;
         }
        .current {
            border-bottom: 3px solid #ff5501;
            font-weight: 600;
        }
        .item {
            display: inline-block;
            margin-right: 10px;
            padding: 5px 10px 10px;
        }
    }
    .wishlist-title {
        margin-bottom: 10px;
       
        strong {
            font-size: 32px;
            margin-right: 10px;
        }
        a {
            text-decoration: underline;
        }
    }
    .wishlist-info {
        margin-bottom: 10px;
        .wishlist-notice {
            color: #7d7d7d;
            display: inline-block;
            margin-right: 20px;
        }
    }
    /*.wishlist-info {
        display: block;
        .counter.qty {
            text-transform: capitalize;
        }
    }*/
    .messages {
        width: 1108px;
        clear: both;
        overflow: hidden;
        display: block;    
    }

}
.magento_invitation-index-send {
    .field.text {
        .label {
            span {
                font-family: $font_family-primary-bold;
                font-size: 16px;
            }
        }
    }
    .actions-toolbar {
        .secondary span {
            width: 95px;
            margin-bottom: 20px;
        }    
    }
    textarea {
        height: 170px;
    }
}
.form-wishlist-items{
    .message.info {
        background: #fdf0d5;
        color: #6f4400;
        height: 50px;
        top: 142px;
        position: absolute;
    }
}
/* ============================================ *
 * My wishlist form
 * ============================================ */
#wishlistPopup {
    .product-item {
        margin: 0;
    }
    ol {
        padding-top: 30px;
        padding-bottom: 180px;
    }
    textarea {
        min-height: 80px;
    }
    .product-item-photo {
        margin-bottom: 0;
    }
    .product-item-link {
        padding: 0;
    }
    .price-box {
        span {
            font-size: 16px;
        }
    }
    .product-item-inner {
        padding-top: 0;
    }
    .product-item {
        @include make-col-ready;
        @include media-breakpoint-up(lg) {
             @include make-col(4);
        }
        @include media-breakpoint-between(md,lg) {
            @include make-col(6);
        }
        @include media-breakpoint-down(md) {
            @include make-col(12);
        }
    }
    .product-item-actions {
        display: flex;
        justify-content: space-between;
        font-size: $font-md;
        a {
            font-size: 12px;
            padding: 0 15px 0 0;
            margin: 0;
        }
    }
    .field.qty {
        margin: 0;
    }
}


form#create-wishlist-form {
    background: #fff;
    padding: 25px;
    min-height: 200px;
    /*.actions-toolbar {
        display: flex;
        justify-content: center;  
        .secondary {
            display: block;
            width: 100%;
            clear: both;
            position: absolute;
            left: 271px;
        }   
        .primary {
            display: block;
            margin: 0 auto;
            position: absolute;
            left: 88px;
        }
    } */
    .actions-toolbar {
        display: flex;
        justify-content: center;
        > div {
            flex: 0 0 auto;
             display: block;
        }
        button {
            margin: 0;
            
            min-width: 100px;
            display: block;
           
        }
        .primary {
            margin-right: 10px;
            min-height: 0;
        }
    }
}


    #brm-wishlist-popup {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        div#wishlist-popup-list{
            margin: 15px 0;
        }
        label{
            font-family: $font_family-primary-bold;
        }
        .item {
            width: 200px;
            display: flex;
            input{
                margin-right: 5px;
            }
        }
        #wishlist-popup-add-btn{
            span{
                width: 200px;
            }
        }
    }

#wishlist-popup-create {
    display: flex;
    flex-wrap: wrap;
    align-items:center;
    padding: 0 0 20px;

    @include media-breakpoint-down(sm) {
        display: block;
    }
    #wishlist-popup-create-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 40px auto 0;
    }    
    #wishlist-name-error {
        position: absolute;
        bottom: -25px;
    }
    .field.name {
        display: flex;
        width: 100%;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        position: relative;
        @include media-breakpoint-down(sm) {
            width: 100%;
            display: block;
        }
    }
    label span {
        line-height: 40px;
        padding-right: 10px;
        font-size: 14px;
        font-weight: bold;
        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }
    }
    #wishlist_name {
        border: 2px solid #000;
        background: transparent;
        color: #000;
        width: 200px;
        &::placeholder{
            text-align: left;
            font-family: $font_family-primary-bold;
        }
    }
}
#brm-wishlist-popup {
    @include media-breakpoint-down(sm) {
        .selectric-wrapper {
            width: 100%;
            .selectric {
                width: 100%;
            }        
        }
    }
}
.custom-wishlist-popup {
    .selectric-wrapper {
        width: 50%;
        margin: 10px 0 40px;
        .selectric {
            border: 2px solid #1d1d1d;
        }
    }
    .wishlist-popup-remove {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .modal-header {
        padding: 20px 0 0;
    }   
    li.selected{
        display: flex;
        justify-content: space-between;
        &:after {
            content: "✔";
        } 
    }
    
    .modal-inner-wrap {
        @include media-breakpoint-up(md) {
            max-width: 40%;
        }
        @include media-breakpoint-between(md,md) {
            max-width: 70%;
            width: 700px;
        }   
        .modal-content{
            overflow-y: visible;
        }                   
        .action-close {
            span {
                font-size: 0;
                border:0;
                width: auto;
                height: auto;
                &:hover {
                    font-size: 0;
                    border:0;
                    color: #eeeeee;  
                    background:none;
                }
                @include font-icon-before('\e90b');
                &:before{
                    font-size: 16px;
                }
            }
        }
    }
}

/*****************************requisition_list-requisition-view*********************************/
.requisition_list-requisition-view {
    .requisition-grid .item .col {
        .actions-toolbar > .action-delete {
            bottom: -63px;
            right: 0;

            @include media-breakpoint-between(md,lg) {
                width: 85px;
            } 
        }
        .price {
            font-size: 14px;
        }
    }
    .actions-toolbar {
        button > span {
            font-size: 10px;
            padding: 0 10px;
        }
    }

    .account-pages .table-wrapper {
        width: 100%;
    }
    @include media-breakpoint-down(md) {
        table.data-table.table {
            overflow: auto;
        }
        .account-pages tbody td {
            display: table-cell;
        }
        .account-pages .actions-toolbar button {
            width: 50%;
            margin: 0 auto 10px;
        }
        .requisition-popup .action:not(:last-child) {
            margin-bottom: 0;
        }
    }
    .split.button span.action.new{
        &:after {
            display: none;
        }
    }
    .modals-wrapper {
        p.text-secondary , .modal-content {
            padding-bottom: 20px;    
        }
    }
    .modal-popup.requisition-popup .modal-content {
        padding-bottom: 20px;
    }
    .modal-inner-wrap {
        button.action.confirm , button.action.cancel {
            width: 85px;
        }
    }
}

#brm-remove-wishlist-item {
    h3 {
        display: block;
        padding: 10px 0 40px;
        text-align: center;
        font-size: 16px;
        font-weight: normal;
    }
}
/********************************8
** IE Browser
*************************************/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .wishlist-index-index .actions-toolbar > .primary{
        // display: block;
        // float: right;
        // width: 100%;
        max-width: 200px !important;
        display: block !important;
    }
    .form-wishlist-items .actions-toolbar .primary > *, .form-wishlist-items .actions-toolbar .primary button.update{
        // display: inline-block;
        width: 100% !important;
    display: block !important;
    max-width: 100% !important;
    }
    .wishlist-index-index .actions-toolbar > .primary .tocart_cont{
        // display: inline-block;
        // width: 48%;
        // margin-top: 0;
        display: block !important;
        width: 100% !important;
        max-width: 100% !important;
    }
    .wishlist-index-index .actions-toolbar > .primary .tocart_cont, .magento_invitation-index-send .actions-toolbar > .primary .tocart_cont{
        margin-top: 10px !important;
    }
    .account-pages .limiter select{
        width: 50px !important;
    }
 }


/* ============================================ *
 * Account My Wishlist
 * ============================================ */
.wishlist-toolbar {
    margin-bottom: 30px;
    float: left;
    width: 100%;
}


.block-wishlist-management {

    margin-bottom: 0;
    padding-bottom: 0;
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;

    // multi wishlist toolbar start
    .wishlist-select {
        border-bottom: $border-default;

        margin-bottom: 15px;
        width: 100%;
        float: left;


        .wishlist-name {
            display: block;
            width: 100%;
            padding-bottom: 15px;
            position: relative;
            &:before {
                content: "";
                width: 14px;
                height: 14px;
                background-color: $colour_secondary;
                display: block;
                right: 0;
                top: 4px;
                position: absolute;
            }

        }

        .wishlist-name-label {
            &:after {
                content: ' : ';
            }
        }

    }

    .wishlist-select-items {
        float: left;
        width: 100%;
        @include media-breakpoint-down(sm) {
            display: flex;
            overflow-x: auto;
            width: 98%;
            flex-wrap: nowrap;
            float: none;
        }
        .item {
            float: left;
            padding: 10px 15px;
            margin-right: 10px;

            border-bottom: 4px solid transparent;
            bottom: -2px;
            position: relative;

            @include media-breakpoint-down(sm) {
                margin: 0;
                bottom: 0;
                width: 100%;
                padding: 10px 5px;
                &:not(.wishlist-add) {
                    a,span {
                        border: 1px solid #000;
                        height: 42px;
                        display: block;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                    }
                }
            }
            &.current {
                @include media-breakpoint-down(sm) {
                    span {
                        background-color: #000;
                        color: #fff;
                        
                    }
                }
                
                @include media-breakpoint-up(md) {
                 
                    border-bottom-color: #000;
                }

            }
        }

       
    }
     .wishlist-add {
            float: right;
            @include media-breakpoint-down(sm) {
                float: none;
                max-width: 300px;
                margin: 15px 0 ;
            }
            @include media-breakpoint-up(md) {
                position: absolute;
                right: 0;
            }
        }
    // multi wishlist toolbar end


    .wishlist-title {
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        strong {
            font-weight: normal;
            @include heading-2;

        }

    }

    .wishlist-info {
        display: inline-flex;
        float: left;
        font-size: $font_size-sm;
        .wishlist-notice  {
            margin-right: 15px;
        }
    }


    .wishlist-toolbar {
        display: inline-flex;
        float: right;
        font-size: $font_size-md;
        margin-bottom: 0;
        width: auto;
        @include media-breakpoint-down(md) {
            float: left;
            display: block;
            width: 100%;
            margin-top: 15px;
        }

        .wishlist-toolbar-select {
            margin-right: 15px;
            @include media-breakpoint-down(md) {
                  display: block;
            }
        }

        .wishlist-toolbar-actions {
            display: flex;
            button.remove {
                margin-top: 12px;
            }
            @include media-breakpoint-down(md) {
                width: 100%;
                justify-content: space-between;
                margin-top: 15px;
                flex-direction: column;
            }
        }


    }

}
.wishlist-dropdown {
    position: relative;
    margin-bottom: 10px;
    font-size: 12px;
    @include media-breakpoint-up(md) {
        padding-right:15px;
    }

    &.move {
        @include media-breakpoint-up(md) {
            font-size: 12px;
        }
    }
    &.active {
        ul.dropdown {
            display: block;
        }
    }
    .action.toggle {
        cursor: pointer;
        position: relative;
        // padding-right: 15px;
        &:focus{
            outline: none;
        }

        &.active {
            &:after {
                background-color: $body_selection-colour;
            }
        }


    }

    ul.dropdown {
        background: #fff;
        border: 1px solid $colour_secondary;
        margin-top: 5px;
        min-width: 200px;
        z-index: 100;
        display: none;
        position: absolute;
        right: 0;
        top: 100%;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
        &:before,
        &:after {
            content: "";
            position: absolute;
            left: auto;
            right: 9px;
            width: 0;
            height: 0;
            display: block;
            // display: none;
        }
        &:before {
            top: -15px;
            z-index: 99;
            border: 8px solid;
            border-color: transparent transparent #fff;
        }
        &:after {
            top: -16px;
            z-index: 98;
            border: 8px solid;
            border-color:  transparent transparent $colour_secondary;
        }
    }

    li {
        padding: 5px 15px;
        position: relative;

        &:hover {
            background: #000;
            color: #fff;
            cursor: pointer
        }

        .new {
            &:after{
                // @include font-icon('\e91a');
            }
        }
    }
}



// product wishlist grid
.products-grid.wishlist {
    float: left;
    width: 100%;

    li.product-item {
        text-align: left;
        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

    }
    .product-item-info {
        padding: 15px;
        margin: -15px;
        border: 1px solid transparent;
        &:hover {
             .product-item-inner { 
                 display: block;
            }   
        }

        @include media-breakpoint-up(md) {
            &:hover {
                background: #fff;
                box-shadow: 3px 4px 4px 0 rgba(0, 0, 0, 0.3);
                border: $border-default;
                position: relative;
                z-index: 2;
                .product-item-inner {
                    background: #fff;
                    border: $border-default;
                    box-shadow: 3px 4px 4px 0 rgba(0, 0, 0, 0.3);
                    border-top: none;
                    display: block;
                }
            }
        }

        .product-item-inner {
            @include media-breakpoint-up(md) {
                position: absolute;
                display: none;
                left: 0;
                right: -1px;
                left: -1px;
                z-index: 2;
                padding: 15px;
                border: 1px solid transparent;
            }
        }
    }

    .product-item-photo {
        display: block;
        text-align: center;
    }

    .product-item-checkbox {
        margin: 2px 5px 0 0;
        float: left;
    }
    .product-reviews-summary {
        display: flex;
        margin-bottom: 15px;
    }
    .reviews-actions {
        margin-top: 0;
    }
    a.minimal-price-link span.price-container.price-final_price.tax.weee {
        margin-top: 8px;
    }
    .price-box {
        font-size: $font_size-md;
        font-weight: bold;
        margin: 6px 0;
        position: relative;
        display: flex;
        justify-content: space-between;
        .price {
            // position: absolute;
            // right: 0;
            // top: 10px;
        }
    }
    .price-as-configured {
        display: flex;
        justify-content: space-between;
    }
    span.special-price {
        display: flex;
        flex-direction: column;
        // &:before{
        //     content: "Full pack";
        // }
    }
    span.old-price {
        display: flex;
        flex-direction: column;
        &:before{
            content: "Split pack";
        }
    }
    span.price-label {
        display: block !important;
        font-size: 12px;
        font-weight: normal;
    }
    .special-price .price-box .price {
        font-size: 16px;
        font-size: 16px;
        font-weight: bold;
    }
    .tooltip {
        dt {
            float: left;
            margin-right: 10px;
        }
    }
    .box-tocart {
        position: relative;
    }
    .field.qty {
        display: flex;
        align-items: center;
        .label {
            margin-right: 3px;
            margin-bottom: 0;
            font-weight: bold;
            width: auto;
        }
        input {
            max-width: none;
            height: 35px;
            line-height: 35px;
        }
        .control {
            width: 50px;
        }
    }

    .comment-box {
        label {
            @include visuallyhidden;
        }
        .product-item-comment {
            min-width: inherit;
        }
    }

    // add to cart box strat
    .fieldset {
        display: block;
        margin: 10px 0;
        width: 100%;

        .field.qty {
           width: auto;
        }
        .product-item-actions {
            width: 100%;
            text-align: right;
          
            top: 100%;
            display: block;
            .actions-primary {
                padding: 0;
            }
        }
    }
    
    .quantity-wrap {
        justify-content: flex-start;
    }
    // add to cart box end

    // Icons edit and remvoe start
    .action.edit {
        display: block;
        margin-bottom: 10px;
    }

    .btn-remove {
        float: left;
    }
    .btn-remove,
    .edit {
        margin-top: 5px;

    }

    // Remvoed giftregisty from listing done by Ahmed
    .giftregisty-dropdown {
        @include visuallyhidden;
    }



    //// dropdowns - change top arrow postion
    ul.dropdown{
        &:before,
        &:after {
            left: 15px;
            right: auto;

        }
    }
    ////
}

.form-wishlist-items {
    .actions-toolbar {
        float: left;
        width: 100%;
        a.action.back span,
        .primary button span { //don't change
            font-size: $font_size-sm;
        }
        .primary {
            @include media-breakpoint-up(md) {
                flex-direction: column;
                align-items: flex-end;
                button {
                    width: 100%;
                }
                > * ,button.update {
                     @include make-col-ready;
                     @include make-col(6);
                     padding: 0;
                }
            }
            @include media-breakpoint-down(sm) {
                button.update {
                     @include make-col(12);
                     padding: 0;
                }
            }
        }
        // .action.remove {
        //    margin-top: 12px;
        // }
    }
}


.product-item-info {
    &:hover {
        .wishlist-dropdown .action.toggle  {
            &:before {
                display: none;
            }
        }
    }
}


// popup used on wishlist pages

.wishlist.window.popup {


    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateY(-200%);
            transform: translateY(-200%);
    -webkit-transition: -webkit-transform 0.2s ease;
            transition: transform .2s ease;

    margin: 90px  auto 60px;
    width: 70%;
    max-width: 550px;
    height: 250px;
    padding: 30px;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }

    &.active {
        z-index: 999 !important;
        visibility: visible;
        opacity: 1;
        -webkit-transform: translateY(0);
                    transform: translateY(0);


        //background: $modal_background-color;
        //padding: $modal-popup_padding;
    }
    .field {
        text-align: left;
    }
    .popup-actions {

        position: absolute;
        right: 10px;
        top: 10px   ;
        z-index: 9999s;
        display: none;
        span {
            background: none;
            border: 0;
            line-height: inherit;
            margin: 0;
            padding: 0;
            font-size: 0;
            &:before{
                @include font-icon('\e924');
                font-size: 18px;
                color: #000;
            }

        }

    }
    .popup-header {
        flex: 1;
        padding: 15px;
        position: relative;
        background: #fff;

        .title {
            @include heading-2;
              @include media-breakpoint-down(sm) {
                font-size: 24px;
              }
        }
    }

    .actions-toolbar {
       
    
        .primary {
            display: inline-block;
        }
        .secondary {
            display: inline-block;
        }

    }
    .selectric-items {
        left: 0;
        right: 0;
        margin: auto;
    }
}

.wishlist.overlay {
    background-color: rgba(51, 51, 51, 0.55);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 990 !important;
}


/* ============================================ *
 * Remove from wishlist on product page ppup
 * ============================================ */
.custom-wishlist-popup {
    .modal-inner-wrap {
        max-height: 100%;
    }
    #customer-wishlists  {
        select {
            height: 98px;
            overflow: auto;
            font-size: 16px !important;
            
        }
        option {
            display: flex;
            align-items: center;
            padding-left: 10px;
            height: 30px;
          
        }
        
    }

}