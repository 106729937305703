/* ============================================ *
 * Reset
 * ============================================ */
*,
*:before,
*:after { 
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
			box-sizing: border-box;
}

body { 
	background: $body_background-colour;
	color: $font_colour-default;

	// fonts
	font-family: $font_family-deafult; 
	text-align: center;
	@include font-style($font_size-default, $font_line-height-default, $font_letter-spacing);
}

img {
	border: 0 none;
	-ms-interpolation-mode: bicubic;
	vertical-align: top;
	max-width: 100%;
}

a { 
	color: $link_color;
	text-decoration: $link_text-decoration;
	&:hover { 
		text-decoration: $link_hover-text-decoration;
		color: $link_hover-color;
		cursor: pointer;
	}
	&:focus {
		outline:0;
	}
}


/* ============================================ *
 * Lists
 * ============================================ */
ul,
ol { list-style:none; }


/* ============================================ *
 * Tools
 * ============================================ */
.hidden       { display:block !important; border:0 !important; margin:0 !important; padding:0 !important; font-size:0 !important; line-height:0 !important; width:0 !important; height:0 !important; overflow:hidden !important; }
.nobr         { white-space:nowrap !important; }
.wrap         { white-space:normal !important; }
.a-left       { text-align:left !important; }
.a-center     { text-align:center !important; }
.a-right      { text-align:right !important; }
.v-top        { vertical-align:top; }
.v-middle     {vertical-align:middle;float: right;padding-left: 20px;display: inline-block;}
.f-left,
.left         { float:left !important; }
.f-right,
.right        { float:right !important; }
.f-none       { float:none !important; }
.f-fix        { float:left; width:100%; }
.no-display   { display:none; }
.no-margin    { margin:0 !important; }
.no-padding   { padding:0 !important; }
.no-bg        { background:none !important; }

::-moz-selection { background: $body_selection-colour; text-shadow: none; }
::selection { background:#ffb02f; text-shadow: none; }




/* ============================================ *
 * Caroulses and sliders
 * ============================================ */
.full-width-slider {
	@include make-container;
	padding: 0;
	img { 
		width: 100%
	}
	.slick-prev { left: 5px }
	.slick-next { right: 5px }
}
/* ============================================ *
 * Container classes
 * ============================================ */
.container {
	@include make-container;
}

/* ============================================ *
 * Page titles and headings
 * ============================================ */
.page-title-wrapper {
	// @include make-container;
	// padding-top: 40px;
	// padding-bottom: 40px;
	// text-align:left;
	// h1 {

	// }
	// span {

	// }
	// @media(max-width: $screen-sm-min) {
	// 	padding-top: 20px;
	// 	padding-bottom: 20px;
	// }
}


.loader {
	height: 100%;
	position: fixed;
	right: 0;
	left: 0;
	z-index: 9999999;
	background: rgba(255, 255, 255, 0.5);
	display: flex;
	font-size: 0;
  	img {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
  	}
  	p {
  		font-size: 0;
  	}
}
