/*******************************
** MANADEV
***********************************/
  /* Responsive layout */
  .one-filter-column .mana-filter-slider {
    margin-bottom: 20px;
  }
  .one-filter-column .mana-slider-values {
    line-height: 25px;
  }
  .one-filter-column .mana-slider-values input {
    width: 50px;
    padding: 1px 3px;
  }
  #mana-please-wait img {
    display: none;
   }
  /* Default style - style1 */
  .mana-filter-slider {
    height: 17px;
    position: relative;
    background: transparent url('../Manadev_LayeredNavigationSliders/images/bkg_slider.png') 0 0 repeat-x;
    margin: 15px -10px;

  }
  .mana-filter-slider>.mana-slider-from:before {
    left: 13px;
    content: "";
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 15px;
    width: 10px;
    top: -2px;
    z-index: 4;
    border: solid transparent;
    position: absolute;
    pointer-events: none;
    border-color: #000;
    border-top-color: #000;
    border-radius: 20px;
    border-width: 5px;
  }
  .mana-filter-slider>.mana-slider-to:before {
    left: -10px;
    content: "";
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 15px;
    width: 10px;
    top: -2px;
    z-index: 4;
    border: solid transparent;
    position: absolute;
    pointer-events: none;
    border-color: #000;
    border-top-color: #000;
    border-radius: 20px;
    border-width: 5px;
}
  .mana-filter-slider > .mana-slider-from,
  .mana-filter-slider > .mana-slider-to {
    height: 17px;
    width: 17px;
    position: absolute;
  }
  .mana-filter-slider > .mana-slider-span {
    height: 8px;
    position: absolute;
    background: none;
    border-bottom: 2px solid #000;
    margin: 0;
    left: 15px !important;
    width: 200px !important;
  }
  .mana-filter-slider > .mana-slider-from {
    background: transparent url('../Manadev_LayeredNavigationSliders/images/slider.png') 0 0 no-repeat;
    cursor: e-resize;
  }
  .mana-filter-slider > .mana-slider-to {
    background: transparent url('../Manadev_LayeredNavigationSliders/images/slider.png') -19px 0 no-repeat;
    cursor: w-resize;
  }
  .mana-slider-values {
    text-align: center;
    margin-top: 3px;
    overflow: hidden;
    @include media-breakpoint-down(sm){
        margin: 0 10px;
    }
  }
  .mana-slider-values .mana-slider-min-value {
    float: left;
  }
  .mana-slider-values .mana-slider-max-value {
    float: right;
  }



// Variables
$filter_options-title-font-size: 14;
$filter_options-title-font-family: $font_heading;

$filter_options-content-font-size: 12;
$filter_options-content-font-family: $font_family-secondary;

/* ============================================ *
 * Layerd Nav
 * ============================================ */
.catalogsearch-result-index {
    .filter .filter-title {
        @include media-breakpoint-down(sm) {
            position: absolute;
            top: 76px;
            left: 14px;
        }
    }
}

.product-layer-wrapper {
    position: relative;

    @include media-breakpoint-down(md) {
        .toolbar-products:first-child {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
        }
    }
}

.filter {
    text-align: left;
    padding: 0 10px;

    @include media-breakpoint-up(sm) {
        padding: 0 30px 0 0;
        display: block;
    }

    .ui-slider-handle {
        background: $primary-colour;
    }
    .am-show-button {
        padding: 0;
        border: 0;
        box-shadow: none;
        border-radius: 0;
        &:before {
            display: none;
        }
    }
    .amshopby-button {
        border-radius: 0; 
        &:hover {
            border-color: #000;
        }
    }
    .am_shopby_apply_filters {
        position: static;
        display: none;
        visibility: hidden;
        padding: 0;
        
        &.-fixed {
            position: static;
        }
    }
   /* &:not(.active) {
        .am_shopby_apply_filters {
            display: none;
        }
    }*/

    .filter-current {
        a {
            &:hover {
                background: none !important;
            }
        }
         
        li.item{
            position: relative;
            display: flex;
            padding-bottom: 5px;
        }
        a.action.remove{
            width: 20px;
            height: 20px;
            position: absolute;
            right: 0;
            padding: 0;
            &:after {
                content: "X";
                top: -10px;
                right: 6px;
                font-weight: 600;
                position: absolute;
            }
        }
        
    }
    .amshopby-filter-current  {
        .amshopby-item.item ,.amshopby-items{
            padding: 0;
        }

        .amshopby-filter-name {
            display: flex;
            padding: 0 5px 0 0;
            font-weight: 700;

            &:after {
                content: ':';
            }
        }
    }
    .amshopby-item-removed {
        display: none !important;
    }
    a.amshopby-remove {
        left: auto;
        &:before {
            display: none;
        }
        &:after {
            transform: none;
            background-color: unset;
            width: auto;
            height: auto;
        }
        &:hover {
            ~ * {
                opacity: 1;
            }
        }  
        &:hover {
            &:after {
                background-color: unset;
            }
        }
    }
    a.filter-clear {
      color: inherit;
      font-size: 14px;
      @include media-breakpoint-down(sm){
        text-align: center;
        display: block;
      }
      &:hover {
        color: inherit;
      }
    }

    .filter-options-content {
        .item {
           
            a:hover {
                background-color: #eee;
                color: inherit;
            }
           
            a[class*="am-filter-item"] {
                padding: 0 20px;
                margin-bottom: 5px;

                @include media-breakpoint-down(sm){
                    padding: 15px 20px;
                    @include font-size(12);
                    font-family: "CeraPro-Bold", Times, serif;
                    line-height: normal;
               }
            }
            .label {
              font-size: unset;
            }

            a.amshopby-link-selected {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: inherit;
                background: #eee;

                &:after {
                    @include media-breakpoint-down(sm){
                        //top: 0;
                    }
                }
            }
            .am-input, input[type='checkbox'] {
                display: none;
            }
        }
       
        form {
            .items {
                &:not(.items-children) {
                    max-height: 200px;
                    overflow-y: auto;
                }
                &:not(.items-children):not(.am-category-view) {
                    margin: 0;
                }
                .items-children {
                    &.-folding  {
                        padding: 0;
                    }

                }
            }
        }

        #amount {
            color: $primary-colour !important;
        }

        p {
            display: flex;
        }

        label {
            min-width: 40%;
            line-height: 40px;
            padding-left: 20px;
        }

        #price-slider {
            @include media-breakpoint-down(md) {
                margin: 0 20px;
            }
        }
    }

    &.active {
        display: block;
        position: fixed;
        z-index: 99999;
        clear: both;
        background: #fff;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        @include media-breakpoint-down(md) {
            padding: 0;
            overflow: auto;
        }
        .manadev-show-more {
            @include media-breakpoint-down(sm) {
                padding-left: 10px;
            }
        }
        .filter-title {
            text-align: left;

        }        

        .filter-content {
            .filter-options {
                display: block;
            }

        }
    }

    .swatch-attribute-options {
        @include media-breakpoint-down(md) {
            padding: 0 20px;
        }
    }

    /*.filter-options-item.active {
        .filter-options-title {
            &:after {
                @include font-icon('\e93a');
                font-size: $font-lg;
            }
        }
    }*/

    .filter-title {
        display: block;
        margin: 15px 0 14px;
        font-family: $font_family-secondary;

        @include media-breakpoint-up(md) {
            pointer-events: none;
        }

        @include font-style(24, 1.42, normal);
        font-stretch: normal;

        @include media-breakpoint-between(sm, md) {
            margin-top: 75px;
        }

        @include media-breakpoint-down(sm) {
            margin: 0;
            text-align: left;
            cursor: pointer;
            clear: both;
            border: 1px solid #9b9b9b;
            width: 100%;
            line-height: 30px;
            margin: 0;
        
        }

        strong {
            font-weight: normal;

            &:before {
                display: none;
            }

            @include media-breakpoint-down(sm) {
                font-family: $font_family-primary-bold;
                @include font-style(14, normal, normal);
                font-style: normal;
                line-height: 37px;
                color: #000;
            }
        }
    }

    .filter-current {
        @include media-breakpoint-down(sm) {
            display: flex;
            justify-content: flex-start;      
            padding: 0px 15px 12px 22px;
            flex-direction: column;
            margin: 0px -10px;
            text-align: left;

            /*.remove {
                display: none;
            }*/

            .filter-current-subtitle {
                font-size: 16px;
            }
            .amshopby-filter-name {
                font-weight: normal;
            }

            .items {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                @include media-breakpoint-down(sm){
                    flex-direction: column;
                }

                li {
                    font-size: 14px;
                    font-family: $font_family-primary-bold;
                    padding: 0 4px;
                    @include media-breakpoint-down(sm){
                        @include font-size(14);

                    }

                    /*&:after {
                        display: inline-block;
                        content: "X";
                        position: absolute;
                        right: 10px;
                    }*/


                }
            }
        }
    }
    strong.block-subtitle.filter-subtitle{
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    strong.block-subtitle.filter-current-subtitle{
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    .filter-content {
        display: block;
        position: relative;

        @include media-breakpoint-down(sm) {
            .block-actions {
                a {
                    position: absolute;
                    top: 25px;
                    right: 15px;
                    font-size: 0;

                    &:before {
                        // @include font-icon('\e904');
                        content:"Clear all";
                        display: block;
                        font-size: 14px;
                        color: $primary-colour;
                    }
                }
            }

           
        }

        >strong{
            display: none;
        }

        .filter-label{
            font-family: "CeraPro-Bold", Times, serif;
        }
       
        .remove-icon{
            display: none;
        }
        .swatch-attribute.color:before {
            display: none;
        }

        .swatch-option {
            margin: 3px;
        }
    }



    div.filter-options-title {
        border: 0;
        border-bottom: 2px solid #eeeeee;
        font-size: 16px;
        font-family: $font_family-primary-bold;
        color: $primary-colour;
        padding: 20px 0;
        clear: both;
        outline: none;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        @include media-breakpoint-down(md) {
            padding: 20px;
        }

        &:after {
            @include font-icon('\e93d');
            font-size: $font-lg;

        }
    }

    .my.item,
    .item {

        a {
            padding: 0 10px;
            line-height: 40px;
            position: relative;
            width: 100%;
            display: block;
            text-align: left;
            -webkit-transition: all .25s ease;
            transition: all .25s ease;
            font-family: $font_family-primary;
            font-size: 14px;

            @include media-breakpoint-down(Sm){
                padding: 0 20px;
            }

            &:hover {
                background: #eeeeee;
            }
            &.checked,&.amshopby-link-selected{
                background: #eeeeee;

                &:after {
                    display: flex;
                    @include font-icon('\e90a');
                    font-size: 16px;
                    margin-right: 10px;
                }

                @include media-breakpoint-down(Sm){
                    background: none;
                    span {
                        background: #eeeeee;
                    }
                }
            }

            // &:hover:before {
            //     @include font-icon('\e90a');
            //     position: absolute;
            //     left: 0px;
            //     width: 20px;
            //     top: 8px;
            //     height: 20px;
            //     line-height: 20px;
            //     font-size: 16px;
            // }

            /*
      &:before {
        content: "";
        height: 16px;
        width: 16px;
        position: absolute;
        left: 0;
        top: 4px;
        border: 1px solid rgba(0,0,0,0.11);
      }
      &:hover:before {
        background:#000;
      }
      &:after {
        position: absolute;
        left: 6px;
        top: 6px;
        content: '';
        height: 14px;
        width: 14px;
        vertical-align: -0.062em;
        background-color: #fff;
        -webkit-mask: url(../icons/icomoon/icon-checkmark.svg) no-repeat;
        mask: url(../icons/icomoon/icon-checkmark.svg) no-repeat;
        -webkit-mask-size: contain;
        mask-size: contain;
        opacity: 0;
        -webkit-transition: all .25s ease;
        transition: all .25s ease;
      }
      */
        }
    }
}

.close.icon-filter {
    right: 15px;
    position: absolute;
    top: 10px;
}

.filter-options {
    input[type="checkbox"] {
        + span.amshopby-choice {
            display: none;
        }
    }
    dt {
        font-family: $filter_options-title-font-family;
        @include font-style($filter_options-title-font-size, $h4-m-height, $h4-m-spacing);

        padding: 0;
        border: 1px solid $colour_secondary;
        margin-bottom: 5px;

        cursor: pointer;

        &.active {
            border-color: #000;
        }
    }
    .filter-options-title {
        &.active {
            border-color: #000;
        }
    }

    dd {
        padding: 0;
        font-family: $filter_options-content-font-family;
        @include font-style($filter_options-content-font-size, $h4-m-height, $h4-m-spacing);

        li.item {
            padding-bottom: 5px;

            &:hover {
                a {
                    text-decoration: underline;
                }
            }

            .count {
                font-weight: bold;
                font-size: 10px;
                color: $colour_secondary;
            }
        }
    }
}

.ln_overlay {
    display: none;
}

.manadev-expandable-filter{
    .inner-container{
        padding: 0 !important;
    }
}
.manadev-expandable-filter.outer-container.scrollbar {
    max-height: 200px;
    overflow-y: auto;
}
.manadev-expandable-filter.outer-container.show-more-show-less{
    overflow: hidden;
    // max-height: 400px !important;
    // overflow-y: auto;
}
.apply-filters{
    margin-top: 20px;
    @include media-breakpoint-down(sm){
        margin: 20px;
        @include font-size(12);
    }
    button{
        display: inline-block;
		width: 100%;
		padding: 0 $button-padding;

		white-space:nowrap;

		// Text Styles
		text-align:center;
		text-transform: $button-text-transform;

		color: $button-color;
		font-size: $button-font-size;
		height: $button-height;
		line-height: $button-line-height;
		font-family: $font_family-primary-bold;
		// Custome Styles
		border: $button-border-size solid $button-border-color;
        background: $button-background;
        &:hover{
            background: #000;
            color: #fff;
            span{
                color: #fff;
            }
        }
        a{
            width: 100%;
            height: 100%;
            display: block;
        }
    }
}
/**********************
*** Mobile design
**********************/
@include media-breakpoint-down(sm){

    .category-view{
        //display: none;
    }
    div#mana_ajax_wrapper_main_mana_layered_nav {
        margin-top: 20px;
    }
    .filter {
        &.active {
            position: static;
            margin: 0 10px;
            filter-title {
                background-color: none;
                strong {
                    color: inherit;
                }
            }
            
        }
        .filter-content {
            padding: 10px;
            border: 1px solid #eee;
            margin: 10px 0;
            display: none;
        }
        div.filter-options-title {
            padding: 0 0 5px 0;
            border-bottom: 1px solid #f3f3f3;
            margin: 10px;
            @include font-size(14);
            &:after {
                transform: rotate(270deg);
                @include font-size(12);
            }
            &[aria-expanded="true"]{
                &:after {
                    @include font-icon('\e93d');
                    font-size: $font-sm;
                    transform: rotate(0deg);
                }
            }
        }
        .filter-title {
            margin: 10px 0;
            strong {
                width: 100%;
                padding-left: 10px;
                text-align: left;
            }
        }
    }
    .apply-filters {
        margin: 0;
    }

    
}

#mana-please-wait {

    width: 300px;
    height: 140px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    background: #fff;
    border:1px solid #000;
    img{
        display: none;
    }
    &:before{
        content: "";
        background: url(../images/load.gif) 0 center no-repeat;
        width: 100px;
        height: 74px;
        display: flex;
        justify-content: center;
        width: 70px;
        position: absolute;
        top: 15px;
        left: 115px;
    }
    p {
        width: 100%;
        position: absolute;
        bottom: 15px;
        font-family: "CeraPro-Bold", Times, serif;
        text-transform: uppercase;
        font-size: 14px;
    }
}
/****************MANADEV MOBILE*******************/
.mana-filter-block{
    &.mana-mobile{
        @include media-breakpoint-up(md){
            display: none;
        }
        ol.items{
            margin: 0 10px;
        }
        li.item{
            text-align: left;
            position: relative;
            padding-bottom: 10px;
            a{
                &:hover {
                    background: #eeeeee;
                }
                &.checked , &.amshopby-link-selected{
                    background: #eeeeee;

                    &:after {
                        display: flex;
                        @include font-icon('\e90a');
                        font-size: 16px;
                        margin-right: 10px;
                    }
                }
            }

        }
        .filter-remove{
            display: none;
        }
        .m-applied-filters{
            li.item{
                .action.remove{
                    .remove-icon{
                        display: none;
                    }
                    &:after {
                        display: inline-block;
                        content: "X";
                        position: absolute;
                        right: 10px;
                    }
                }
            }
        }
        a.action.clear.filter-clear {
            width: 100%;
            display: block;
            padding: 10px;
        }


        .filter-options {

            dt{
                margin: 10px;
                &.active {
                    border-color: #f3f3f3;
                }
                &.filter-name{
                    border: none;
                    padding-bottom: 5px;
                    border-bottom: 1px solid #f3f3f3;
                    text-align: left;
                    display: flex;
                    justify-content: space-between;
                    &:after {
                        @include font-icon('\e93d');
                        font-size: $font-sm;
                        transform: rotate(-90deg);
                    }
                }
            }
            .filter-name[aria-expanded="true"]{
                &:after {
                    @include font-icon('\e93d');
                    font-size: $font-sm;
                    transform: rotate(0deg);
                }
            }

        }
        .block-content {
            padding: 10px;
            border: 1px solid #eee;
            margin: 10px;
            display: none;
        }
        .m-filter-title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 40px;
            padding-left: 10px;

        }

        a.action.mana-apply-filters {
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid #000;
            margin-top: 20px;
            &:hover{
                background: #000;
                span{
                    color: #fff;
                }
            }
        }
    }
}
#mana_ajax_wrapper_main_mana_layered_nav{
    @include media-breakpoint-down(sm){
        display: none;
    }
}
#mana_ajax_wrapper_mobile_mana_layered_nav{
    @include make-container();
}
.page-with-filter {
    .sidebar-main {
        display: flex;
        flex-direction: column;
        .breadcrumbs {
            order: -1;
        }
    }
}
div.amshopby-slider-container {
    .am-slider {
        position: relative;
        .ui-slider-handle {
            position: absolute;
            z-index: 2;
            background: #000 !important;
            box-shadow: none !important;
            width: 10px !important;
            border-radius: 25% !important;
            span {
                background: #000 !important;
                border-color: #000 !important;
            }
        }
        .ui-slider-range {
            background: #000 !important;
        }
    }
}