
/* ============================================ *
 * Reviews on product page
 * ============================================ */
#product-review-container {
    @include make-col-ready;
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
    @include media-breakpoint-down(md) {
      @include make-col(12);
    }
	text-align: left;

	.toolbar {
		background: $secondary-colour;
		margin: $margin/3 0;
		padding: $padding/3;
	}
	.review-item {
		border-bottom: $border-width $border-type $border-light-colour;
		margin: 0;
		padding: $padding 0;

		&:after {
  			clear: both;
			content: '';
			display: table;
		}
		&:last-child {
			border-width: 0;
		}
	}
	.review-title {
	    font-size: $font-lg;
	    margin-bottom: $margin;
	}
	.review-content {
	  margin-bottom: $margin;
	}
	.review-ratings {

	}
	.review-details {
		font-size: $font-sm;
	}

}

.block {
	&.review-add {
      @include make-col-ready;
      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }
      @include media-breakpoint-down(sm) {
        @include make-col(12);
      }
		text-align: left;
	 
    .field {
      margin-bottom: $margin;
      input,
      textarea {
        width: 100%;
        max-width: 300px;
      }
    }
  }
	
} 


/* ============================================ *
 * Rating-summary
 * ============================================ */
.rating-summary .rating-result {
  width: 88px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.rating-summary .rating-result:before {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  -webkit-font-smoothing: antialiased;
  color: #c7c7c7;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: $font-md;
  height: $font-md;
  letter-spacing: 2px;
  line-height: $font-md;
  content: '\f005' '\f005' '\f005' '\f005' '\f005';
  display: block;
  font-style: normal;
  font-weight: normal;
  speak: none;
}
.rating-summary .rating-result > span {
  display: block;
  overflow: hidden;
}
.rating-summary .rating-result > span:before {
  position: relative;
  z-index: 2;
  -webkit-font-smoothing: antialiased;
  color: #ff5501;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: $font-md;
  height: $font-md;
  letter-spacing: 2px;
  line-height: $font-md;
  content: '\f005' '\f005' '\f005' '\f005' '\f005';
  display: block;
  font-style: normal;
  font-weight: normal;
  speak: none;
}
.rating-summary .rating-result > span span {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.product-reviews-summary .rating-summary .label,
.table-reviews .rating-summary .label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}


/* ============================================ *
 * review-control-vote
 * ============================================ */
.review-control-vote {
  overflow: hidden;
}
.review-control-vote:before {
  color: #c7c7c7;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: $font-md;
  height: $font-md;
  letter-spacing: 2px;
  line-height: $font-md;
  font-style: normal;
  font-weight: normal;
  speak: none;
  vertical-align: top;
  -webkit-font-smoothing: antialiased;
  content: '\f005' '\f005' '\f005' '\f005' '\f005';
  display: block;
  position: absolute;
  z-index: 1;
}
.review-control-vote input[type="radio"] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.review-control-vote input[type="radio"]:focus + label:before,
.review-control-vote input[type="radio"]:checked + label:before {
  opacity: 1;
}
.review-control-vote label {
  cursor: pointer;
  display: block;
  position: absolute;
}
.review-control-vote label span {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.review-control-vote label:before {
  color: #ff5501;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: $font-md;
  height: $font-md;
  letter-spacing: 2px;
  line-height: $font-md;
  font-style: normal;
  font-weight: normal;
  speak: none;
  vertical-align: top;
  -webkit-font-smoothing: antialiased;
  opacity: 0;
}
.review-control-vote label:hover:before {
  opacity: 1;
}
.review-control-vote label:hover ~ label:before {
  opacity: 0;
}
.review-control-vote .rating-5 {
  z-index: 2;
}
.review-control-vote .rating-5:before {
  content: '\f005' '\f005' '\f005' '\f005' '\f005';
}
.review-control-vote .rating-4 {
  z-index: 3;
}
.review-control-vote .rating-4:before {
  content: '\f005' '\f005' '\f005' '\f005';
}
.review-control-vote .rating-3 {
  z-index: 4;
}
.review-control-vote .rating-3:before {
  content: '\f005' '\f005' '\f005';
}
.review-control-vote .rating-2 {
  z-index: 5;
}
.review-control-vote .rating-2:before {
  content: '\f005' '\f005';
}
.review-control-vote .rating-1 {
  z-index: 6;
}
.review-control-vote .rating-1:before {
  content: '\f005';
}
.review-control-vote:before {
  content: '\f005' '\f005' '\f005' '\f005' '\f005';
}


.review-control-vote label:before,
.review-control-vote:before {
  font-size: 32px;
  height: 32px;
  letter-spacing: 10px;
  line-height: 32px;
}

.review-field-rating {
  .control {
    margin-bottom: 48px;
    margin-top: $margin;
    label {
      width:auto;
    }
  }
}



.review-ratings {
  display: table;
  margin-bottom: $margin;
  max-width: 100%;
}




