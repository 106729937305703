
/* ============================================ *
 * Font Icons
 * ============================================ */

@font-face {
  font-family: 'icomoon';
  font-weight: normal;
  font-style: normal;
  src:	url('../fonts/font-icons/icomoon.woff2?xyrt8u') format('woff2');
  font-display: swap;
}

$font_family-icon: 'icomoon';


@import "tools/mixins/_font-icons.scss";

.icon-account {
	@include font-icon-before('\e904');
}
.icon-cart {
	@include font-icon-before('\e906');
}
.icon-phone{
	&:before{
		content:"";
		display: block;
		background-image: url(../images/phone-white.png);
		background-size: cover;
		height: 18px;
		width: 18px;
	}
}
.icon-padlock{
	&:before{
		content:"";
		display: block;
		background-image: url(../../web/images/padlock.png);
		background-size: cover;
		height: 14px;
		width: 14px;
		margin-right: 5px;
	}
}
.icon-search {
	@include font-icon-before('\e925');
}
.icon-wishlist {
	@include font-icon-before('\e92c');
}
.icon-menu {
  @include font-icon-before('\e911');
}
.icon-filter {
  @include font-icon-before('\e904');
}
.icon-newsletter {
  @include font-icon-before('\e91d');
}
.icon-full-star {
  @include font-icon-before('\e906');
}
.icon-accordion-down-sold {
  @include font-icon-before('\e90e');
}
.icon-accordion-down {
	@include font-icon-before('\e911');
}
.icon-accordion-up {
  @include font-icon-before('\e90d');
}
.icon-arrow-left {
  @include font-icon-before('\e936');
}
.icon-arrow-right {
  @include font-icon-before('\e938');
}
.nav-toggle {
	@include font-icon-before('\e911');
}
.nav-open .nav-toggle {
	@include font-icon-before('\e911');
}

.icon-facebook {
	@include font-icon-before('\e90c');
	&:hover {
		color: #000;
	}
}
.icon-twitter {
	@include font-icon-before('\e913');

	&:hover {
		color: #000;
	}
}
.icon-pinterest	{
	@include font-icon-before('\e912');

	&:hover {
		color: #000;
	}
}

.icon-instagram	{
	@include font-icon-before('\e90f');

	&:hover {
		color: #000;
	}
}

.icon-linkedin {
	@include font-icon-before('\e910');

	&:hover {
		color: #000;
	}
}
  
.icon-houzz	{
	@include font-icon-before('\e90e');

	&:hover {
		color: #000;
	}
}

.icon-right-side {
	@include font-icon-before('\e902');
}

/* ============================================ *
* header Icons
* ============================================ */

/* ============================================ *
* Breadcrumbs
* ============================================ */
.icon-breadcrumb {
	@include font-icon-after('\e902');
		&:after {
			display: block;
			position: absolute;
			top: 3px;
    		bottom: 0;
    		right: 0px;
    		font-size: 8px;  
		}
}

/* ============================================ *
* Footer Icons
* ============================================ */
.footer .icon-twitter {
	font-size: 20px;
}
.insta-icon {
	font-size: 20px;
}
.footer .icon-facebook {
	font-size: 22px;
}

/* ============================================ *
 * Font-Awesome
 * ============================================ */
.mobile-only {
	display: none;
	@include media-breakpoint-down(md) {
		display: block;
	}
}
.desktop-only {
	display: block;
	@include media-breakpoint-down(md) {
		display: none;
	}
}