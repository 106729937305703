$nav_font-family: $font_family-deafult; // fonts.sass
$nav_parent-font-family: $font_family-primary; // fonts.sass
$nav_font-size: 15px; // $font_size-default; // fonts.sass
$nav_font-color: $font_colour-default; // var.sass
$nav_font-color-hover: $nav_font-color; // var.sass

// border
$nav_border-top: 0.5px solid $border_colour-default; // 0 none
$nav_border-bottom: 0.5px solid $border_colour-default; // 0 none

// colours 
$nav_background: transparent;

$nav-lv1-font-size: 15px;
$nav-lv2-font-size: 15px;
$nav-lv3-font-size: 18px;
$nav-lv4-font-size: 18px;

$active-nav-indent: 54px;

$parent_icon: "";

/* ============================================ *
 * General Navigation Styles
 * ============================================ */
.header-nav {
    //background: $nav_background;
    //border-top: $nav_border-top;
    //border-bottom: $nav_border-bottom;
    @include media-breakpoint-up(sm){
        padding-left: 0;
        padding-right: 10px;
        position: static;
    }
}

.nav-sections {
    font-size: $nav_font-size;
    color: $nav_font-color;
    font-family: $nav_font-family;
    @include make-container();
    position: static;
    padding: 0;
    height: 100%;
    .nav-sections-items,.nav-sections-item-content,.navigation {
        height: 100%;
    }

    @include media-breakpoint-down(md) {
        padding: 0;
    }
}

.nav-toggle {
    background: $secondary-colour;
    cursor: pointer;
    border-radius: 50%;
    font-size: 0;
    position: relative;
    z-index: 14;
    display: flex;
    align-items: center;
    @include make-col(1);

    &:before {
        color: $primary-colour;
        font-size: 20px;
        margin: 0 auto;
        transition: all ease-in 0.3s;
    }

    span {
        display: none;
        visibility: hidden;
    }
}



/* ============================================ *
 * Mobile
 * ============================================ */
@include media-breakpoint-down(sm) {
    .navigation {
        padding: 0;
        text-align: left;

        .parent {
            > a {
                position: relative;

                &:after {
                    position: absolute;
                    right: 15px;
                    top: 3px;
                }

                &.ui-state-active {
                    &:after {}
                }
            }
        }
    }
    .nav-sections {
        -webkit-overflow-scrolling: touch;
        position: fixed;
        top: 75px;
        left: 0;
        width: 100%;
        text-align: left;
        padding: 0;
        background: #f2f2f2;
        height: calc(100% - 75px);
        z-index: 999;
        transition: all ease-in 0.2s;
        transform: translateX(-100%);
        .header.links {
            border-bottom: 1px solid #eee;

            li {
                font-size: 1.6rem;
                margin: 0;

                &.greet.welcome {
                    border-top: 1px solid #eee;
                    font-weight: 700;
                    padding: .8rem 10px;
                }

                >a {
                    border-top: 1px solid #eee;
                }
            }

            a,
            a:hover {
                display: block;
                font-weight: 700;
                padding: .8rem 10px;
            }

            .header.links {
                border: 0;
            }
        }
    }

    .nav-before-open {
    	overflow: hidden ;
    	height: 100vh;
        .page-wrapper {
            height: 100%;
            left: 0;
            overflow: hidden;
            position: relative;
        }
    }

    .nav-open {
        .nav-toggle {
            &:before {
                @include font-icon('\e90b');
                background: #000;
                color: #fff;
                border-radius: 50%;
                padding: 0;
                width: 35px;
                height: 35px;
                line-height: 37px;
                font-size: 12px;
                margin: auto;
            }
        }

        .nav-sections {
            background: #fff;
            border-bottom: 2px solid #000;
            transform: translateX(-100%);
        }
    }

    /*NEW To close when click outside*/
    .nav-open .nav-toggle:after {
        content: '';
        display: block;
        height: 100%;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 1;
    }

    .nav-open .nav-sections {
    	transform: translateX(0);
        z-index: 999;
    }



    .nav-sections-items {
        position: relative;
        z-index: 1;
        height: 100%;
    }

    .nav-sections-item-title {
        display: none;

        .nav-sections-item-switch {
            &:hover {
                text-decoration: none;
            }
        }
    }

    .nav-sections-item-content {
        width: 100%;
        display: block;
        padding: 20px 0 10px 0;
        background: #fff;
        display: flex;
		flex-flow: column;
		height: 100%;

        &.active {
            display: block;
        }


    }

    .navigation {
        position: relative;
        height: 100%;
        overflow-y: auto;
        
        ul {
            margin: 0;
            padding: 0;
            .submenu{
                display: none;
                //padding-left: 5px;
            }
        }

        li {
            margin: 0;
            border-top: 1px solid #ccc;

            @include media-breakpoint-down(md) {
                border-top: 2px solid #e8e8e8;
                background: $secondary-colour;

            }            
            > a {
                padding: 20px 15px;
                word-wrap: break-word;
                font-size: $font-md;
                font-family: $font_family-primary-bold;
                text-transform: uppercase;
            }
        }

        a {
            display: block;
            padding: 10px;
        }

        a,
        a:hover {}

        li.parent {

            > a {
               	//width: calc(100% - 60px);

                &:after {
                	@include font-icon('\e93d');
					font-size: 17px;
					text-align: right;
					cursor: pointer;
					pointer-events: none;
                    width: 10px;
                    height: 20px;
                    margin: auto;                    
                    right: 20px;
                    top: 0;
                    bottom: 0;
					-webkit-transition: all 0.25s ease;
					transition: all 0.25s ease;
					padding: 0;
					display: flex;
					justify-content: center;
					align-items: center;
                    transform: rotate(-90deg);
                }

                &.active {
                	&:after{
						-webkit-transition: all 0.25s ease;
						transition: all 0.25s ease;
						transform: rotate(0deg);
                    }
                }
            }

            &:not(.parent) {
                >.level-top {
                    &:after {
                        display: none;
                    }
                }
            }

            &.active {
                .all-category {
                    .ui-state-focus {
                        padding-left: 20px;
                        display: inline-block;
                    }
                }
            }



            &.active,
            &.has-active {
                >a:not(.ui-state-active) {

                    span:not(.ui-menu-icon) {}
                }
            }
        }

        li.level1 > a {
            padding-left: 30px;
        }

        li.level2 > a {
            padding-left: 50px;
        }

        .nav-wrap {
            >li {
                word-wrap: break-word;
            }

            &:not(:first-child) {

                left: auto !important;
                overflow-x: hidden;
                padding: 0;
                position: relative;
                top: auto !important;
                transition: left .3s ease-out;

                >li {
                    >a {}

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                ul {
                    display: none;

                    >li {
                        margin: 0;

                        a {

                            display: block;
                            position: relative;

                            //line-height: normal;
                            &:hover {
                                background: #eeeeee;
                            }
                        }
                    }
                }

                &.expanded {
                    display: block !important;
                    padding-right: 0;
                    top: 0 !important;
                    padding: 0 20px;

                    span {
                        color: $primary-colour;
                        font-family: $font_family-primary;
                        font-size: 14px;
                    }
                }

                .active {
                    >a {}
                }

                .level1 {
                    &.active {
                        >a {
                            padding-left: 20px;
                        }
                    }
                }
            }
            &.active {
            	display: block;
            }
        }
        .submenu {
        	a {
        		font-size: 13px;
				color: #777;
        	}
        }
    }
    .nav_social_icons {
    	text-align: center;
    	display: block;
    	padding: 20px 0;
        border-top: 2px solid #eee;
        @include media-breakpoint-up(sm){
            display: none !important;
        }
        @include media-breakpoint-down(sm){
            display: block !important;
        }
    	li {
    		display: inline-block;
    		font-size: 20px;
			margin: 0 20px;
        }
        &.mobile-only{
            @include media-breakpoint-down(sm){
                display: block !important;
            }
        }
    }
}

/* ============================================ *
 * Desktop
 * ============================================ */
@include media-breakpoint-up(md) {
    .level0.submenu {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        z-index: 99999;
        padding: 0;
        align-items: flex-start;
        max-height: 530px;
        overflow: auto; 

        background: #fff;
        width: 100%;
        text-align: left;
        justify-content: flex-start;
        flex-wrap: nowrap;

        padding: 30px 40px;

        a {
            display: block;

            //line-height: inherit;
            &:hover,
            &.ui-state-focus {}
        }
        .submenu {
            display: block !important;
            position: static !important;
            text-align: left;

            &.level1 {
                margin-bottom: 25px;
                padding-top: 10px;
                border-top: 2px solid #a7a4a4;

                li {
                    a {
                        margin-bottom: 4px;
                        border-bottom: 1px solid transparent;

                        &:hover {
                            border-bottom: 1px solid #000;
                        }
                    }
                }
            }

        }
    }

    .nav-toggle {
        display: none;
    }

    .nav-sections-item-title {
        display: none;
    }

    .navigation {
        height: 100%;
        &:empty {
            display: none;
        }

        > ul {
            margin: 0 auto;
            float: none;
            text-align: left;
            padding: 0;
            //position: relative;

            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            height: 100%;

            a {
                display: block;
                font-size: 13px;
                font-family: $font_family-primary;
                position: static;

                &:hover {
                    >.category-image-wrap {
                        display: block;
                    }
                }
            }
        }
        li.show-all {
            display: none;
        }
        // Nav level 1 - parent
        li.level0 {
          
            height: 100%;
            align-items: center;
            display: flex;

            @include media-breakpoint-up(md) {

                // &:last-child {
                //     @include visuallyhidden;
                // }
            }

            &:hover>.submenu {
                display: flex;
            }
            &:hover>.level-top {
                text-decoration: none;

                >span {
                    border-color: #000;
                }
            }
            >.level-top {
                display: block;
                padding: 0 7px;
                font-family: $font_family-primary-bold;
                text-transform: uppercase;
                font-size: 12px;
                white-space: nowrap;
                display: flex;
                @media only screen and (min-width: 992px) and (max-width: 1340px)  { 
                    font-size: 11px !important;
                }

                /*For zooming & smaller screen*/
               /* @media(max-width: 1280px) {
                    padding: 0 4px;
                }*/

                >span {
                    border-bottom: 2px solid transparent;
                }

                &:hover,
                &.ui-state-focus {
                    text-decoration: none;

                    >span {
                        border-color: #000;
                    }
                }
            }

            &.active,
            &.has-active {
                >.level-top {}
            }

            &.parent:hover>.submenu {
                overflow: visible !important;
            }

        }

        // Nav level 1 - first child
        li.level1 {
            position: relative;
            @include make-col-ready;

            @include media-breakpoint-up(sm) {
                max-width: 16%;
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }

            &:hover {
                >a {
                    text-decoration: none !important;
                }
            }

            &:nth-child(n+4) {
                .category-image-wrap {
                    right: 100%;
                    left: auto;
                    text-align: right;
                }
            }

            &:not(:nth-child(n+4)) {
                .category-image-wrap {
                    left: 100%;
                }
            }
        }

        li.level1>a {
            display: block;
            text-transform: capitalize;
            font-family: $font_family-secondary;
            margin-bottom: 10px;
            font-size: 16px !important;
            position: relative;

            &:hover {
                text-decoration: none !important;
            }
        }
    }

    .category-image-wrap {
        display: none;
        position: absolute;
        top: 34px;
        left: 100%;
        width: 400px;
        z-index: 9;
        margin: 0 10px;
    }

    .panel.header {

        .links,
        .switcher {
            display: inline-block;
        }
    }
    li.level-top {
        &.parent a.level-top {
            position: relative;
            display: flex;
            align-items: center;

            &:after {
                /*
                content: $parent_icon;
                width: 10px;
                height: 10px;
                background: #000;
                margin-left: 5px;
                */
            }

            &.ui-state-active {
                &:after {}
            }
        }

        a.level-top {
            font-family: $nav_parent-font-family;
            font-size: $nav-lv1-font-size;
        }
    }

    //}
}