//
// Grid Options
//
// To avoid your options getting overridden by an update,
// copy and paste the below options to your own sass file
// and include it before this file.

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.


// BRM - Moved to variables.scss
// $grid-breakpoints: (
//         xs: 576px,
//         sm: 768px,
//         md: 992px,
//         lg: 1200px,
//         xl: 1440px
// ) !default;

$grid-breakpoints: (
        xs: $screen-xs-min,
        sm: $screen-sm-min,
        md: $screen-md-min,
        lg: $screen-lg-min,
        xl: $screen-xl-min
) !default;




// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// $container-max-widths: (
// 		xs: 540px, 
//         sm: 720px,
//         md: 960px,
//         lg: 1180px
// ) !default;

// BRM - Moved to variables.scss
$container-max-widths: (
		xs: $max-widths-xs-min,
        sm: $max-widths-sm-min,
        md: $max-widths-md-min,
        lg: $max-widths-lg-min,
) !default;

/*
$max-widths-xs-min: 540px;
$max-widths-sm-min: 720px;
$max-widths-md-min : 960px;
$max-widths-lg-min : 1180px;
*/

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: $grid-column !default;
$grid-gutter-width: $grid-gutter-width !default;

// END of Grid Options


// Bootstrap file imports
@import "breakpoints/mixins/breakpoints";
@import "grid/bootstrap-grid";