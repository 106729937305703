.blog-main-banner{
    .pagebuilder-slide-wrapper{
        height: 390px;
        min-height: initial !important;
    }
    .pagebuilder-overlay{
        padding: 0;
        @include media-breakpoint-down(sm){
            padding: 0 15px;
        }

        .content {
            position: absolute;
            width: 100%;
            margin: 0 auto;
            text-align: center;
            color: #fff;
            @include media-breakpoint-down(sm){
                width: 90%;
                margin-top: 0px;
            }
        } 
        .post-entry {
            width: 70%;
            margin: 80px auto 0;
        }
        h2{
            @include media-breakpoint-up(sm){
                font-size: 58px;
            }
            margin-bottom: 20px;
            
            a{
                color: #fff;
            }
        }
        .post-excerpt {
            font-size: 16px;
        }
    }
    
}
/* ============================================ *
 * Blog List 
 * ============================================ */
.blog-filters {
    display: flex;
    margin: 22px auto 102px;
    align-items: flex-end;    
    @include make-col-ready();
    padding-left: 11%;
    @include media-breakpoint-down(md) {
        margin: 0;
        overflow-y: auto;
        padding: 16px 13px 10px;
        max-width: 100%;        
    }    
    ul {
        display: flex;
    }
    span {
        color: #b8b8b8;
        line-height: 11px;
        font-size:12px;
        font-family: $font_family-primary-bold;
        white-space: nowrap;
    }
    li {
        margin: 0 16px;
        &:last-child {
            margin: 0 0 0 20px;

        }
    }
    a {
        display: block;
        padding: 0 3px;
        font-size: 18px;
        line-height: 20px;
        position: relative;
        text-transform: lowercase;
        @include media-breakpoint-down(md) {
            white-space: nowrap;
            font-size: 18px;
        }
        &:after {
            content: "";
            background: #dddddd;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 7px;
            z-index: -1;
        }     
    }   
}
.post-list {
    .item {
        overflow: hidden;
        margin: 58px 0;
        @include media-breakpoint-down(md) {
            margin: 40px 0;
        }           
        &:nth-child(even) {
            .post-image {
                order: -1;
                padding-right: 35px;
                @include media-breakpoint-down(md) {
                    padding-right: 15px;
                }
            }
            .post-entry {
                @include media-breakpoint-up(md) {
                    @include make-col(5);
                    @include make-col-offset(1); 
                    padding-left:0; 
                }                 
            }
        }
    }
    .post-categories {
        display: flex;
        li {
            margin-right: 15px;
        }
        a {
            color: #999999;
            text-transform: lowercase;
        }     
    }   
    .post-list-item {
        @include make-row();
        text-align: left;
        @include media-breakpoint-down(md) {
            margin: 0;
        }
    }
    .post-entry {
        @include make-col-ready();
        padding-top: 10px;
        @include media-breakpoint-up(md) {
            @include make-col(6);
            padding: 0 35px 0 11.75%;
        }          
        display: flex;
        flex-direction: column;        
        h2 {
            display: block;
            margin: 8px 0 7px 0;
            font-family: $font_family-secondary;
            color: #000;
            @include font-style(32, 1.41, normal);
            @include media-breakpoint-down(md) {
                font-size: 18px;
                margin: 40px 0 8px;
            }
            a {
                font-family: $font_family-secondary;
                font-weight: normal;
            }
        }        
    }
    .post-excerpt {
        @include font-style(16, 1.88, normal);
        @include media-breakpoint-down(md) {
            font-size: 12px;
        }
    }    
    .post-image {
        @include make-col-ready();
        @include make-col(6); 
        @include media-breakpoint-down(md) {
            @include make-col(12); 
            order: -1;
        }        
        img {
            width: 100%;
        }
    }
    .post-date {
        margin-top: auto;
        color: #999999;
        font-family: $font_family-primary-bold;
        @include media-breakpoint-down(md) {
            position: absolute;
            top: 35px;
        }            
    }

    .post-link {
        margin: auto 0;
        @include media-breakpoint-down(md) {
            margin: 24px 0 0;
            span {
                font-size: 14px;
                border:0;
                border-bottom: 2px solid;
                padding:0;
                width: auto;
            }
        }        
    }    
}
.jscroll-inner {
    .pager {
        display: block;
        clear: both;
        text-align: center;
        position: relative;
        overflow: hidden;
        margin-bottom: 30px;
        @include media-breakpoint-up(md) {
            margin: 24px 0 145px 0;
        }
        @include media-breakpoint-down(sm) {
            margin: 6px 0 111px;
        }
        .toolbar-amount {
            display: none;
        }
        #paging-label {
            display: none;
        }
        .item.pages-item-next {
            position: static;
        }
        li.item {
            .label {
                display: none;
            }               
            &:not(.pages-item-next) {
                display: none;
            }
            .next {
                display: inline-block;
                width: 115px;
                margin: 0 auto;
                font-size: 16px;
                line-height: 36px; 
                border: 2px solid #000;   
                @include media-breakpoint-down(sm) {
                    width: 117px;
                    height: 35px;
                    line-height: 30px;
                }                                            
            }
        }
    }
    svg {
        width: 30px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        background: transparent;
        rect {
            display: none;
        }        
    }
    .postListPager {
        display: inline-block;
        margin: 0 0 30px;
    }    
}
.wordpress-homepage-view  {
    .pagebuilder-slide-wrapper {
        min-height: auto !important;
    }
}
/* ============================================ *
 * Featured post slider 
 * ============================================ */
.featured-post-slider {
    overflow: hidden;
    @include media-breakpoint-down(md) {
        display: none;
    }      
    > li:not(.slick-slide) {
        display: none;
    }
    .pagebuilder-slide-wrapper {
        height: 450px;
        padding: 70px 15% 40px 165px;
        display: flex;
        align-items: center;   
        position: relative;     
    }
    .pagebuilder-collage-content {
        min-height: 340px !important;
        margin-left: 0 !important;
        width: 445px;
        padding: 30px 24px 32px 31px;
    }
    .pagebuilder-slide-button {
        display: inline-block;
        padding: 0 16px;
        height: 40px;
        line-height: 35px;
        border-width: 2px;
        margin-top: 25px;   
    }
    .pagebuilder-overlay {
        opacity: 0;
        padding: 0;
        transition: all ease-in 0.4s !important;
    }  
    .post-date {
        position: absolute;
        right: 10%;
        bottom: 40px;
        width: 166px;
        height: 24px;
        line-height: 24px;
        background-color: #000000;
        color: #fff;
        font-family: $font_family-primary-bold;
        font-size: 10px;
        opacity: 0;
        transition: all ease-in 0.4s;
    }   
    h2 a {
        color: #ffffff;
        @include font-style(30, 1.41, 1px);
        font-family: $font_family-primary;
    }
    .post-categories {
        display: flex;
        margin-bottom: 10px;
        li {
            margin-right: 10px;
        }
        a {
            display: block;
            height: 16px;
            padding: 0 5px;
            background: #fff;
            border-radius: 2px;
            text-transform: lowercase;
            @include font-style(12, 11px, normal);
            @include media-breakpoint-down(md) {
                background:$primary-colour;
                color: $secondary-colour;
            }
        }   
    }      
    .post-excerpt {
        @include font-style(16, 1.88, normal);
    }
    .slick-current {
        .pagebuilder-overlay {
            opacity: 1;
        }
        .post-date {
            opacity: 1;
        }
    } 
    .slick-list {
        width: 75%;
        overflow: visible;
    }  
    .slick-slide {
        padding-right: 30px;
    }
    .slick-prev {
        display: none !important;
    }
    .slick-next {
        right: 25%;
        width: 48px;
        height: 48px;
        line-height: 48px;
    }
    .slick-next:before {
        display: block;
        @include font-icon('\e902');
        line-height: 48px;
        background-color: #ffffff;
        opacity: 1;
        border-radius: 50%;
    }       
}
/* ============================================ *
 * Post view 
 * ============================================ */
.wordpress-post-view {
    .breadcrumbs {
        display: none;
    }
    @include media-breakpoint-down(md){
        figure {
            margin: 0;
        }
    }
}
.post-view {
    @include make-container(); 
    margin: 0 auto;
    .post-content  {
        p {  
            font-size: 16px;
            text-align: left;
            padding: 30px 160px 27px; 
            @include media-breakpoint-down(sm) {
                padding: 30px 0 27px; 
            } 
        }
        img {
            width: initial !important;
            height: initial !important;
            padding: 0;
            margin: 0;
        }
    }
}
.post-image {
   img {
       width: 100%;
       max-height: 540px;
       object-fit: cover;
       @include media-breakpoint-down(md) {
            min-height: 350px;
       }
   }
}
.post-view-banner {
    position:relative;
    .post-categories {
        display: flex;
        margin-bottom: auto;
        align-items: center;
        justify-content: center;
        li {
            margin-right: 10px;
        } 
        a {
            display: block;
            height: 16px;
            padding: 0 5px;
            background: #fff;
            border-radius: 2px;
            @include font-style(10, 14px, normal);
             @include media-breakpoint-down(md) {
                background: $primary-colour;
                color: $secondary-colour;
            }
        }               
    }    
    .banner-content {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: #f5a623;
        width: 540px;
        text-align: center;
        height: 222px;
        margin:  0 auto;
        padding: 32px 38px 25px;
        color: $secondary-colour;
        display: flex;
        flex-direction: column;
        @include media-breakpoint-down(md) {
            width: 93%;
            padding: 24px 7px;
            max-width: 346px;
        }
    }
    .category-links {
        display: flex;
        justify-content: center;
        color: $primary-colour;
        span {
            background: $secondary-colour;
            padding: 6px;
            border-radius: 2px;
            margin: 0 4px;
            @include media-breakpoint-down(md) {
                background: $primary-colour;
                color: $secondary-colour;
                a{
                    color: $secondary-colour;
                }
            }
        }
    }
    .title {
        @include font-style(32, 1.41, 1px);
        text-align: center;
        font-weight: normal;
        display: block;
        margin: auto;
        text-transform: uppercase;
        font-family: $font_family-primary;
        @include media-breakpoint-down(md) {
            font-size: 24px;
            padding: 10px 0 20px;
        }
    }
    .post-info {
        font-family: $font_family-primary-bold;
        margin-top: auto;
        @include font-style(12, normal, normal);
        a {
            text-decoration: underline;
            color: $secondary-colour;
        }
        span {
            padding-left: 10px;
        }
        strong{
            text-decoration: underline;
        }
    }
}
.post-socials {
    display: flex;
    padding: 30px 160px 27px;
    @include media-breakpoint-down(sm) {
        padding: 30px 100px 27px;
        text-align: center;
    }
    li {
        padding-right: 35px;
        @include media-breakpoint-down(sm) {
            padding-right: 24px;
        }
    }
    .icons {
        font-size: 16px;
        @include media-breakpoint-down(md) {
            font-size: 18px;
        }
    }
}
.post-content {
    .post-text , h2 {
        font-family: $font_family-primary;
        @include font-style(16, 1.67, normal);
        color: $primary-colour;
        font-weight: normal;
        text-align: left; 
        padding:0 160px 31px;
        @include media-breakpoint-down(md) {
            padding:0 0 20px;
            font-size: 12px;
        }
        &:first-child {
            font-size: 24px;
        }
    }
    .aligncenter {
        margin: 0 0 44px 0;
        padding: 0 99px;
        @include media-breakpoint-down(md) {
            width: 100%;
            padding: 0;
        }
    }
    .img-post-view {
        width: 100%; 

    }
}
.post-bottom{
    margin:0 160px;
    padding: 24px 0;
    display: flex;
    flex-wrap: wrap;
    border-top: 2px solid #ddd;
    @include media-breakpoint-down(md) {
        margin: 0;
    }
    .post-socials {
        padding: 0;
        @include make-col(6);
        margin: 0 auto;
        justify-content: center;
        li {
            padding: 0 15px;
        }
    }
    .post-link {
        font-family: $font_family-primary-bold;
        font-size: 16px;
        color: $primary-colour;
        display: inline-block;
        line-height: 8px;
        &:after {
            content: "";  
            display: inline-block;
            width: 100%;
            height: 2px;
            background:$primary-colour;
        }
        &.previous-btn {
            text-align: left;
        }
        &.next-btn {    
            text-align: right;
        }
    }
}

/* ============================================ *
 * Related Proucts
 * ============================================ */
.post-related-product {
    ul {
        @include media-breakpoint-up(md) {
            padding: 0 160px;
        }

        li {
            margin: 0 0 30px;
            max-width: unset;
        }
    }

    .product-item-details {
        margin-top: 10px;
        p {
            padding: 0 !important;
        }
    }
}


/* ============================================ *
 * Related Posts
 * ============================================ */
.related-posts-container {
    overflow: hidden;
    margin: 73px 0 30px;
}
.related-posts {
    @include media-breakpoint-up(md) {
        @include make-row();
    }
    > li {
        text-align: center;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;        
        @include make-col-ready();
        @include media-breakpoint-up(md) {
            @include make-col(6);
        } 
        @include media-breakpoint-down(md) {
            padding-bottom: 30px;
        }
    } 
    .post-image {
        width: 100%;
        a {
            display: block;
        }
    }
    .post-info {
        position: absolute;
        top: 40%;
    }
    .post-categories {
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        justify-content: center;
        li {
            margin-right: 10px;
        } 
        a {
            display: block;
            height: 16px;
            padding: 0 5px;
            background: #fff;
            border-radius: 2px;
            line-height: 14px;
            font-size: 10px !important;
        }               
    }
    h2 a {
        display: inline-block;
        width: 60%;
        @include font-style(32, normal, normal);
        color: #fff;
        font-family: $font_family-secondary;
        font-weight: normal;
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }           
}
