/* ============================================ *
 * Global tabs
 * ============================================ */
.detailed {

  .data {
    &.items {
      //@include make-row();
      // position: relative;
      // z-index: 1;
      // border-bottom: 0;
      // margin-left: 0;
      // margin-right: 0;
    }
  }
  //     &:before,
  //     &:after {
  //       content: '';
  //       display: table;
  //     }
  //     &:after {
  //       clear: both;
  //     }
  //     > .item.title:not(.disabled) > .switch:focus,
  //     > .item.title:not(.disabled) > .switch:hover {
  //       background: #fff;
  //     }
  //     > .item.title:not(.disabled) > .switch:active,
  //     > .item.title.active > .switch,
  //     > .item.title.active > .switch:focus,
  //     > .item.title.active > .switch:hover {
  //       background: #fff;

  //     }            
  //     > .item.title {
  //       display: inline-block;
  //       margin: 0 auto;
  //       width: 100%;

  //       @media (min-width: $screen-sm-min) {
  //         width: auto;
  //       }

  //       a:after {
  //         display: none;
  //       }
  //       // title
  //       > .switch {
  //         height: 40px;
  //         display: block;
  //         position: relative;
  //         z-index: 2;
  //         font-size: $font-lg;
  //         line-height: 40px;
  //         color: $font-colour-default;
  //         text-decoration: none;
  //         background: $border-light-colour;
  //         border: $border-width $border-type $border-light-colour;
  //         border-bottom: none;
  //         padding: 0 20px;

  //         &:visited {
  //           //color: $font-colour-default;
  //           //background: #fff;
  //         }
  //         &:hover {
  //           color: $font-colour-default;
  //           background: #fff;

  //         }
  //         .active,
  //         &:active {
  //           > .switch {
  //             color: $font-colour-default;
  //             background: #fff;
  //           }

  //         }
  //       }
  //     }
  //     // tab content
  //     > .item.content {

  //       width: 100%;
  //       background: #ffffff;
  //       padding: 40px 0;
  //       border-top: $border-width $border-type $border-light-colour;
  //       @media (min-width: $screen-sm-min) {
  //         margin-top: 38px;
  //         margin-left: -100%;
  //         float: right;
  //       }

  //       &:before,
  //       &:after {
  //         content: '';
  //         display: table;
  //       }
  //       &:after {
  //         clear: both;
  //       }
  //       .active {
  //         display: block;
  //       }
  //     }

  //   }
  // }

}





