// Variables 

$list_item-columns-lg: 4;
$list_item-columns-md: 3;
$list_item-columns-sm: 8;



/* ============================================ *
 * Category Top
 * ============================================ */

.category-view {
	text-align: left;
	display: none;
	@include media-breakpoint-up(md) {
		padding-top: 30px;
		
	}
}
.amasty_xlanding-page-view{
	.category-view{
		display: block;
	}
}
.category-description {
	[data-content-type='row'][data-appearance='contained'] {
		padding: 0;
	}

	@include media-breakpoint-down(md) {
		padding: 20px;
	}
}

/* ============================================ *
 * Category Grid
 * ============================================ */
.products-grid {}

.apply-button {
	display: none;
}

.product-items {
	@include make-row;

	@include media-breakpoint-down(md) {
		margin: 40px 0 0;
		justify-content: center;
	}
	li.product-item {
		padding-bottom: 10px;
	}
}

li.product-item {
	@include make-col-ready;
	position: relative;
	// margin: 30px auto ;
	text-align: center;
	outline: none;
	@include make-col(12);

	@include media-breakpoint-down(md) {
		display: flex;
		flex-direction: column;
		padding: 0 20px 20px;
		justify-content: center;
		align-items: center;
		.product-item-info {
			position: relative;
		}
	}
	@include media-breakpoint-up(sm) {
		@include make-col(4);
	}

	@include media-breakpoint-up(md) {
		@include make-col($list_item-columns-lg);
	}

	@include media-breakpoint-between(sm, md) {
		margin: 50px auto;
	}

	text-align: center;

	.product-item-details {
	    display: flex;
	    flex-direction: column;
	    height: 100%;
	    @include media-breakpoint-down(md) {
		    align-items: center;
		    height: auto;
			.price-box {
			    display: flex;
			    justify-content: center;
			}
			.actions-primary form {
			    justify-content: center;
			}			
		}

	    .product-item-inner {
	    	display: flex;
    		margin-top: auto;
	    }
	}
}

.product-item-photo {
	.product-image-container {
		display: inline-block;
		max-width: 100%;
	}

	.product-image-wrapper {
		display: block;
		height: 0;
		overflow: hidden;
		position: relative;
		z-index: 1;
	}

	.product-image-photo {
		bottom: 0;
		display: block;
		height: auto;
		left: 0;
		margin: auto;
		max-width: 100%;
		position: absolute;
		right: 0;
		top: 0;
	}

	.product-item-name {
		-moz-hyphens: auto;
		-ms-hyphens: auto;
		-webkit-hyphens: auto;
		display: block;
		hyphens: auto;
		margin: 5px 0;
		font-weight: normal;
		word-wrap: break-word;
	}
}
.catalog-product-view,.catalog-category-view ,.catalogsearch-result-index{
	.price-box .price-wrapper:after {
		content: "each";
		font-size: 14px;
		margin-left: 2px;
	}
}
.minimal-price-link {
    display: flex;
    align-items: flex-start;
    text-align: left;
    font-size: 14px;
	font-family: $font_family-primary-bold;
	margin-right: 15px;
	flex-direction: column;
	justify-content: space-between;

	._title {
		font-size: 12px;
		font-weight: normal;
		vertical-align: top;
		margin-right: 5px;
		font-family:  $font_family-primary;
	}
	+ .price-container, + .special-price {
		.price {
			font-size: 14px !important;
			font-weight: normal !important;
			line-height: 14px !important;
			vertical-align: bottom;
		}
	}
}
.product-item-details {
	.price-box {
		margin: 15px 0;
		text-align: left;
		font-family: $font_family-primary-bold;

		.price {
			white-space: nowrap;
		}

	}
}

.actions-primary {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;

	.stock {
		@include visuallyhidden;
	}
	.alert{
		button{
			&:hover {
				a {
					color: #fff;
				}
			}
			span{
				padding: 0 10px;
			}
			a{
				&:hover{
					color: #fff;
				}
			}
		}
	}

	form {
		display: flex;
		align-items: center;

		.tocart {
			padding-left: 16px;

			@include media-breakpoint-down(sm) {
				padding-left: 25px;

				span {
					width: 135px;
					padding: 0;
					height: 35px;
					line-height: 30px;
				}
			}

			@include media-breakpoint-between(sm, md) {
				padding-left: 40px;

				span {
					width: 100px !important;
				}
			}

			span {
				text-align: center;
				margin: 0 auto;
				width: 122px;
				padding: 0;

				@include media-breakpoint-between(sm, md) {
					width: 110px;
					height: 35px;
					line-height: 30px;
				}
			}
		}

		.product-price {
			display: block;
			overflow: hidden;
			width: 100%;
			&.only-one .price-label {
			    display: none;
			}
		}
	}

	.qty-block {
		width: 24%;
		display: flex;
		align-items: center;

		span {
			font-size: 14px;
			vertical-align: middle;
			padding-right: 2px;
		}

		.qty {
			width: 30px;
			height: 30px;
			line-height: 30px;
			border: 1px solid #d2d2d2;
			font-size: 12px;
			padding: 0;
			font-family: $font_family-primary-bold;
		}
	}
}

.towishlist {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 88;

	@include media-breakpoint-down(md) {}

	svg {
		// max-width: 17px;
		// height: 17px;
		// overflow: visible !important;
		// position: absolute;
		// top: 10px;
		// right: 10px;
		display: none;
	}

	span {
		font-size: 0
	}

	&:after {
		@include font-icon('\e92c');
		font-size: 18px;
	}

	&:hover {
		&:after {
			@include font-icon('\e92a');
		}
	}

	&.added {
		&:after {
			@include font-icon('\e92a');
		}
	}
}

.product-att {
	text-align: left;
	font-size: 14px;
	display: block;
	padding-bottom: 8px;
}

////////catalog-banner/////////
.catalog-category-view {
	.breadcrumbs {
		padding: 30px 0 20px;
		margin: 0;
	}

	.content-banner {
		@include media-breakpoint-down(sm) {
			margin: 76px 14px 0 !important;
		}
	}

	.selectric-scroll li {
		color: #000 !important;
		padding-left: 16px;
	}

	.selectric {
		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}

	@include media-breakpoint-down(md) {
		.column {
			padding: 0;
		}

		.breadcrumbs {
			position: unset;
			margin-top: 0;
			padding: 0 14px;
			max-width: 100%;
			right: 0;
			left: 0;
			top: 20px;

			a,
			strong {
				color: #000;
			}
		}
	}

	.pagebuilder-column-group {
		@include media-breakpoint-down(md) {
			margin: 0;
		}
	}

	.product-item-details {
		.price-box {
			width: 100%;
		}
	}

	.actions-primary {
		form {
			flex-wrap: wrap;
		}
	}

	.product-att {
		padding-bottom: 12px;
		text-transform: capitalize;

		p {
			font-size: 14px;
		}
	}

	.product-layer-wrapper {
		@include media-breakpoint-down(md) {
			margin-top: 24px;
		}
	}

	.modal-popup .modal-header {
		justify-content: safe flex-end;
		padding: 20px 10px;
		margin: 0;
		.modal-title{
			text-align: center;
			margin:0 auto;
		}
	}
	button.cancel-quote-request.action{
		margin-left: 0 !important;
	}
	div#negotiable-quote-popup{
		label{
			text-align: left;
		}
	}
}

.catalog-banner {
	min-height: 300px;

	@include media-breakpoint-down(md) {
		min-height: 250px;
		padding: 0 10px !important;
	}
}

.content-banner {
	margin: 50px 80px;
	min-width: 443px !important;
	min-height: 188px;
	padding: 18px 25px;
	background: #ffb02f;
	color: $secondary-colour;
	font-family: $font_family-primary;
	font-weight: normal;

	@include media-breakpoint-down(sm) {
		margin: 76px 30px 0 !important;
		min-width: initial;
	}

	h1 {
		font-size: 32px;
		padding-bottom: 10px;
		text-transform: uppercase;
		font-family: $font_family-primary;
		font-weight: normal;

		@include media-breakpoint-down(md) {
			span {
				font-size: 24px !important;
			}
		}
	}

	p {
		font-size: 16px;
		line-height: 30px;

		@include media-breakpoint-down(md) {
			span {
				font-size: 12px !important;
			}
		}
	}
}

///////////////////////////////
///////////////////////////////
.swatch-input {
	left: -1000px;
	position: absolute;
	visibility: hidden;
}


/* product list image */
.product-item .product-photo {}

.products-grid .product-item .product-image-wrapper {
	display: block;
	height: 0;
	overflow: hidden;
	position: relative;
	z-index: 1;
}

.products-grid .product-item .product-image-container {
	display: inline-block;
	max-width: 100%;
}

.products-grid .product-item img.product-image-photo {
	bottom: 0;
	display: block;
	height: auto;
	left: 0;
	margin: auto;
	max-width: 100%;
	position: absolute;
	right: 0;
	top: 0;

}

/*************** swatch*******************/
.swatch-attribute.color {
	display: flex;

	&:before {
		content: "Colour:";
		padding-right: 13px;
		display: inline-block;
		color: #000;
		margin-top: 10px;
		font-size: 14px;
		;
	}
}

.fotorama__nav-wrap.fotorama__nav-wrap--horizontal {
	@include media-breakpoint-down(sm) {
		display: none !important;
	}
}
.catalog-category-view{
	.special-price{
		.tooltip{
			display: none;
		}
	}
}

/****************search list************/
.catalogsearch-result-index {
	.actions-primary form {
		flex-wrap: wrap;
	}

	.price-box {
		width: 100%;
	}
	.special-price{
		.tooltip{
			display: none;
		}
	}
}
