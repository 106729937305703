/**************************************
** 01- About us 
** 02- Faq
** 03-ShowRooms
***************************************/
.cms-page-view{
	#maincontent{
		@include media-breakpoint-up(md) {
			margin-top: 60px;
		}
	}
	&.cms-our-showrooms ,&.cms-about-us {
		#maincontent{
			padding-top: 0;
		}
	}
}
/*======================================
** 01- About us 
=======================================*/

.cms-about-us {
	.breadcrumbs {
		display: none;
	}

	.pagebuilder-collage-content {
		background: #a40014;
		min-height: 453px;
		width: 445px;
		position: static;
		padding: 23px 33px 33px 31px;

		@include media-breakpoint-down(md) {
			min-height: 271px;
			width: 345px;
		}

		h3 span {
			font-size: 36px !important;
			letter-spacing: 1px;
			line-height: 1.25;
			text-transform: uppercase;

			@include media-breakpoint-down(md) {
				font-size: 24px !important;
			}
		}

		p {
			border: 0 !important;

			@include media-breakpoint-down(md) {
				display: none !important;
			}
		}
	}

	.single p {
		@include media-breakpoint-down(md) {
			border-bottom: 0 !important;
			margin: 0 14px !important;
		}
	}

	.empty {
		@include media-breakpoint-down(sm) {
			display: none !important;
		}
	}

	.bg-color {
		@include media-breakpoint-down(sm) {
			padding: 24px 0 !important;
			min-height: 112px !important;
			width: 100% !important;
			margin: 0 29px 0 !important;

			&.green {
				height: 144px;
			}

			@include media-breakpoint-between(md, lg) {
				width: 100% !important;
			}
		}
	}

	.pagebuilder-column-group {
		@include media-breakpoint-down(md) {
			position: static;
			margin: 0;
		}
	}

	[data-content-type='row'][data-appearance='contained'] {
		max-width: 1109px;
	}

	[data-content-type='row'][data-appearance='contained'] [data-element='inner'] {
		@include media-breakpoint-down(md) {
			margin: 0 0 29px !important;
		}
	}

	.pagebuilder-collage-content {
		h2 {
			font-size: 36px;

			@include media-breakpoint-down(md) {
				font-size: 24px;
			}
		}
	}

	[data-content-type='row'][data-appearance='full-bleed'] {
		@include media-breakpoint-down(md) {
			margin-bottom: 39px !important;
		}
	}

	.pagebuilder-slide-wrapper {
		padding: 123px 0 29px 165px;

		@include media-breakpoint-down(md) {
			min-height: auto !important;
		}
	}

	[data-content-type='slide'] .pagebuilder-slide-wrapper .pagebuilder-overlay {
		@include media-breakpoint-between(sm, md) {
			padding: 33% 98px 16px;
		}
	}

	.pagebuilder-overlay {
		@include media-breakpoint-down(md) {
			padding: 33% 15px 25px !important;
		}
	}

	div[data-content-type='slider'] .slick-dots {
		padding-left: 30px;
		bottom: 55px;
		left: 162px !important;

		@include media-breakpoint-between(xs, sm) {
			left: 42px !important;
			top: 380px !important;
		}

		@include media-breakpoint-down(xs) {
			left: 50px !important;
		}

	}

	[data-content-type='row'][data-appearance='full-width']>.row-full-width-inner {
		position: relative;

		@include media-breakpoint-down(md) {
			position: static;
		}
	}

	[data-content-type='row'][data-appearance='full-bleed'] {
		@include media-breakpoint-down(md) {
			height: 466px;
		}

		@include media-breakpoint-down(sm) {
			height: 387px;
		}

		@include media-breakpoint-between(md, lg) {
			height: 544px !important;
		}

		@include media-breakpoint-down(lg) {
			height: auto !important;
		}
	}

	.page-title-wrapper {
		display: none;
	}

	.pagebuilder-overlay {
		@include media-breakpoint-down(md) {
			padding-top: 20% !important;
		}
	}

	.pagebuilder-button-primary {
		cursor: pointer;
		font-size: 16px;

		span {
			font-size: 16px;
			border-bottom: 2px solid $secondary-colour;
			display: inline-block;
			clear: both;
			line-height: 15px;
			margin: 0;
			color: #fff;

			@include media-breakpoint-down(md) {
				margin: 0;
			}
		}
	}

	.scrollToTop-about {

		span {
			font-family: $font_family-secondary;
			border-bottom: 2px solid $primary-colour;
			cursor: pointer;

			@include media-breakpoint-down(md) {
				margin: 20px 0px;
				font-family: $font_family-primary;
			}
		}
	}

	.image-container {
		@include media-breakpoint-down(md) {
			padding: 0 !important;
		}
	}

	.banner-img {
		@include media-breakpoint-down(md) {
			padding: 0 !important;
		}
	}

	.skip-link {
		color: $secondary-colour;
		cursor: pointer;
		width: auto;
		position: absolute;
		bottom: 0;
		right: 0;
		@include font-style(14, normal, normal);
		text-align: right;

		@include media-breakpoint-down(md) {
			text-align: center !important;
			left: 0;
			top: 232px;
		}
	}

	.banner-bg {
		@include media-breakpoint-down(md) {
			width: 100% !important;
			height: 300px;
		}

		@include media-breakpoint-down(sm) {
			padding: 0 !important;
			width: 100% !important;
			height: 300px;
			display: flex !important;
			text-align: center;
			position: relative;
		}
	}

	.about-text {
		@include media-breakpoint-down(md) {
			padding: 24px 15px 0 !important;
		}

		p {
			line-height: 2.14;
			padding: 0 15px;
			margin-bottom: 0 !important;

			@include media-breakpoint-down(sm) {
				&.last {
					border-bottom: 0 !important;
				}
			}
		}

		.pagebuilder-column {
			padding: 0 !important;
		}
	}

	.end-graphy p {
		border-bottom: 0 !important;
	}

	.about-buttons {
		@include media-breakpoint-down(md) {
			position: static;
		}

		@include media-breakpoint-down(sm) {
			text-align: center;
			position: absolute;
			bottom: 25px;
			left: 0;
			right: 0;
		}
	}

	.column {
		&.main {
			.cms-content-important {
				font-size: $font-lg;
				padding: $padding*2;
				margin: 0;
				margin-bottom: $margin;
			}

			p {
				text-align: left;
				margin-bottom: $margin;
				@include font-style(14, 1.88, normal);

				@include media-breakpoint-down(sm) {
					border-bottom: 1px solid #eee;
					display: block;
					padding: 16px 0 16px !important;
					text-align: left;
					margin: 0;
				}

				@include media-breakpoint-between(md, lg) {
					padding: 0 15px !important;
				}
			}

			.banner-text {
				@include font-style(36, 1.25, normal);
				color: $secondary-colour;
				text-align: center;
				font-family: $font_family-secondary;
				padding: 0;
				margin: 0;

				@include media-breakpoint-down(md) {
					font-size: 24px;
				}

				h3 {
					font-family: $font_family-secondary;
					font-weight: normal;

					@include media-breakpoint-down(md) {
						span {
							font-size: 24px !important;
						}
					}
				}
			}

			.text-field {
				line-height: 2.14;

				>p {
					border: 0;

					@include media-breakpoint-down(md) {
						border-bottom: 2px solid #cccccc;
						margin: 0;
						padding: 0 0 15px;
						;
					}
				}
			}

			.left-text {
				text-align: left;
				@include font-style(14, 2.14, normal);

				@include media-breakpoint-down(md) {
					text-align: left;
					display: block;
					margin: 0;
					padding: 0;
				}
			}

			li {
				text-align: left;
				padding-left: $padding;

				&:before {
					padding-right: $padding/2;
				}
			}
		}
	}

	.slick-dots {
		@include media-breakpoint-down(md) {
			top: 81% !important;
			left: 18px;
			bottom: 0;
			padding-left: 0 !important;
		}

		@include media-breakpoint-down(sm) {
			top: 76% !important;
		}
	}

	.pagebuilder-slide-wrapper .pagebuilder-slide-button {
		@include media-breakpoint-down(md) {
			margin: 40px 0 15px !important;
		}
	}
}

/*********** search results *******************/
.catalogsearch-result-index .results {
	.block {
		background: #f3f3f3;
		display: flex;
		padding: 10px;
	}

	dt.title {
		font-size: 18px;
		padding-right: 20px;
	}

	dd.item {
		line-height: 28px;
		margin: 0 5px;
		padding: 0 4px;
		background: #000;

		a {
			color: #fff;
		}
	}
}

/**************cms footer links *****************/
p.Body {
	text-align: left;
	padding: 15px 0;
	font-size: 18px;
}

.cms-page-view {

	p,
	.about-text {
		text-align: left;
		padding: 15px 0;
		font-size: 18px;
	}

	h4 {
		text-align: left;
		padding: 15px 0;
		font-size: 22px
	}
}

/*======================================
** 02-Faq
=======================================*/
.cms-faq {
	ul{
		[data-content-type="faq-item"] {
			&:last-child{
				border-bottom: none !important;
			}
		}
	}
	[data-content-type='row'][data-appearance='contained'] {
		margin-bottom: 10px;
	}
	.main-title{
		position: relative;
		&:before{
			content: "";
			width: 80px;
			position: absolute;
			bottom: 0px;
			border-top: 3px solid #000 !important;
		}
	}
	[data-element="answer"]{
		padding: 15px 0 !important;
		p,span,div{
			font-size:18px !important;
			padding: 0;
		}
	}
	[data-content-type="faq-item"] {
		padding-left: 0 !important;
		padding-top: 10px !important;
		padding-bottom: 10px !important;
		border-bottom: 1px solid #777 !important;

		h3 {
			padding-left: 0 !important;
			position: relative;
            &:focus{
				outline: none;
			}
			&:after {
				content: "+";
				position: absolute;
				right: 15px;
				@include font-size(22);
				cursor: pointer;
				@include media-breakpoint-down(sm){
					right: 5px;
				}
			}

			&[aria-expanded="true"]{
				&:after {
					content: "--";
				}
			}
		}

		div {
			padding-left: 0 !important;
		}
	}

	.category-title {
		padding-bottom: 10px;
		margin-bottom: 20px;
		position: relative;
		&:before{
			content: "";
			width: 100px;
			position: absolute;
			bottom: 0;
			border-bottom: 3px solid #000 !important;
		}
	}
}

/*======================================
** 03- Quick order
=======================================*/

.quickorder-index-index {
    .fieldset > .fields > .field.required > .label:after{
        margin: 0;
    }
    ul#ui-id-2{
        
        padding-top: 15px;
        li{
            background: #fff !important;
        }
    }
}
/*======================================
** 03- SHOWROOMS
=======================================*/
.cms-page-view{
    .main-btn{
        display: block;
        //width: 109px;

        .button-black {
            //width: 100%;
            padding: 0 20px;
            border: 2px solid #000;
            line-height: 40px;
            span {
                padding:0;
                font-family: $font_family-primary-bold;
                text-align: center;
                font-size: 16px;         
            }
            &:hover {
                background:#000;
                span {
                    color: #fff;
                }
            }
        }
        .button-white {
            //width: 100%;
            padding: 0 20px;
            border: 2px solid #fff;
            line-height: 40px;
            span {
                padding:0;
                font-family: $font_family-primary-bold;
                text-align: center;
                font-size: 16px;  
                color: #fff;       
            }
            &:hover {
                background:#fff;
                span {
                    color: #000;
                }
            }
        }
    }
}
.cms-our-showrooms {
	.pagebuilder-button-primary {
		padding: 0 20px;
		border: 2px solid #000;
		line-height: 40px;
		display: block;
		 font-family: $font_family-primary-bold;
		&:hover {
			background: #000;
			color: #fff;
			a {
				color: #fff;
			}
		}
	}
}


.cms-interactive_catalogues{
	.block-static-block{
		h1,a{
			padding: 20px;
			color: #fff;
		}
	}
	@include media-breakpoint-down(xs){
		h1{
			font-size: 22px;
		}
		strong{
			font-size: 14px;
		}
	}
}