/* ============================================ *
 * Minicart
 * ============================================ */
.block-minicart {
	height: 100%;
	.block-content {
		display: flex;
		height: 100%;
		flex-direction: column;
		padding: 16px;
		min-height: 400px;
	}
}

#minicart-content-wrapper {
	height: 100%;
}
// Mini Cart Header
.minicaert-head {
	padding-bottom: 25px;
}
.minicart-title {
	position: relative;
	text-align: left;
	display: flex ;
	flex-direction: row;
	color: $primary-colour;
	font-family: $font_family-primary;
	@include font-style(16, normal, normal);
  	font-weight: bold;
	span {
		line-height: 1;
	}
	.text {

		padding-right: 30px;
		position: relative;
		display: block !important;
	}
	.qty {
		padding-right: 5px;
	}
	.item-label {

	}
	.close-button {
		position: absolute;
		background: none;
		top: 0;
    	bottom: 0;
	    margin: auto;
	    right: 0;
	    outline: none;
	    font-size: 10px;
        &:before {
             @include font-icon('\e90b');
        }
		span {
			background: none;
			padding: 0;
			line-height: 1;
			color: $font-colour-default;
			font-size: 12px;
			display: none;
			&:hover {
				background: none;
			}
			&:before {
				width: 15px;
				height: 15px;
				//content: "";
				//background: #000;
				display: inline-block;
				margin-right: 5px;
				vertical-align: text-bottom;
			}
		}
	}
}
// Mini Cart Content
.minicart-content {
    max-width: 300px;
    overflow: auto;
    @include media-breakpoint-down(sm) {
    	padding: 0 10px;
    }
	.minicart-items-wrapper {
		height: auto !important; // fix mbile issue
	}
	ol.minicart-items {
		display: flex;
		flex-direction: column;
	}
	li.product-item {
		margin: 0;
    	padding: 0;
    	border-bottom: 1px solid #eee;
    	@include make-col(12);
		> div.product {
			display: flex;
			flex-direction: row;
			padding: 5px 0;
			@include make-col(12);
		}
		&:last-child {
			> div.product {
				border-bottom: 0 none;
			}
		}
	}
	.product-image-container{
	    max-width: 44px;
	}
	.product-image-photo {
		position: static;
    	height: initial !important;
	}
	.product-item-photo {
		//width: 26%;
		max-width: 44px;
		margin-right: 10px;
	}
	.product-item-details {
		text-align: left;
		position: relative;
		flex-grow: 1;
		.product-item-name {
			display: block;
			font-size: 12px;
			margin-bottom: 0px;
			font-family: $font_family-primary-bold;
  			clear: both;
  			overflow: hidden;
  			&.subtitle {
				font-family: $font_family-primary;
  			}
		}
		.options.active {
			display: flex;
			align-items: center;
		}
		.options.list {
			color: $primary-colour;
			display: flex;
			justify-content: flex-start;
		}
		.options-content {
			position: relative;
    		position: relative;
    		display: flex;
    		align-items: center;
    		margin-left: 20px;
			.label {
			    color: #000;
			    margin-right: 5px;
			    font-size: 10px;
			}
		}
		.product-color {
    		width: 12px;
    		height: 12px;
    		display: block;
		}
		dd.values {
			padding-right: 20px;
		}
		dt.label {
    		padding-right: 8px;
    		position: relative;
    		display: none;
		}
		.product-item-pricing {
			display: flex;
			flex-direction: column;
			color: $primary-colour;
			.price-container {
				order: 2;
				margin-top: 0;
			}

		}
		.price {
			font-size: 12px;
			font-weight: bold;
			display: block;
		}

		.details-qty {
			.label {
				font-size: 10px;
				color: $primary-colour;
			}
			.item-qty {
				border: 0;
				font-weight: bold;
				width: 20px;
				height: 20px;
				padding: 0 2px;
				color: #000;
			}
			.update-cart-item {
				padding-left: 5px;
				span {
					background: none;
					padding: 0;
					color: #000;
					text-decoration: underline;
					font-size: 12px;
					line-height: inherit;
					&:hover {
						background: none;
					}
				}
			}
		}
	}

	.actions {
		> div {

		}
		a {
			font-size: 0;
    		position: relative;
    		display: block;
    		margin-bottom: 10px;
    		&.edit {
    			&:before {
				    content: "";
				    background: url(../images/Edit.svg) no-repeat;
				    background-size: 40px 40px;
				    background-position: -10px -9px;
				    width: 20px;
				    height: 20px;
				    display: inline-block;
				    vertical-align: middle;
				    font-size: 14px;
				    color: #000;
    			}
    		}
    		&.delete {
    			&:before {
				    content: "";
				    background: url(../images/delete.svg) no-repeat;
				    background-size: 11px;
				    background-position: center;
				    height: 20px;
				    width: 20px;
				    display: inline-block;
				    vertical-align: middle;
				    font-size: 14px;
				    color: #000;
    			}
    		}
		}
	}

}
// Mini Cart bottom
.bottom-minicart {
	margin-top: auto;
	display: flex;
	color: $primary-colour;
	padding: 20px 0;
	min-height: 140px;
	flex-wrap: wrap;
	@include media-breakpoint-down(sm) {
		margin-bottom: 20px;
		height: 47px;
		padding: 0;
	}
	.subtotal {
	    text-align: left;
	    padding: 15px 0 10px;
	    display: flex;
	    align-items: center;
	    justify-content: space-between;
	    width: 100%;
		.label {
			display: inline-block;
			font-weight: bold;
			font-size: 12px;
			color: #b8b8b8;
			margin-right: 5px;
		}
		.amount {
			display: inline-block;
    		padding-left: 6px;
    		font-size: 16px;
    		font-weight: bold;
    		.price {
    			display: block;
    		}
		}
	}

	.actions {
		display: flex;
    	justify-content: center;
		width: 100%;
    	text-align: center;
		.keep-shopping {
			color: #fff;
			background:$primary-colour;
			a {
				color: #fff;
			}
		}
		.checkout {
			line-height: 52px;
			display: block;
			width: 100%;
			color: #fff;
			background: $button-background;

		}

		.secondary {
			margin-top: auto;
		}

		.viewcart {
			display: block;
		    padding: 10px 0;
		    text-decoration: underline;
		    font-size: 16px;
		}
	}
}
.checkout-btn button {
	width: 100%;
	span {
		font-family: $font_family-primary-bold;
	}
}
.extra {
    display: flex;
    justify-content: space-between;
    width: 100%;
    > div {
        width: 100%;
    }
	span.price-wrapper {
    	font-size: 12px;
    	background: #e6e6e6;
    	display: block;
    	text-align: center;
    	padding: 5px 10px;
	}
}
// Mini cart ajax
.ajax-loading {
	.block-minicart {
		.block-content {
			position: relative;
			&:after {
				// ajax-loader.gif

				background: url('../images/ajax-loader.gif') 50% 50% no-repeat #000;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				opacity: 0.1;
				content: ""''
			}
		}
	}
}



/***************/
/* ============================================ *
 * Cart empty
 * ============================================ */
.cart-empty {
    padding: 50px 0;
    margin: auto;
    text-align: center;
    h3{
    	font-size: 48px;
  		padding-bottom:14px;
    }
    p {
    	font-size: 21px;
  		padding-bottom:40px;
  		color: $primary-colour;
    }
	a {
	    padding:0 40px;
        height: 48px;
        line-height: 48px;
        margin:0;
        background:$primary-colour;
        color: $secondary-colour;
        cursor:pointer;
        font-family: $font_family-primary-bold;
        font-size: $font-xs;
        text-transform: uppercase;
        display: inline-block;
	}
}

/* ============================================ *
 * Cart Table
 * ============================================ */
.cart-container {
	@include make-container();
	padding-bottom: 40px;
	@media(max-width: $screen-sm-min) {
		padding-top: 0;
	}
	.form-cart {

		@include media-breakpoint-down(sm) {
		 	padding: 0 15px;
		}
	}

	.page-title-wrapper {
	    position: relative;
	    padding-top: 50px;
	    padding-bottom: 40px;
	    text-align: left;
	    @include make-row();
	    @include media-breakpoint-down(sm) {
		   	padding: 30px 0;
		   	margin: 0 auto;
		}

	}
    .left-col {
        @include media-breakpoint-up(sm) {
            @include make-col(6);
            @include make-col-offset(3);
        }
        .page-title {
            font-size: 48px;
            font-weight: 900;
            margin: 0 auto 15px;
            color: $primary-colour;
            text-align: center;
            text-transform: capitalize;
            @include media-breakpoint-down(sm) {
                font-size: 18px;
                padding: 0;
                margin:  0 auto;
            }
        }
        p {
            font-size: 21px;
            font-weight: 500;
            text-align: center;
            line-height: 25px;
            color: #9b9b9b;
        }
   }
   .right-col {
        text-align: right;
        @include make-col-ready();
        @include media-breakpoint-up(sm) {
            @include make-col(3);
            margin: 0 auto;
            padding: 80px 0 0;
        }
        @include media-breakpoint-down(sm) {
            display: none;
        }
        .checkout-link {
            float: right;
            padding:0px 40px;
            height: 48px;
            line-height: 48px;
            margin:0;
            background:$primary-colour;
            color: $secondary-colour;
            cursor:pointer;
            font-family: $font_family-primary-bold;
            font-size: 14px;
            text-transform: uppercase;
            @include media-breakpoint-down(sm) {
                position: static;
                float: none;
                margin: 0 auto;
                max-width: 100%;
            }
            @include media-breakpoint-between(md,lg) {
                padding:0px 35px;
			}
        }
        img {
            display: inline-block;
            vertical-align: bottom;
            margin-right: 30px;
        }
    }
	.delivery-msg {
	    display: block;
	    padding: 0 25px;
	    margin-bottom: 40px;
	    height: 58px;
	    line-height: 58px;
	    background: #eee;
	    text-align: left;
	    letter-spacing: 0.1px;
	    position: relative;
	    overflow: hidden;
	    transition:(all .5s ease);
		.close {
		    position: absolute;
		    right: 25px;
		    top: 0;
		    height: 58px;
		    line-height: 62px;
		    &:after {
		    	@include font-icon('\e92d');
		    }
		}
	    @include media-breakpoint-down(sm) {
		   	display: none;
		}
	}

}
.cart.item {
	tr.item-info{
		border-bottom:1px solid #ccc;
        @include media-breakpoint-down(sm) {
            padding: $padding 0;
            display: table-cell;
        }
	}
	.product-item-photo .product-image-photo {
		bottom: unset;
	}
    .control.qty {
        text-align: center;
        display: flex;
    	justify-content: center;
    	align-items: center;
        @include media-breakpoint-down(sm) {
            display: flex;
            flex-direction: column;
            align-items: baseline;
        }
        .update-cart-item span {
    		padding: 0;
    		text-align: center;
    		width: 72px;
    		font-size: 14px;
    		@include media-breakpoint-down(sm) {
    			width: 100%;
    		}
		}
    }
    .quantity-wrap {
        margin-bottom: 10px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        .plus{
         border-radius: 0 6px 6px 0;
        }
        .minus {
             border-radius: 6px 0 0 6px;
        }
        input {
            width:60px;
        }
    }
    .qty-controler {
        background-color: #f5f5f5;
        width: 34px;
    }
	.actions-toolbar {
	    display: flex;
	    flex-direction: row-reverse;
	    justify-content: space-between;
	    @include media-breakpoint-down(sm) {
	    	flex-direction: column;
			align-items: center;
	    }
	}
}
.cart.table {
	th {
		vertical-align: middle;
	    text-align: left;
	    border-color: #ccc;
	    font-size: 21px;
  		font-weight: 500;
	    text-transform: capitalize;
	    color: $primary-colour;
		&.image {
			@include make-col(2);
		}
		&.item {
		    @include make-col(3);
		}
		&.qty{
			@include make-col(2);
			 text-align: center;
		}
		&.price{
			@include make-col(2);
			padding-left:20px;
		}
		&:not(:first-child) {
		    text-align: center;
		    padding-left: 0;
		}
		&.subtotal{
			position: relative;
			span{
				margin-right: 5px;
			}
			&:after{
				content:"Excl. Tax";
				@include font-size(12);
				position: absolute;
				bottom: 14px;
			}
		}
		@media(max-width: $screen-sm-min) {
			display: none;
		}

	}
	td {
		padding: 20px 0;
		text-align: left;
		border-bottom: 1px solid #ccc;
		@media(max-width: $screen-sm-min) {
			display: block;
			border: 0 none;
		}

		&:not(:first-child) {
		    text-align: center;
		}
        &.item{
            @include media-breakpoint-down(sm) {
                padding-right: 10px !important;
            }
			.product-item-photo {
			    float: left;
			}
        }
        &.subtotal{
            @include media-breakpoint-down(sm) {
                padding-left: 20px;
                margin-left: auto;
                width: 60%;
                &:before {
                	content: "Subtotal";
                	display: inline-block;
                	font-size: 16px;
                	padding-right: 5px;
                }
            }
            .cart-price {
    			width: 100%;
				display: block;
				font-variant-numeric: tabular-nums;
    			text-align: center;
			}
        }
		&.product-details{
			padding:20px 0;
			@include media-breakpoint-down(sm) {
				padding:35px 20px;
				display: flex;
			}
			.product-item-details{
				width: 80%;
				float: left;
				@include media-breakpoint-down(sm) {
					width: 70%;
				}
				strong{
					a{
						font-size: $font-lg;
  						color: $primary-colour;
  						padding: 0 10px 0 0;
					}
				}
				dl.item-options{
					.option-container{
						display: flex;
						align-items:flex-start;
						font-size: 14px;
    					font-weight: normal;
    					color: $primary-colour;
    					padding: 2px 0;
    					dd{
    						padding-left:2px;
    					}
					}
					a{
						display: block;
					}
				}
			}
			.item-actions{
				width: 20%;
				float: right;
				margin-top: 6px;
				@include media-breakpoint-down(sm) {
					width: 30%;
				}
				a{
					display: block;
					font-size: 14px;
  					font-weight: 500;
  					text-align: center;
  					padding-bottom:2px;
  					color: #9b9b9b;
  					text-decoration: none;
  					&.action.towishlist{
						display: none;
					}
  					&:hover{
  						text-decoration: underline;
  					}
				}
			}
		}
		&.item-actions {
			a.action {
			    display: block;
			    @include media-breakpoint-down(sm) {
			    	display: inline-block;
			    	margin-right: 20px;
			    }
			}
			@include media-breakpoint-down(sm) {
			    // float: left;
			    // width: 65%;
			    // padding: 0;
				// text-align: left;
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
		&.image {
			width: 178px;
			padding-left: 0;
			.product-image-wrapper {
				padding: 0 !important;
			}
			img {
				margin: 0 auto;
				max-width: 100%;
				height: auto;
			}
			@include media-breakpoint-down(sm) {
				width: 35%;
				float: left;
				padding: 0 15px 0 0;

			}
		}
		&.item {
			padding: 20px 0;
			border-left: 0 none;
			text-align: left;
			position: relative;
			width: 30%;
			@include media-breakpoint-down(sm) {
				width: 100%;
				float: left;
				padding: 0;
			}
			.item-content {
    			display: flex;
			}
			.product-item-name {
				padding: 0 20px;
			}
			tr:last-child {
				border-bottom: $border-width $border-type $border-colour;
			}
			.product-item-details {
				@include media-breakpoint-down(sm) {
				    padding-top: 20px;
				}
				position: static;
				p{
					padding-left: 20px;
					font-size: 18px;
				}
			}
			.product-item-name {
				font-size: 18px;
				display: block;
				margin-bottom: 10px;
				height: auto !important;
				@include media-breakpoint-down(sm) {
					font-size: 14px;
				}
			}
			.product-item-photo {
			    float: left;
			}
			.product-label {
				display: block;
				margin-top: 10px;
				font-size: 7px;
				border-radius: 0;
				height: 18px;
				line-height: 18px;
				margin-bottom: 20px;
				background: #fff;
				width: 167px;
				padding: 0;
				position: static;
				&:before {
					@include font-icon('\e920');
					margin-right: 5px;
					font-size: 13px;
					display: inline-block;
					vertical-align: -3px;
				}
			}
			.product-sku {
				display: block;
				font-size: 12px;
			}
			.item-options {
			    display: block;
			    clear: both;
			    overflow: hidden;
				dt {
					display: block;
					clear: both;
					margin-bottom: 10px;
				}
				dd {
					display: block;
					font-size: 10px;
				}

			}
			.item-actions {
				position: absolute;
				bottom: 30px;

				a {
					display: inline-block;
					padding-right: 10px;
					font-size: 10px;
					color: #afafaf;
					&.action-edit {
						&:before {
							@include font-icon('\e90f');
							font-size: 12px;
							padding-right: 3px;
						}
					}
					&.action-delete {
						&:before {
							@include font-icon('\e926');
							font-size: 12px;
							padding-right: 3px;
						}
					}
				}
				@include media-breakpoint-down(sm) {
					position: static;
				}
			}
		}
		&.price {
			font-size: 16px;
			font-weight: normal;
			color: $primary-colour;
			padding: 20px;
			@media(max-width: $screen-sm-min) {
				width: 20%;
				float: left;
				clear: left;
				padding: 0 20px;
				line-height: 44px;
			}
			.price-including-tax {
    			width: 100%;
    			display: block;
			}
			.price {
			}
		}
		&.col.qty {
			@include media-breakpoint-down(sm) {
				display: flex;
				flex-wrap: wrap;
				@include make-col-ready();
                width: 100%;
				clear: both;
				justify-content: center;
			}
		}
		&.qty {
			.control.qty{
                @include media-breakpoint-down(sm) {
                	display: inline-block;
                	margin: 0;
                }
                label.label {
                    display: none !important;
    			}
    			.input-text.qty {
    				@include media-breakpoint-down(sm) {
    					width: 100%;
    				}
    			}
    			.update-cart-item {
    				width: 32%;
    				margin-left: 10px;
    				@include media-breakpoint-down(sm) {
    					width: 100%;
    					margin: 0;
    				}
    			}
            }

		}
		&.subtotal {
			color: $primary-colour;
			font-weight: 600;
			font-size: 24px;
			padding:16px 0;
            font-weight: 500;
			@media(max-width: $screen-sm-min) {
				// width: 70%;
				// float: left;
				// padding: 0 20px;
				display: flex;
				width: 100%;
				justify-content: center;
				align-items: center;
			}

		}
		label.label {
			display: none;
			@media(max-width: $screen-sm-min) {
				display: block;
			}
		}
	}
}

/* ============================================ *
 * Cart Bottom
 * ============================================ */
.cart-bottom {
    @include make-row();
    justify-content: space-between;
    padding-top: 60px;
    @include media-breakpoint-down(sm) {
        @include make-col-ready();
        margin: 0 auto;
        padding-top: 0;
        border:0;
    }
}
.cart-discount {
    @include media-breakpoint-up(sm) {
    	@include make-col-ready();
        @include make-col(6);
    }
}
.cart-summary {
    @include make-col-ready();
    padding-top: 0;
    padding-bottom: 0;
    @include media-breakpoint-up(sm) {
        @include make-col(4);
	}
	dl.items.methods {
		align-items: flex-start;
		justify-content: flex-start;
		display: flex;
		flex-direction: column;
	}

    #block-giftcard {
        display: none;
    }
    @include media-breakpoint-down(sm) {
        margin: 0;
        padding: 0;
    }
    .table-wrapper {
        .totals {
            tbody {
                display: flex;
                flex-direction: column;
            }
        }
    }
    .totals-tax {
        order: 1;
    }
}
.cart-totals {
    display: block;
    width: 100%;
    .data.totals {
        tr {
            display: flex;
            width: 100%;
            th {
                text-align: left;
                font-size: 14px;
                font-weight: bold;
                color: $primary-colour;
                &:last-child {
                    text-align: right;
                }
                 @include media-breakpoint-down(sm) {
                  text-align: left;
                }
            }
            td{
                font-size: 14px;
                font-weight: 500;
                color: $primary-colour;
                text-align: left;
                @media(max-width: $screen-sm-min) {
                    text-align: right;
                }
            }
            th,td {
                padding: 10px 0;
                border: 0;
                width: 50%;
                text-align: left;
                &:last-child {
                	text-align: right;
                }
                @media(max-width: $screen-sm-min) {
                    text-align: left;
                    padding: 10px 0;
                }
            }
            &:last-child {
                td,th, strong {
                    font-size: 17px;
                    font-weight: 600;
                    color: $primary-colour;
                    @include media-breakpoint-down(md) {
                    	font-size: 16px;
                    }
                }
            }
        }
    }
}
.cart.actions {
     @include make-col-ready();
     @include make-col(12);
     display: flex;
     clear: both;
     overflow: hidden;
     padding: 15px 0 0 0;
     border-top:1px solid #ccc;
     margin-top: 14px;
    .continue-shopping {
        @include media-breakpoint-down(sm) {
            width: 50%;
        }
        a{
            font-size: 16px;
            font-weight: 500;
            color: $primary-colour;
            text-transform: uppercase;
            margin-top: 20px;
            display: block;
            @include media-breakpoint-down(sm) {
                font-size: 10px;
                 text-align: left;
            }
            &:before{
                @include font-icon('\e902');
                color: $primary-colour;
                font-size: 12px;
                padding-right:12px;
            }
        }
    }
    .methods {
        margin-left: auto;
        float: right;
        text-align: right;
        .item {
            float: right;
            margin-bottom: 15px;
			&:last-child {
				margin-bottom: 0;
				display: none;
			}
        }
        @include media-breakpoint-down(sm) {
            width: 80%;
            margin-top: 10px;
            text-align: center;
        }
    }
    .action {
        &.continue {
            float: left;
        }
        &.update {
            float: right;
        }
    }
    @media(max-width: $screen-sm-min) {
        border: 0;
        padding: 0;
        margin: 0;
    }
}
.action.primary.checkout {
    background:$primary-colour;
    line-height: 48px;
    height: 48px;
    color: $secondary-colour;
    padding:0;
    font-size: 14px;
    padding : 0 40px;
    font-family: $font_family-primary-bold;
    text-transform: uppercase;
    span {
    	color:#fff;
    }
    &:hover {

    }
}
.action.multicheckout {
    margin: 13px 15px 0;
    display: block;
}

/* ============================================ *
 * Block Discount
 * ============================================ */
.block {
	&.discount {
		.title {
			outline: none;
			strong {
				display:block;
				text-align:left;
				font-size: 21px;
    			font-weight: 500;
    			color: $primary-colour;
				margin-bottom: 10px;
				&:after{
					@include font-icon("\e93c");
			    	margin-left: 10px;
			    	font-size: 18px;
				}

			}
		}
		&.active {
			.title {
				strong {
					&:after{
						@include font-icon("\e93c");
						transform: rotate(180deg);
						display: inline-block;
					}
				}
			}

		}
		.coupon {
		    display: block;
		    clear: both;
		    overflow: hidden;
		    text-align: left;
		    @include make-row();
		    margin: 0;
			.field {
				width: 392px;
				height: 46px;
				@include media-breakpoint-between(sm,lg) {
					width: 380px;
				}
				@include media-breakpoint-down(sm) {
					width: 73%;
				}

				@include media-breakpoint-down(sm) {
					padding-right:0;
				}
			}
			.cancel.primary {
			  	width: 82px;
			  	span {
			  		padding: 0;
			  	}
			 }
			.actions-toolbar {
				//width: 100px;
				//height: 46px;
				padding: 20px 0;
				> .primary{
					padding:0;
				}
                button {
                    @include media-breakpoint-down(sm) {
                        min-width: 80px;
                        padding: 0;
                    }
                }

				@include media-breakpoint-down(sm) {
					width: 25%;
				}
			}
			.find-vocher {
			    display: inline-block;
			    clear: both;
			    font-size: 14px;
  				font-weight: normal;
			    margin-top: 15px;
			    text-align: left;
			    line-height: 20PX;
			    color: $primary-colour;
			    margin-top:50px;

			}
			.payment-footer{
				max-width: auto;
				margin: 50px 15px 0;
				float: left;
                @include media-breakpoint-down(sm) {
                    margin-left: 0;
                }
				ul{
					li{
						margin: 0 15px 0 0;
                        display: inline-block;
						a{
							width: 30px;
							height: 18px;
						}
					}
				}
			}
		}

		@media(max-width: $screen-sm-min) {
		    padding: 10px 0 20px;
		}
	}
}
/* ============================================ *
 * Cart - Crossel
 * ============================================ */
.block {
	&.crosssell {
		@include make-container;
		padding-bottom: $padding*3;
		.block-content {

		}
	}
}

/* ============================================ *
 * Cart-Page-Message
 * ============================================ */
 .cart-top {
 	padding:0;
 }
.message-container {
    background: #e6e6e6;
    display: block;
    margin-bottom: 10px;
    p {
    	display: block;
    	padding: 10px;
    	font-size: 12px;
	}
}


/* ============================================ *
 * One page checkout -  general
 * ============================================ */
.checkout-container {
    @include make-row;
	flex-wrap: wrap;
    width: 100%;
    margin: 0;
    .messages {
    	width: 100%;
    }
}
.amazon-button-container {
    margin-bottom: 10px
}
.page-layout-checkout {
    .logo {
        margin: auto;
        img {
	        @include media-breakpoint-down(md) {
	            max-width: 159px;
	        }
	    }
    }
    .loading-mask {
        position: fixed !important;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        margin: auto;
        background: rgba(255,255,255,0.5);
        img {
            left: 0;
            right: 0;
            bottom: 0;
            top:0;
            margin: auto;
            width: 55px;
            height: 55px;
            position: absolute;
            display: block;
        }
        p {
            display:none !important;
        }
    }
    > .loading-mask {
        img {
            width: 250px;
            height: 210px;
        }
    }
	.page-header {
		border-bottom: 2px solid #ccc;
    	margin-bottom: 20px;
	}
	.header {
		@include make-container();
		.shopping {
			@include make-col(4);
             @include media-breakpoint-down(sm) {
                display: none;
            }
			.continue-shopping {
				position:relative;
				text-align: left;
				a {
					font-size: 14px;
  					font-weight: normal;
  					color: $primary-colour;
  					letter-spacing: 1.2px;
  					line-height: 30px;
  					padding-left:20px;
  					&:before {
						@include font-icon('\e920');
						font-size: 10px;
					 	position: absolute;
    					top: 12px;
    					left: 0;
  					}
				}
			}
		}
		.help {
			@include make-col(4);
			text-align: right;
			margin-left:auto;
			font-size: 14px;
  			font-weight: normal;
  			color: $primary-colour;
  			line-height: 1.5;
            @include media-breakpoint-down(sm) {
                display: none;
            }
			span {
				a {
					color: $primary-colour;
                    text-decoration: underline;
					text-decoration: none;
					&:hover{
						text-decoration: underline;
					}
				}
			}

		}
		&.content {
			text-align: center;
			position: relative;
			padding: 10px 0;
			display: flex;
		}
	}
    .page-wrapper {
        position: relative;
        overflow: hidden;
        padding-bottom: 45px;
    }

}

.checkout-index-index {
    .page-main {
        padding-top: 0;
    }
    .column.main {
	  @include make-container;
    }
     .content.minicart-items{
        display: block !important;
    }
    .top-container{
        width: 100%;
        text-align: center;
		margin-bottom: $margin;
		span.icon-padlock {
			position: relative;
			display: flex;
			justify-content: center;
		}
    }
    .nav-toggle{
        display: none;
    }
    .street.admin__control-fields {
        .control {
            .label {
                display: none;
            }
        }
    }
    .action-close {
        background: none;
        padding: 0;
        span {
            display:none;
        }
        &:before {
		   @include font-icon('\e90b');
            font-size: $font-sm;
        }
    }

    #customer-password {
        &::placeholder{
            font-size: 0;
        }

    }
    select {
        width:100%;
        height: 44px;
    }
    .action-select-shipping-item , .edit-address-link , .action-update , .action-cancel , .action-edit-address , .action-show-popup {
		span {
			width: 142px;
			margin-top: 20px;
		}
	}
	.message.warning {
    	color: #6f4400;
    	position: static;
    	padding: 40px 0;
    	background: none;
    	span {
    		background: #fdf0d5;
    		display: block;
    		padding: 5px 20px!important;
    		margin: 0;
    		font-size: 11px;
		}
	}
	.shipping-address-items {
		display: flex;
		border-bottom: 2px solid #000;
		flex-wrap: wrap;
	}

}
.opc-main {
	 @include make-container;
	.columns {
	}
	/* Authentication */
	.authentication-wrapper {
        display: none;
		.authentication-dropdown {
		    display: none;
		    width: 500px;
		    padding: $padding;
		    position: fixed;
		    background: #ccc;
		    z-index: 99999 !important;
		    left: 34%;
		    top: 20%;
		    &._show {
			    display: block;
			}
			.modal-header {
			    float: right;
			}
			.primary {
			    float: right;
			}
		}
		.login-form-toggle {
		    display: block;
		    clear: both;
		    padding: 5px;
		    max-width: 500px;
		    border: $border-width*2 $border-type $border-colour;
		    margin-bottom: 30px;
		    overflow: hidden;
		    p {
			    display: inline-block;
			    padding-left: $padding;
			    font-size: 18px;
			    line-height: 50px;
			}
			.action {
			    float: right;
				span {
				    line-height: 45px;
				    padding: 0 $padding*3;
				}
			}
		}
	}
}
/* ============================================ *
 * opc-estimated-wrapper
 * ============================================ */
.opc-estimated-wrapper {
    justify-content: space-between;
    width: 100%;
    padding: 0 $padding;
    background-color: #f5f5f5;
    height: 90px;
    align-items: center;
    display: none;
    @include media-breakpoint-down(sm) {
        display: flex;
    }
    button {
       position: relative;
       background: none;
       padding: 0;
       align-items: center;
       	span {
       		border:0 ;
       		padding: 0;
       	}
       &::before {
             @include font-icon('\e906');
             font-size: 20px;
             position: absolute;
             right: 20px;
        }
    }
    .estimated-block {
        display: inline-block;
    }
}
/* ============================================ *
 * OPC Wrapper
 * ============================================ */
.opc-wrapper {
	@include make-col-ready();
    @include make-col(8);
    padding-left: 0;
    text-align: left;
	@include media-breakpoint-down(sm) {
	    @include make-col(12);
        padding:0 15px;
	}
	select ~ label.label{
		display: none;
	}
	fieldset {
		.field {
			._with-tooltip {
    			padding-right: 0;
    		}
    	}
	}
	.field-tooltip {
        @include media-breakpoint-down(sm) {
            display: none;
        }
        .label {
            display: none;
        }
		.field-tooltip-content{
			background: $primary-colour;
   			border: 1px solid $secondary-colour;
   			color: $secondary-colour;
   			padding: 0 0 0 5px;
   			left: 5px;
   			top: -6px;
   			line-height: 46px;
   			height: 46px;
            width: 210px;
            text-align: center;
            font-size: 10px;

		}
	}
	.step-title {
		padding-bottom: 10px;
    	margin-bottom: 20px;
    	border-bottom: 1px solid #ccc;
    	font-size: 21px;
    	font-weight: 500;
    	color: $primary-colour;
		@include media-breakpoint-down(sm) {
			margin-top: 30px;
		}
	}
	.form-login {
		#customer-email-fieldset {
			fieldset {
			    margin-bottom: 20px;
			}
			.actions-toolbar {
			    display: flex;
			    justify-content: space-between;
			    align-items: flex-end;
			}
		}
	}
	.form-login,
	.form-shipping-address {
		max-width: 500px;
		padding: 0;
		.fieldset {
			.field {
				._with-tooltip{
					padding-right:0;
				}
			}
		}
	}
	.field:not(.choice) {
		display: block;
		width: 100%;
		margin-bottom: 20px;
		position:relative;
		.note{
			color:#9b9b9b;
		}
		.field-tooltip {
			.field-tooltip-action {
			    display: block;
			    width: 20px;
			    height: 20px;
			    line-height: 22px;
			    text-align: center;
			    padding-left: 2px;
			    color: #ddd;
			    position: absolute;
			    left: -35px;
    			top: 1px;
				&:before {
					content: "?";
					font-size: 14px;
					color: #fff;
					line-height: 29px;
					width: 29px;
					height: 29px;
					background: $primary-colour;
					border-radius:50%;
    				left: 0;
    				right: 0;
    				top: 0;
    				position: absolute;
				}
			}
		}
		&.label {
			position: relative;
			&:after {
				content: "*";
				color: red;
				display: inline-block;
				font-size: 12px;
			}
		}
	}
    #checkoutSteps {
        > li {
            @include media-breakpoint-up(sm) {
                margin-bottom: 45px;
            }
        }
    }
}
.shipping-address-items {
    .shipping-address-item{
        margin-bottom: $margin;
        padding: 10px 0;
		width: 50%;
		min-height: 220px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		&.selected-item{
			button.action-select-shipping-item{
				span{
					background: #000;
					color: #fff;
				}
			}
		}
    }
}

.table-checkout-shipping-method {
	.row {
		display: table-row;
		margin: 0;
	}
	th {
		padding: 5px 0;
		color: $primary-colour;
		@media(max-width: $screen-sm-min) {
			display: none;
		}
	}
	td {
		padding: 10px 0;
		color: $primary-colour;
		&:first-child {
			padding-left: 10px;
		}
        @include media-breakpoint-down(sm) {
            padding: 10px 5px;
            font-size: $font-sm;
        }
	}
}
#shipping-method-buttons-container {
	padding: 40px 0;
	display: block;
	@include media-breakpoint-down(sm) {
		padding: 20px 0;
	    display: block;
	    overflow: hidden;
	    text-align: center;
        padding-bottom: 60px;
	}
	.primary {
		@include media-breakpoint-up(sm) {
			float: right;
		}

	}
}
/* ============================================ *
 * address popup
 * ============================================ */
.address-form-popup {
    h1 {
        font-size: 2em;
        display: inline-block;
    }
    .action-close {
        float: right;
    }
    &.modal-popup{
        @include media-breakpoint-down(sm) {
            left: 10px !important;
            right: 10px !important;
            top: 10px !important;
        }
    }
	.modal-inner-wrap {
	    top: 10%;
	    text-align: left;
        height: 500px;
        position: static;
        max-height: 90%;
        width: 70%;
        max-width: 729px;
		@include media-breakpoint-down(sm) {
			top: 5%;
			width:98% !important;
		}
		.modal-header {
		    padding: 20px 30px 0;
            flex: 0;
            .action-close{
               position: static;
            }
		}
        #tooltip-label {
            display: none;
        }
		.fieldset {
		    @include make-row();
		    flex-wrap: wrap;
		    justify-content: space-between;
		}
		.field {
            @include make-col-ready();
            @include make-col(6);
			@include media-breakpoint-down(sm) {
				 @include make-col(12);
			}
		    margin-bottom: 10px;
			.label {
			    display: inline-block;
			    margin-bottom: 5px;
			}
			._with-tooltip {
			    padding: 0;

			}
			&.street {
				@include make-col(12);
				.control {
				    display: flex;
				    justify-content: space-between;
					@include media-breakpoint-down(sm) {
					    flex-wrap: wrap;
					}
				    .label {
					    display: none;
					}
				}
                .field {
                    @include media-breakpoint-down(sm) {
                        padding: 0;
                    }
                }
			}
			&[name='shippingAddress.company'] {
			    order: 10;
			}
			&.choice {
			    order: 12;
			    padding-top: 20px;
                input {
                    float: left;
                    width: 10%;
                }
                .label {
                    float: left;
                    width: 85%;
                    line-height: 13px;
                }
			}
		}
		.modal-footer{
			justify-content: space-between;
			display: flex;
            padding: 0 10px;
			button {
				margin-bottom: 10px;
			}
		}
		.field.admin__control-fields > .control {
            @include make-row;
            @include media-breakpoint-down(sm) {
               margin: 0;
            }
		}
	}
}
/* ============================================ *
 * payment section
 * ============================================ */
.checkout-index-index {
	.payment-method {
		iframe {
            height: 200px !important;
        }
	}
	.payment-option-content {
		.form-discount {
			div.payment-option-inner {
				@include media-breakpoint-down(sm) {
					width: 100%;
				}
			}
			.action.action-apply {
				span {
					line-height: 36px;
				}
			}
		}

	}
	.form-discount {

		.actions-toolbar {
			div.primary {
				//border: 2px solid #000;
			}
			.action {
				height: 42px;
				line-height: 40px;
				padding: 0 10px;
				border: 2px solid #000;				
			}
		}
		button {
			> span {
				margin: 0;
				padding: 0 10px;
				border: 0;
				height: auto;
			}
		}
	}
}
.payment-method {
    padding-bottom: 25px;
    margin-bottom: 20px;
    line-height: normal;
    border-bottom: 1px solid #ccc;
	@include media-breakpoint-down(sm) {
		padding-bottom: 25px;
	}
    .field{
        input,label{
            width: auto;
            float: left;
        }
        &.number {
            iframe {
               height: auto !important;
            }
        }
    }

    .fieldset{
        clear: both;
        width: 100%;
        &.address {
        	label {
        		margin: 18px 0 10px;
        	}
        }
    }
    .hosted-error {
        clear: both;
        font-size: $font-sm;
    }
    .field-tooltip {
        display: none;
    }
    input[type="radio"]{
		margin-right: 5px;
    }
    input[type="checkbox"]{
        margin:4px 4px 0 0;
    }
    .input-text {
    	width: 100% !important;
		min-height: unset;
    }
    iframe {
        height:50px !important;
         input {
           border: 1px solid #cdcdcd;
            padding: 0 10px;
            line-height: 40px;
            height: 42px;
        }
    }
    label {
		display: flex;
		align-items: center;
		width: 100% !important;
		flex-direction: row-reverse;
		justify-content: flex-end;
        font-size: 16px;
        margin: 0;

		.payment-icon {
			margin-left: auto;
		}
    }

	.available-credit {
		display: flex;
		white-space: nowrap;
		strong {
		    padding-right: 5px;
		}
	}
	.actions-toolbar {
	    text-align: right;
        margin-top: $margin;
		@include media-breakpoint-down(sm) {
			padding-top: 20px;
		}

	}
	.primary {
		@include media-breakpoint-down(sm) {
			text-align: center;
		}

	}
    &._active{
        .payment-method-content {
            display: block;
            padding-top: 15px;
        }
    }
}
.payment-method-content {
    display: none;
}
.companycredit-opayo .payment-method-content {
	display: block !important;
}
.sp-container{
	height: 200px;
}
.payment-method-title{
	display: flex;
    align-items: center;
    margin: 0 !important;
    min-height: 20px;
    @include media-breakpoint-down(sm) {
    	overflow: hidden;
    }
}

.payment-option {
	margin-top: 15px;
    .payment-option-title {
	    cursor: pointer;
    	color: $primary-colour;
    	font-size: 16px;
	}
	.payment-option-content {
		@include media-breakpoint-up(sm) {
		   	padding-bottom: 20px;
		}
		.field {
			label{
                display: none;
            }

    	}
    	.actions-toolbar{
    		> button.primary{
    			padding: 0;
    			border: 1px solid #000;
    			height: 42px;
    			&:hover {
    				background:$primary-colour;
    			}
    		}
    	}
    	.action.action-apply span {
    		border: 0;
    		line-height: 41px;
    		padding: 0;
    		text-align: center;
    		width: 125px;
    	}
		.form-discount {
			display: flex;
			.payment-option-inner{
				width: 390px;
				height: 46px;
				line-height: 46px;
                @include media-breakpoint-down(sm) {
                    width: auto;
                    margin-bottom: 20px;
                }
			}
		}
		.label {
		    display: block;
		    padding-bottom: 10px;
		}
		.actions-toolbar {
			width: 100px;
			height: 46px;
		    display: flex;
		    justify-content: space-between;
            @include media-breakpoint-down(sm) {
                width: auto;
            }
		}
	}
	&.discount-code{
		.payment-option-title{
			position: relative;
			outline: none;
			&:focus{
				outline: none;
			}
			&:after{
				@include font-icon('\e93b');
				position: absolute;
				right: 0;
				transform: rotate(180deg) scaleX(-1);
				top: 10px;
			}
		}
		&._active{
			.payment-option-title{
				position: relative;
				&:after{
					@include font-icon('\e93b');
					position: absolute;
					right: 0;
					top: 10px;
					transform: none;
				}
			}

		}

	}
}
#use-customer-balance {
    padding: 20px 0 0;
}
.payment-method-billing-address {
	@include media-breakpoint-up(sm) {
	   	padding-bottom: 20;
	}
	.label{
		position:relative;
	}
}
.checkout-billing-address{
     display: flex;
     flex-wrap: wrap;
     clear: both;

	.actions-toolbar .primary {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		button.action.action-update >span {
			background: #000;
			color: #fff;
		}
	}
    .action-cancel{
            margin-left: 0;
            @include media-breakpoint-down(sm) {
                margin-left: 0;
            }
     }
    .field.choice{
        display: flex;
        label.check-wrap{
            width: 20%;
        }
        label{
            span {
                font-size: 14px;
                font-weight: 300;
                font-style: normal;
                color: $primary-colour;
            }
        }
    }
    .control {
        height: 42px;
        clear: both;
    }
    input {
    	width: 100%;
    }
}
.billing-address-details{
    width: 100%;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    color: $primary-colour;
}
/* ============================================ *
 * braintree
 * ============================================ */
ul.credit-card-types {
    margin-top: 20px;
    li.item {
        margin-bottom: 15px;
        display: inline-block;
        padding: 0 10px;
        label {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
            align-items: center;
            input {
                margin-right: 15px;
            }
        }
    }
}
#co-transparent-form-braintree{
    iframe{
        border: 1px solid #cdcdcd !important;
        padding: 0 10px;
        line-height: 40px;
        height: 42px !important;
        font-size: $font-sm;
        margin-bottom: 10px;
        &:focus{
            border-color: #000 !important;
        }
    }
    .control.braintree-card-control {
        img {
         width: 25px;
         margin-left: 10px;
        }
    }
    .hosted-control {
        width: 50%;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }

}
.cart .paypal.checkout,
.bottom-minicar .paypal.checkout {
    display: none;
}
.giftcardaccount {
	display: none;
}
/* ============================================ *
 * OPC Progress bar - show only on desktop
 * ============================================ */
.opc-progress-bar {
    display: none;
}
 @include media-breakpoint-up(sm) {
    .links-container{
        width: 100%;
        display: block;
        border-bottom: 2px solid #ccc;
        margin-bottom: 20px;

    }
    .opc-progress-bar {
        margin: 0 auto;
        counter-reset: i;
        display: flex;
        width: 100%;
        max-width: 100%;
        &-item {
            @include make-col(4);
            margin: 0 auto 10px;
            display: block;
            display: inline-block;
            position: relative;
            text-align: center;
            vertical-align: top;
            cursor: pointer;
            > span {
                display: inline-block;
                width: 100%;
                word-wrap: break-word;
                color: #9b9b9b;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
                margin: 0 auto 10px;
                letter-spacing: 1.2px;
                &:before {
                    display: inline-block;
                    content: counter(i)".";
                    counter-increment: i;
                    margin-right: 3px;
                }
            }

            &._active {
                > span {
                    color: $primary-colour;
                }
            }

        }
    }
}

/* ============================================ *
 * OPC Side bar
 * ============================================ */
.opc-sidebar {
	@include make-col-ready();
    @include make-col(4);
    padding-right: 0;
    outline: 0;
    text-align: left;
	@include media-breakpoint-down(sm) {
	    @include make-col(12);
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 100%;
        height: 100%;
        padding: 0;
         -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
	}
    &._show {
        left: 0;
    }
	.title {
	    display: inline-block;
	    color: $primary-colour;
	    font-size: 21px;
	    font-weight: 500;
  		font-style: normal;
	    margin-bottom: 9px;
	    text-align: left;

	}
    & > .title {
        @include media-breakpoint-down(sm) {
            padding-left: 18px;
        }
    }
	.modal-header {
        display: none;
        @include media-breakpoint-down(sm) {
            display: block;
            text-align: right;
            padding: 20px 10px 10px 10px;
            border: 2px solid #233759;
            border-bottom: none;

        }
	}
    .modal-content{
        border: 2px solid $primary-colour;
        padding: 15px;
        margin-bottom: 75px;
        @include media-breakpoint-down(sm) {
           border-top: none;
           border-bottom: 0;
           height: 100%;
        }
        .secondary {
    		display: inline-block;
    		padding-left: 14px;
		}
        a.action.viewcart {
    		text-align: left;
    		border-bottom: 1px solid #000;
		}
    }
    .modal-inner-wrap {
        @include media-breakpoint-down(sm) {
            background-color: #f5f5f5;
            margin-left: 50px;
            height: 100%;
        }
    }
}
.opc-block-summary {
    margin: 0 auto;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    //padding:$padding;
    .minicart-items-wrapper {
        max-height: 55vh;
        overflow: auto;
        height: auto;
        width: 100%;
        border-top: 1px solid #d2d2d2;
        border-bottom: 1px solid #d2d2d2;
        .product-item-name {
            margin-bottom: 10px;
            display: block;
            text-align: left;
        }
        .details-qty {
            margin-bottom: $margin;
            text-align: left;
        }
		.details-sku {
		    text-align: left;
		}        
        .subtotal {
        	text-align: left;
        }

        @include media-breakpoint-down(sm) {
            max-height: 100vh;
        }
    }
    .title {
        display: block;
        position: relative;
        margin-bottom: 10px;
        font-size: 21px;
        font-weight: 500;
        color: $primary-colour;
    }
    .block.items-in-cart{
        order: -1;
        border-bottom:0;
        margin: 0 auto;
        width: 100%;
    }
    .table-totals {
        tr {
            td,th {
                font-size: 16px;
                font-style: normal;
                padding: 10px 0 0;
                white-space: normal;
                span{
                    font-size: 16px;
                    font-style: normal;
                    margin: 0;
                    color: $primary-colour;
                }
            }
            td:last-child {
                text-align: right;
                border: 0;
            }
        }
    }
    li.product-item {
        padding: 20px 0;
        margin: 0 !important;
    	max-width: 100%;
    	overflow: hidden;
    }
    .items-in-cart {
        .title {
            font-size: $font-sm;
            padding-bottom: 10px;
            &:after {
                position: absolute;
                right: 5px;
                top: 5px;
            }
        }
        .product-item-inner {
        	flex-direction: column;
        	.details-qty {
        		margin-bottom: 0;
        	}
        }
        .product-item {
            .product {
                @include make-row;
                //width: 100%;
            }
            .product-image-container {
                @include make-col-ready();
                @include make-col(4);
                padding: 0;
                border: none;
            }
            .product-item-details {
                margin: 0;
                float: none;
                @include make-col-ready();
                @include make-col(8);
            }
            .product-image-wrapper {
                height: auto;
                border: none;
            }
            .product-item-name-block,.subtotal {
                @include media-breakpoint-down(sm) {
                  //width: 50%;
                  //float: left;
                }
            }
            .subtotal {
                @include media-breakpoint-down(sm) {
                    text-align: left;
                }
            }
        }
    }
}
.shipping-information {
    margin: 0 0 20px;
}
.shipping-information-content {
    line-height: 22px;
    text-align: left;
}
.ship-to,.ship-via {
    margin: 0 0 20px;
}
.shipping-information-title {
    border-bottom: 1px solid #cccccc;
    margin: 0 0 20px;
    position: relative;
    padding-bottom: 10px;
    color: #333333;
    font-size: 18px;
    text-align: left;
    display: block;
    clear: both;
    overflow: hidden;
    span {
        line-height: 40px;
    }
    button {
        float: right;
    }
}
.opc-block-shipping-information{
    display: none
}

.fieldset > .legend {
	display: none;
}

/* ============================================ *
 * success page
 * ============================================ */
.checkout-onepage-success {
    .page-main {
        padding-top: 0;
    }
	.opc-wrapper {
	    width: 100%;
	    text-align: center;
	    font-size: 20px;
	    line-height: 30px;
        margin: auto;
        padding:0;
	}
    .nav-toggle{
        display: none;
    }
	#registration {
	    display: block;
	    clear: both;
	}
	.page-title {
	    display: block;
	    clear: both;
	    margin-bottom: $margin*2;
	}
	.checkout-success {
		padding: 40px 0;
		a.action {
			display: inline-block;
			clear: both;
			overflow: hidden;
			float: none;
			margin: 0 auto;
			background: #000;
			color: #fff;
			padding: 10px 20px;
			margin-top: 30px;
			font-size: 16px;
		}
		a.action.create-account {
		    display: inline-block;
		    margin: $margin 0;
            padding: 0 40px;
            height: 48px;
            line-height: 48px;
            background:$primary-colour;
            color: $secondary-colour;
            cursor:pointer;
            font-family: $font_family-primary-bold;
            font-size: $font-xs;
            text-transform: uppercase;
		}
	}
    .page-footer {
        margin-top: 100px;
    }
}
.checkout-cart-index {
	.product-item-details .price-box {
		.price-box {
			margin: 0;
		}
	}
	button.add-to-wish{
		display: none;
	}
	// button.action.toggle.change{
	// 	display: none;
	// }
	.actions-primary .tocart  {
		padding-left: 16px;
		@include media-breakpoint-down(sm) {
			padding-left: 24px;
		}
		span {
			width: 126px;
			@include media-breakpoint-down(sm) {
				width: 90px
			}
		}
	}
	.product-item-details {
		overflow: hidden;
	}
	.modal-inner-wrap {
		.modal-title {
			font-size: 24px;
    		line-height: 43px;
		}
		.modal-content {
			padding: 20px;
			.label {
				display: block;
			}
		}
		.actions-toolbar {
    		display: flex;
    		justify-content: center;
    		button {
    			margin:  10px;
    		}
		}
		.message.error {
			display: none;
		}
	}
	.cart.actions {
		border-top:0;
		@include media-breakpoint-down(sm) {
			margin-top: 20px;
			justify-content: center;
		}
		a.action.continue {
		    display: none;
		}
	}
	@include media-breakpoint-down(sm) {
		.cart-discount {
    		width: 100%;
		}
	}
	.cart-gift-item {
		display: none;
	}
	.split.wishlist {
		button {
			margin: 0 auto 10px;
			@include media-breakpoint-down(sm) {
				margin: 0 0 10px;
			}
		}
	}
}
/************ address pop up **********/
.address-form-popup {
	.modal-inner-wrap {
		.modal-header {
			padding: 6px 30px 0;
			&:after {
				position: absolute;
				left: 44%;
    			top: 4px;
    			@include media-breakpoint-down(sm) {
    				display: none;
    			}
			}
		}
		.action-close {
			color: $secondary-colour;
			&:before {
				@include font-icon('\e90b');
				font-size: 14px;
			}
		}
		.modal-footer {
			padding: 10px;
			.action {
				margin: 0 10px 15px;
				span {
					line-height: 25px;
					width: 142px;
					border: 2px solid;
				}
				&:hover {
					span {
						background: $primary-colour;
						color: $secondary-colour;
					}
				}
			}
		}
	}
}
.form-shipping-address {
	padding: 0 20px;
}
.field-error {
	color: #f11e1e;
	display: block;
	width: 100%;
}
.checkout-methods-items {
	display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include make-col-ready();
    .item {
    	width: 100%;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        @include media-breakpoint-down(sm) {
        	align-items: center;
        }
    }
	.secure-text {
	    text-align: left;
	    display: block;
	    margin: 15px 0;
	    font-family: $font_family-primary-bold;
	}
    .negotiable-quote.request {
    	display: block;
    	margin-top: 20px;
    	button {
    		min-width: 246px;
    	}
	}
}

/********** checkout payment *******/
.checkout-payment-method-load {
	.input-text{
		width: 100% !important;
	}
}
/******** edit shipping address*********/
.multishipping-checkout_address-newshipping  {
	@include make-container();
	@include make-row();
	margin: 0 auto;
	.form-address-edit {
		label {
			text-align: left;
		}
	}
}
/******** multishipping-checkout-addresses *********/
.multishipping-checkout-addresses {
	@include make-container();
	margin: 0 auto;
	@include media-breakpoint-down(sm) {
		.base {
    		font-size: 24px;
		}
	}
	.title {
		strong {
			display: block;
			margin-bottom: 30px;
		}
	}
	.data.table {
		@include media-breakpoint-down(sm) {
			overflow: auto;
			display: block;
		}
		th {
			background: #000;
			color: #fff;
			height: 40px;
			line-height: 40px;
			font-weight: bold;
			padding: 0 10px !important;
		}
		tr {
			td {
				text-align: left !important;
			}
		}
		dl.item-options {
    		display: flex;
    		justify-content: flex-start;
    		flex-wrap: wrap;
    		dt {
    			width: 30%;
    			@include media-breakpoint-down(sm) {
    				width: auto;
    			}
			}
			dd {
    			width: 50%;
    			@include media-breakpoint-down(sm) {
    				width: 30%;
    				padding-left: 10px;
    			}
			}
		}
		.field.qty {
		    display: flex;
		    justify-content: flex-start;
		    padding: 15px 0;
		    label {
    			padding-right: 20px;
			}
			input.qty {
				width: 50px;
			}
		}
		.col.actions {
    		padding: 35px 10px;
		}
		.field.address {
    		display: flex;
    		justify-content: flex-start;
    		padding: 15px 0;
    		label {
    			padding-right: 20px;
			}
		}
	}
	.actions-toolbar {
		display: flex;
		@include media-breakpoint-down(sm) {
			display: block;
		}
		.secondary {
    		display: flex;
    		margin: 0;
    		@include media-breakpoint-down(sm) {
    			display: block;
    			margin: 10px 0;
    		}
		}
		button {
			margin-right: 5px;
			@include media-breakpoint-down(sm) {
				margin-top: 10px;
			}
		}
		a.action.back {
    		line-height: 30px;
    		padding: 0 20px;
    		@include media-breakpoint-down(sm) {
    			margin-top: 10px;
    			display: block;
    			text-align: left;
    			padding: 0;
    		}
		}
	}
}
.multishipping-checkout-shipping  {
	@include make-container();
	margin: 0 auto;
	@include media-breakpoint-down(sm) {
		.base {
    		font-size: 24px;
		}
	}
	.box.box-shipping-address , .box.box-shipping-method , .box.box-items {
    	margin: 10px 0;
    	border-bottom: 1px solid #ccc;
    	padding: 10px 0;
	}
	.field.choice {
	    display: flex;
	    width: 14%;
	    margin:  0 auto;
	    justify-content: flex-start;
	    @include media-breakpoint-down(sm) {
	    	width: 45%;
	    }
	    .control {
    		width: 14%;
		}
		label {
		    text-align: left;
		    padding-left: 20px;
		}
		input.checkbox{
	    	width: 14%;
		}
	}

	strong.box-title {
	    margin-bottom: 20px;
	    display: block;
    	margin-bottom: 20px;
    	display: block;
    	background: #ccc;
    	height: 25px;
    	line-height: 25px;
	}

	.table-wrapper .items.data {
	    width: 50%;
	    margin: 0 auto;
	}
	.actions-toolbar {
    	display: flex;
    	margin-top: 20px;
    	@include media-breakpoint-down(sm) {
			display: block;
		}

    	.action.back {
    		padding-left: 20px;
    		line-height: 40px;
    		@include media-breakpoint-down(sm) {
				display: block;
				padding-left:0;
				text-align: left;
				margin-top: 10px;
			}
		}
	}

}
.cart.table-wrapper {
	.action.toggle  {
		+ .items {
			display: none;
			height: auto !important;
			max-height: unset;
			width: 200px;
			left: calc(50% - 100px);
			span:after {
				display: none;
			}
		}
	}
	.action.toggle.active  {
		+ .items {
			display: block;
		}
	}
}
.multishipping-checkout-billing {
	@include make-container();
	margin: 0 auto;
	@include media-breakpoint-down(sm) {
		.base {
    		font-size: 24px;
		}
	}
	.box-title {
	    display: block;
	    margin: 30px 0;
	}
	.box-content {
	    display: block;
	    padding: 0 0  20px;
	    border-bottom: 1px solid #ccc;
	}
	.legend.box-title , .box-billing-address .box-title{
    	background: #ccc;
    	line-height: 25px;
    	height: 25px;
    	color: #000;
    	font-weight: bold;
	}
	.actions-toolbar {
    	display: flex;
    	margin-top: 20px;
    	@include media-breakpoint-down(sm) {
			display: block;
		}

    	.action.back {
    		padding-left: 20px;
    		line-height: 40px;
    		@include media-breakpoint-down(sm) {
				display: block;
				padding-left:0;
				text-align: left;
				margin-top: 10px;
			}
		}
	}
}


/* ============================================ *
 * Prices Label
 * ============================================ */
.price-including-tax .price:before {
    content: "Incl. Tax: ";
}
// .price-excluding-tax .price:before {
//     content: "Excl. Tax: ";
// }

div#reorder-quote-popup{
	.actions-toolbar {
		display: flex;
		justify-content: space-between;
	}
	.primary {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	button.action.merge.primary {
		margin-right: 20px;
	}
	button.action.merge.primary {
		margin: 0;
	}

	.actions-toolbar {
		justify-content: flex-start !important;
	}

	@media (min-width: 992px){
		button.action.merge.primary {
			margin: 0 10px;
		}
	}

}

