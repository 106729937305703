
$price_default-font-size: 20;
$price_old-font-size: 14;
$price_spcial-font-size: 20;

$price_default-color: #000;
$price_old-color: #ccc;
$price_spcial-color: #000;

/* ============================================ *
 * Common
 * ============================================ */
 .catalog-category-view{
    .columns{
        margin-bottom: 20px;
    }
    .price-box.price-final_price {
    	min-height: 20px;
        margin-top: 0;
    }
	.catalog-topnav {
		display: none;
	}
 }

/* ============================================ *
 * home catalog widget
 * ============================================ */

.widget-product-grid {
    .product-item {
        margin: 0;
    }
    .product-item-info {
        padding: 20px;
        background: $secondary-colour;
    }
    .product-item-details .price-box {
        margin: 30px 0 16px;
        @include media-breakpoint-down(md) {
            margin: 32px 0 18px;
        }
    }
    .actions-primary form {
        display: flex;
        align-items:center;
    }
    .qty-block {
        display: flex;
        justify-content: flex-start;
        height: 55px;
        line-height: 50px;
        span {

        }
        .qty {
            margin-top: 4px;
            width: 30px !important;
        }
    }
    .action.tocart {
        width: 122px;
        span {
            font-family:$font_family-primary-bold;
            padding: 0;
            font-size: 16px;
            text-align: center;
            border: 2px solid $primary-colour;
            @include media-breakpoint-down(sm) {
                font-size: 14px;
                height: 35px;
                line-height: 30px;
                max-width: 100%;
            }
        }
    }
    .view-product {
        display: none;
    }
    .towishlist {
        top: 16px;
        right: 36px;
    }
    .product-item-link {
        font-size: 16px;
        font-weight: bold;
        height: 55px;
        padding: 0;
    }
    .swatch-attribute-options {
        flex-wrap: wrap;
        margin-top: 0;
    }
    .price-box {
        display: flex;
        margin-bottom: 16px;
        .normal-price {
            padding-right: 12px;
        }
    }
    .stock-details {
        margin-bottom: 12px;
    }
    .swatch-attribute.color{
        &:before {
            margin-top: 0;
        }
    }

    .slick-dots {
        position: absolute;
        bottom: 25px;
        list-style: none;
        text-align: center;
        padding: 0;
        margin: 0;
        left: 0;
        width: inherit;
        @include media-breakpoint-down(md) {
            bottom: 95px;
            left: 0;
            right: 0;
        }
        li {
            button {
            font-size: 0;
            padding:0;
                &:before {
                    content: "";
                    display: block;
                    color: #ffb02f;
                    border: 2px solid $primary-colour;
                    border-radius: 50%;
                    width: 16px;
                    height: 16px;
                    opacity: 1;
                    line-height: 15px;
                    padding:0;
                    background: transparent;
                }
            }
            &.slick-active {
                button {
                    &:before {
                        content: "";
                        display: block;
                        background: $primary-colour;
                        border: 2px solid transparent;
                        border-radius: 50%;
                        width: 16px;
                        height: 16px;
                        opacity: 1;
                        line-height: 15px;
                    }
                }
            }
        }
    }
}
.category-description {
    margin-top: 0;
    @include media-breakpoint-up(md) {
        width: 80%;
        p {
         font-size:$font-md;
        }
    }
    @include media-breakpoint-down(sm) {
        margin-top: -30px;
    }
    @include media-breakpoint-down(xs) {
        margin-top:10px;
    }
}
.home-product-slider {
    .widget-product-grid {
        .product-item-info {
            padding: 0;
             @include media-breakpoint-up(md) {
                padding: 10px;
             }
            @include media-breakpoint-between(sm,md) {
                width: auto;
            }
            @include media-breakpoint-down(sm) {
                width: 160px !important;
                margin: 0 auto;
                height: 290px;
            }
        }
    }
    .product-item-link {
        height: auto;
    }
    .price-box.price-final_price{
        display: flex;
        align-items: end;
        margin: 10px 0 0;
    }
    .price-wrapper  {
    	margin-top:17px;
    }
    .price-label + .price-wrapper  {
    	margin-top: 0;
    }
}
.top-slider-link {
    display: block;
    @include media-breakpoint-down(md) {
        display: none !important;
    }
    .top {
        a{
            display: block;
            font-family: $font_family-primary-bold;
            font-size: 12px;
            color: #b8b8b8;
            text-align: left;
        }
    }
    ul {
        display: flex;
    }
    li {
        padding: 0 20px 0 0;
        position:  relative;
        height: 20px;
        margin:25px 0 70px;
        a {
            display: inline-block;
            color: #b8b8b8;
            border-bottom: 1px solid #b8b8b8;
            line-height: 13px;
            margin-bottom: 85px;
            font-weight: normal;
        }
        &:after {
            @include font-icon('\e902');
            font-size: 9px;
            position: absolute;
            right: 4px;
            top: 6px;
            color: #b8b8b8;
        }
        &:last-of-type:after{
                display: none;
        }
    }
}
.slider-title {
    @include media-breakpoint-down(md) {
        margin-bottom: 30px !important;
        p{
            font-size: 24px !important;
        }
        span {
            font-size: 24px !important;
            line-height: 24px !important;
        }
    }
}

.slick-initialized .slick-slide {
    overflow: hidden;
}
/* ============================================ *
 * home catalog product slider
 * ============================================ */

.home-product-slider {
    .product-item {
        @include make-col(3);
        position: relative;
        &:hover {
            .tocart {
                display: block;
                margin: 0 auto;
            }
            .product-image-container {
                opacity: 0.4;
            }
            .actions-primary {
                position: absolute;
                top: 82px;
                left: 0;
                right: 0;
                z-index: 999;
            }
        }
        .view-product {
            display: none;
        }
    }
    .product-item-info {
        @include media-breakpoint-down(md) {
            padding: 15px 50px;
        }
    }
    .stock-details {
        display: none !important;
    }
    .qty-block {
        display: none !important;
    }
    .towishlist {
        display: none !important;
    }
    .tocart {
        display: none !important;
    }
    .product-item-link {
        font-size: 12px !important;
    }
    .price-box {
        font-size: 12px !important;
    }
    .slick-prev.slick-arrow {
        position: absolute;
        left: -80px;
        @include media-breakpoint-down(sm) {
            left: 0;
            top: 40%;
        }
        @include font-icon-before('\e937');
        &:before {
            font-size: 28px;
            color: $primary-colour;
        }
        &:hover {
           @include font-icon-before('\e936');
        }
    }
    .slick-next.slick-arrow {
        position: absolute;
        right: -80px;
        @include media-breakpoint-down(sm) {
            right: 0;
            top: 40%;
        }
        @include font-icon-before('\e939');
        &:before {
            font-size: 28px;
            color: $primary-colour;
        }
        &:hover {
           @include font-icon-before('\e938');
        }
    }
    .slick-dots {
    @include media-breakpoint-down(md) {
        bottom: -5px;
        left: 0;
        right: 0;
    }

    }
}

/* ============================================ *
 * Category Toolbar
 * ============================================ */


/* ============================================ *
 * General Price
 * ============================================ */
 .catalog-product-view,.catalog-category-view ,.catalogsearch-result-index{
.price-box {
  span {
    line-height: 1;
  }
  .price-label {
    display: none;
  }
  .special-price{
    .price-label {
        display: block;
        font-size: 12px;
		font-weight: normal;
		vertical-align: top;
        font-family:  $font_family-primary;
      }
  }
  .price-wrapper  {
  	display: block;
  	margin-top: 18px;
  }
  .price-label + .price-wrapper  {
  	margin-top: 7px;
  	display: block;
  }
  .price {
    color: $price_default-color;
    white-space: nowrap !important;
    font-size:20px;
    font-weight: bold;
  }
  .special-price {
    display: block;
    color: $price_spcial-color;
    .price {
     @include font-size(14);
    }
  }
  .old-price {
    display: none;
    @include visuallyhidden();
    span {
        color: #cccccc !important;
    }
    .price {
        color: $price_old-color;
        font-size: $price_old-font-size !important;
        text-decoration: line-through;
    }
  }
}
.price-box.price-final_price {
    display: flex;
    justify-content: flex-start;
    align-items: end;
    .normal-price {
        padding-right: 20px;
    }
}
 }
/* ============================================ *
 * Product Name
 * ============================================ */
.product-item-link {
  font-size: 16px;
  display: block;
  margin:15px 0 10px;
  text-align: left;
  @include media-breakpoint-up(sm){
    height: 43px;
    margin: 0;
    line-height: normal;    
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

}

/* ============================================ *
 * Add to cart
 * ============================================ */
.box-tocart {
    .fieldset {
        display: flex;
        flex-direction: columns;
        align-items: center;
        .qty {
            .label {}
            .control {}
        }
    }

}
// .box-tocart {
//   padding-top: $padding;
//   padding-bottom: $padding;
//   border-bottom: $border-width $border-type $border-light-colour;
//   .fieldset {
//     @include make-row();
//   div.qty {
//     @include make-col-ready;
//     @include media-breakpoint-up(sm) {
//       @include make-col(4);
//     }
//     @include media-breakpoint-down(sm) {
//       @include make-col(12);
//     }
//     label {
//       display: inline-block;
//       margin-right: $padding;
//       vertical-align: middle;
//       width: auto;
//     }
//     .control {
//       display: inline-block;
//       vertical-align: middle;
//     }
//   }
//   .actions {
//     @include make-col-ready;
//     @include media-breakpoint-up(sm) {
//       @include make-col(8);
//     }
//     @include media-breakpoint-down(sm) {
//       @include make-col(12);
//     }
//     position: relative;
//     button {
//       width: 100%;
//       padding-right: $padding*4;
//       span {
//         width: 100%;
//       }
//     }
//     .towishlist {
//       position: absolute;
//       top: 0;
//       right: 10px;
//       width: 40px;
//       height: 40px;
//       border: $border-width $border-type $border-colour;
//       background: #fff;
//       text-align: center;
//       line-height: 40px;
//       &:before {
//       }
//       &:hover {
//         background: $secondary-colour;
//       }
//       &:hover:before {
//       }
//       span {
//         border: 0;
//         clip: rect(0, 0, 0, 0);
//         height: 1px;
//         margin: -1px;
//         overflow: hidden;
//         padding: 0;
//         position: absolute;
//         width: 1px;
//       }
//     }
//   }
// }
// }




/* ============================================ *
 * Swatch attribute
 * ============================================ */
.swatch-attribute {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    position: relative;
    @include media-breakpoint-down(sm) {
        margin-bottom: 16px;
    }
    span {
        padding-top: 10px;
    }
    &.size .swatch-option {
        width: 30px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #ccc;
    }
    .mage-error {
        top: 43px;
        display: block;
        overflow: hidden;
        clear: both;
        width: 100%;
        flex-wrap: wrap;
        position: absolute;
        color: #f54d4d;
    }
}
.swatch-attribute.size {
    .swatch-option {
        width: 30px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #ccc;
    }
}
.swatch-attribute-label {
    font-family: $font_family-primary;
    font-size: 12px;
    position: relative;
    &:after{
        content: ":";
        display: inline-block;
        color: $primary-colour;
        padding-left: 2px;
    }
}

.swatch-attribute-label.required {
    padding-right: 10px;
}

.swatch-attribute-label[data-required="1"]:after {
    content: '*';
    color: red;
    position: absolute;
    right: -11px;
    top: -2px;
    font-weight: bold;
    font-size: 1em;
}

.swatch-attribute-selected-option {
    color: #646464;
    padding: 0 10px;
}

.swatch-attribute-options {
    margin-top: 10px;
    display: flex;
    outline: none;
    a {
        display: inline-block;
        background: #fff;
    }
}

.swatch-option {
    width: 16px;
    height: 16px;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    &:not(:last-child) {
        margin-right: 8px;
    }
}

.swatch-option.selected {
    outline: 2px solid #ff5501;
    border: 1px solid #fff;
    color: #333;
    z-index: 9999;
}

.swatch-option.text.selected {
    background-color: #FFF !important;
}

.clearfix:after {
    content: " "; /* Older browser do not support empty content */
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.swatch-attribute.size .swatch-option.selected, .swatch-attribute.manufacturer .swatch-option.selected {
    color: black;
    background: #fff;
    border: 1px solid #fff;
}
.swatch-option[option-label="White"],.swatch-option[option-label="white"] {
    border: 1px solid #ccc;
}
.swatch-option:not(.disabled):hover {
    outline: 1px solid #999;
    border: 1px solid #fff;
    color: #333;
}

.swatch-option.image:not(.disabled):hover,
.swatch-option.color:not(.disabled):hover {
    outline: 2px solid #ee0000;
    border: 1px solid #fff;
}

.swatch-option.disabled {
    cursor: default;
}

.swatch-option.disabled:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
    background: -moz-linear-gradient(to left top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0) 42%, rgba(255,255,255,1) 43%, rgba(255,255,255,1) 46%, rgba(255,82,22,1) 47%, rgba(255,82,22,1) 53%, rgba(255,255,255,1) 54%, rgba(255,255,255,1) 57%, rgba(255,255,255,0) 58%, rgba(255,255,255,0) 100%);
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(42%,rgba(255,255,255,0)), color-stop(43%,rgba(255,255,255,1)), color-stop(46%,rgba(255,255,255,1)), color-stop(47%,rgba(255,82,22,1)), color-stop(53%,rgba(255,82,22,1)), color-stop(54%,rgba(255,255,255,1)), color-stop(57%,rgba(255,255,255,1)), color-stop(58%,rgba(255,255,255,0)), color-stop(100%,rgba(255,255,255,0)));
    background: -webkit-linear-gradient(to left top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,rgba(255,255,255,1) 43%,rgba(255,255,255,1) 46%,rgba(255,82,22,1) 47%,rgba(255,82,22,1) 53%,rgba(255,255,255,1) 54%,rgba(255,255,255,1) 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
    background: -o-linear-gradient(to left top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,rgba(255,255,255,1) 43%,rgba(255,255,255,1) 46%,rgba(255,82,22,1) 47%,rgba(255,82,22,1) 53%,rgba(255,255,255,1) 54%,rgba(255,255,255,1) 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
    background: -ms-linear-gradient(to left top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,rgba(255,255,255,1) 43%,rgba(255,255,255,1) 46%,rgba(255,82,22,1) 47%,rgba(255,82,22,1) 53%,rgba(255,255,255,1) 54%,rgba(255,255,255,1) 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
    background: linear-gradient(to left top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,rgba(255,255,255,1) 43%,rgba(255,255,255,1) 46%,rgba(255,82,22,1) 47%,rgba(255,82,22,1) 53%,rgba(255,255,255,1) 54%,rgba(255,255,255,1) 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );
}

.swatch-option-tooltip {
    max-width: 140px;
    /*max-height: 160px;*/
    max-height: 100%;
    min-height: 20px;
    min-width: 20px;
    position: absolute;
    padding: 5px;
    background: #fff;
    color: rgb(148, 148, 148);
    border: 1px solid #adadad;
    display: none;
    z-index: 999;
    text-align: center;
}

.swatch-option-tooltip .corner,
.swatch-option-tooltip-layered .corner {
    left: 40%;
    position: absolute;
    bottom: 0;
    height: 8px;
}

.swatch-option-tooltip .corner:after,
.swatch-option-tooltip-layered .corner:after {
    content: '';
    position: relative;
    top: 1px;
    left: -15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7.5px 0 7.5px;
    border-color: #fff transparent transparent transparent;
    font-size: 1px;
}

.swatch-option-tooltip .corner:before,
.swatch-option-tooltip-layered .corner:before {
    content: '';
    position: relative;
    top: 2px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8.5px 0 8.5px;
    border-color: #adadad transparent transparent transparent;
    font-size: 1px;
}

.swatch-option-tooltip .image,
.swatch-option-tooltip-layered .image {
    display: block;
    height: 130px;
    width: 130px;
    /*margin: 0 0 5px;*/
    margin: 0 auto;
}

.swatch-option-tooltip .title {
    max-width: 140px;
    min-height: 20px;
    max-height: 200px;
    color: rgb(40, 40, 40);
    text-align: center;
    display: block;
    overflow: hidden;
}

.swatch-opt {
    margin: 20px 0;
}

.swatch-more {
    display: inline-block;
    margin: 2px 0;
    text-decoration: none !important;
    position: static;
    z-index: 1;
}

/* Layered Features */
.swatch-option-link-layered {
    position: relative;
    margin: 0 !important;
    padding: 0 !important;
}

.swatch-option-link-layered:focus > div {
    box-shadow: 0 0 3px 1px #68a8e0;
}

.swatch-option-tooltip-layered {
    width: 140px;
    position: absolute;
    background: #fff;
    color: rgb(148, 148, 148);
    border: 1px solid #adadad;
    display: none;
    z-index: 999;
    left: -47px;
}

.swatch-visual-tooltip-layered {
    height: 160px;
    top: -170px;
}

.swatch-textual-tooltip-layered {
    height: 30px;
    top: -40px;
}

.swatch-option-link-layered:hover > .swatch-option-tooltip-layered {
    display: block;
}

.swatch-attribute-options .swatch-option-tooltip-layered .title {
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: -5px;
    left: 0;
    color: rgb(40, 40, 40);
    text-align: center;
    margin-bottom: 10px;
}

.swatch-option-disabled:after {
    content: '';
    position: absolute;
    width: 42px;
    height: 2px;
    background: red;
    transform: rotate(-30deg);
    -o-transform: rotate(-30deg);
    -moz-transform: rotate(-30deg);
    -ms-transform: rotate(-30deg);
    -webkit-transform: rotate(-30deg);
    z-index: 995;
    left: -4px;
    top: 10px;
}

.swatch-option-disabled {
    outline: none !important;
    cursor: default;
    border: 0;
}

/* Bugfix for Add To Cart button */
.swatch-opt-listing {
    margin-bottom: 10px;
}

.swatch-option-loading {
    content: url("../images/loader-2.gif");
}

.swatch-input {
    left: -1000px;
    position: absolute;
    visibility: hidden;
}
/* ============================================ *
 * Sku and qty
 * ============================================ */
.stock-details {
    display: flex;
    justify-content: flex-start;
    font-size: 10px;
    text-align: left;
    line-height: 14px;
    //margin-bottom: 15px;
    @include media-breakpoint-up(md) {
        min-height: 28px;
        align-items: center;
    }
    @include media-breakpoint-down(sm) {
        font-size: 10px;
        margin-bottom: 10px;
    }
    .sku {
        padding-right: 10px;
    }
    .qty-number {
        padding:0 10px;
        border-right: 1px solid #d2d2d2;
        border-left: 1px solid #d2d2d2;
    }
    .pack-qty {
    	padding-left: 10px;
    }
}
#block-upsell-heading , #block-related-heading{
    font-size: 32px;
    @include media-breakpoint-down(sm) {
        font-size: 18px;
    }
}
.products.list .slick-track {
   min-width: 100%;
}
.only-one {
    .price-label {
        display: none !important;
    }
    div.price-box {
        margin: 5px 0 15px;
    }
}
