html {
    @include media-breakpoint-down(md) {
        overflow-x: hidden;
        max-width: 100vw;
    }
    outline: none;
}
.page-main {
    @include media-breakpoint-down(sm) {
        padding-top:0;
    }
}
.desk-only {
    @include media-breakpoint-down(sm) {
        display: none !important;
     }
}
.mob-only {
    @include media-breakpoint-up(md) {
        display: none !important; 
    }
}

/* ============================================ *
 * Brands block
 * ============================================ */
.brands {
    padding: 20px 0 10px !important;
} 
.brands-text{
	span {
		margin-bottom: 24px;
		display: block;
		text-align: left;
        font-family:$font_family-primary-bold;

		@include media-breakpoint-down(md) {
			text-align: center;  
		}
	}
}
.brand-image {
    min-height: 1px;
}
.brands {
    .pagebuilder-column-group {
        min-height: 1px;
        @include media-breakpoint-down(sm){
            flex-direction: row;
            flex-wrap: nowrap;
            padding: 0 20px;
        }
    }
    .pagebuilder-column {
        min-height: 1px;
        /*For mobile to reduce space*/
        @include media-breakpoint-down(sm){
            &:first-child , &:last-child{
                display: none !important;
            }
        }
        figure {
            flex-shrink: 0;
        }        
    }
}
.home-product-bg , .home-blog  {
    .pagebuilder-column-group {
        margin: 0 !important;
    }
}
/* ============================================ *
 * About-home block
 * ============================================ */
.about-home-section  {
    .pagebuilder-column-group {
        margin: 0 -25px !important;
    }
}
.home-about {
    align-items: center;
}
/* ============================================ *
 * Home Catalog block
 * ============================================ */
.home-categories {
    @include make-row;
    .home-category {
        margin-bottom: 30px;
        overflow: hidden;
        @include make-col-ready();
        @include make-col(3);
        @include media-breakpoint-down(sm) {
            @include make-col(6);  
        }
        .top-title {
            position: absolute;
            top: 55px;
            left: 32px;
            color: #fff;
            font-style: italic;
            text-align: left;
            @include media-breakpoint-down(sm) {
                top: 25px;
                left: 16px;
                font-size: 10px;
            }            
        }
        &:hover {
            .inner:before {
                opacity: 1;
            }
            .category-content {
                top: 20px;
            }  
            h3 {
                margin: 0;
            }    
            @include media-breakpoint-down(sm) {
                .category-content {
                    top: 50px;
                }                
            }                  
        }      
    }      
    .category-content {
        padding-bottom: 25px;
        position: absolute;
        top: 192px;
        left: 32px;
        height: calc(100% - 20px);
        max-width: 75%;
        text-align: left;
        transition: all ease 0.3s;
        display: flex;
        flex-direction: column;   
        @include media-breakpoint-down(sm) {
            top: 60px;
            left: 15px;
            z-index: 9;
            height: calc(100% - 50px);
        }    
    } 
    &.columns-4 .home-category{
        @include make-col(3);
        
        @include media-breakpoint-down(sm) {
            @include make-col(6);  
        }
    }
    &.columns-3 .home-category{
        @include make-col(4);
        .category-content {
            top: 282px;
            height: calc(100% - 100px);
        }
        &:hover {
            .category-content {
                top: 100px;
            }
        }
    }        
    h3 {
        display: block;
        margin-bottom: 30px;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.88;
        color: #fff;
        @include media-breakpoint-down(sm) {
            font-size: 18px;
            line-height: 26px;
            height: 60px;
            margin-bottom: 0;
        }
    }
    .category-text {
        display: block;
        max-width: 220px;
        font-size: 12px;
        line-height: 1.83;
        color: #fff;
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    .discover-link {
        display: inline-block;
        width: 144px;
        height: 40px;
        line-height: 37px;
        margin-top: auto;
        text-align: center;
        border: solid 1px #ffffff;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        transition: 0.2s ease-in-out;
        &:hover {
            background: #fff;
            color: $primary-colour;
        }
        @include media-breakpoint-down(sm) {
            font-size: 14px;
            width: 120px;            
        }
    }
    .inner {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0,0,0,0.8);
            opacity: 0;
            transition: all ease 0.3s;
        }          
    }     
}
.home-main-slider {
    width: 100%;
    max-width: initial;
    &:not(.slick-initialized) li:not(:first-child) {
        display: none;
    }    
    
}

.home-slider-wrap {
    .pagebuilder-slide-wrapper {
        min-height: auto !important;
        .pagebuilder-overlay.pagebuilder-poster-overlay{
            @include media-breakpoint-down(sm) {
                //min-height:450px !important;
                min-height: auto  !important;
            }
             /*@include media-breakpoint-down(xs) {
                min-height:375px !important;
            }*/
        }
    }
    [data-content-type="slide"] {
        .pagebuilder-slide-wrapper {
              min-height: auto  !important;
        }
    }
    
    div[data-content-type="slider"] {
        .slick-dots {
            @include media-breakpoint-up(md) {
                left: 60px !important;
            }
            @include media-breakpoint-down(md) {
                top: 75%;
                left: auto !important;
                right: 10px; 

                li {
                    margin: 0 1px;
                    &.slick-active {
                        button {
                            &:before {
                                width: 13px;
                                height: 13px;
                                line-height: 13px; 
                            }
                        }
                    }
                    button {
                        &:before {
                            width: 12px;
                            height: 12px;
                            line-height: 12px;
                        }
                    }
                    
                }
            }
        }
    }
    .pagebuilder-overlay  {
        padding: 0 !important;
    }
    img {
        width: 100%;
        object-fit: cover;
    }
    video {
        max-width: 100%;
        object-fit: cover;
       
    }
}
@media (min-width: 1600px) {
	.pagebuilder-slide-wrapper{
		min-height: 1000px !important;
	}
	.slick-dots{
		bottom: 110px !important;
		left: 500px !important;
    }
}
/* ============================================ *
 * Loockbok Slider block
 * ============================================ */
.look-book-container {
    overflow: hidden;
    @include media-breakpoint-down(md) {
        margin:0 0 60px!important;
    }
}

.look-book-container > div {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .slick-dots {  
        bottom: 238px;
        width: initial;
        @include media-breakpoint-down(md) { 
            bottom: -12px;
            left: 0;
            right: 0;
        }
    }
    li {
        button {
        font-size: 0;
        padding:0;

            &:before {
                content: "";
                display: block;
                color: #ffb02f;
                border: 2px solid $primary-colour;
                border-radius: 50%;
                width: 16px;
                height: 16px;
                opacity: 1;
                line-height: 15px;
                padding:0;
                background: transparent;
            }
        }
        &.slick-active {  
            button {
                &:before {
                    content: "";
                    display: block;
                    background: $primary-colour;
                    border: 2px solid transparent;
                    border-radius: 50%;
                    width: 16px;
                    height: 16px;
                    opacity: 1;
                    line-height: 15px;
                }
            }
        }
    }   
}

.lookbook-slider {
    @include make-col-ready();
    display: flex;
    padding: 0 0 0 10px;
    margin-bottom: 30px !important;
    position: static !important;
    
    @include media-breakpoint-up(md) {
        @include make-col(7);
    }
    &:not(.slick-initialized) {
        display: none;
    }
    li {
        @include make-col(4);
        position: relative;

        &.slick-slide {
            padding-right: 32px;
            min-height: 636px;
            outline: none;

            @include media-breakpoint-down(md) {
                padding: 0 20px;
                min-height: 536px;
            }
        }
    }
}

.lookbook-categories {

    li {
        max-width: 100% !important;
        margin: 0;
        padding: 0;
    }

    a {
        text-align: left;
        font-size: 12px;
        font-weight: bold;
        color: #b8b8b8;
        display: block;
        padding:13px 0 0;

        @include media-breakpoint-down(md) {
            padding: 17px 0 0 0;
        }
    }
}

.slick-list.draggable {
    @include media-breakpoint-down(md) {
        padding-bottom: 20px !important;
    }
}

.lookbook-entry {
    text-align: left;

    @include media-breakpoint-down(md) {
        height: auto;
    }

    h2 {
        line-height: 20px;
        text-align: left;
        font-size: 12px;
        color: $primary-colour;
        font-weight: bold;
        padding:7px 0 24px;
        height: 60px;

        @include media-breakpoint-down(sm) {
            padding: 8px 0; 
        }
    }

    img {
        width: 100%;
    }

    .lookbook-link {
        text-align: center;
        font-size: 16px;
        border: 2px solid $secondary-colour;
        width: 144px;
        display: block;
        padding: 8px 10px;
        line-height: 35px;
        opacity: 0;
        border: 2px solid $primary-colour;

        @include media-breakpoint-down(md) {
            opacity: 1;
            padding: 0;
            width: 158px;
            height: 35px;
            line-height: 30px;
        }
        &:hover {
            background:#000;
            color: #fff;
        }
    }

    &:hover {
        .lookbook-link {
            opacity: 1;
            padding: 0;
        }

        img {
            transition: 0.3s;
        }
        .image-container {
             position: relative;
             &:after {
                content: "";
                background:#000;
                top: 0;
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                opacity: 0.3;
            }
        }

        .blog-catalogue {
            display: block;
            position: absolute;
            top: 28%;
            left: 0;
            right: 0;
            width: fit-content;
            margin: 0 auto;
            padding-right: 32px;
            color: #fff;
            @include media-breakpoint-down(md) {
                padding-right: 0;
            }
        }
    }

    .blog-catalogue {
        display: block;
        font-size: 14px;
        font-weight: bold;
        color: $primary-colour;
        text-decoration: underline;
        display: none;
    }
}

.lookbook-excerpt {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;;
    @include font-style(12, 1.67, normal);
    color: $primary-colour;
    text-align: left;
    padding-bottom: 18px;
    display: block;

    @include media-breakpoint-between(md,lg) {
        min-height: 140px; 
    } 

    @include media-breakpoint-down(sm) {
        padding-bottom: 24px; 
        min-height: 130px
    }
}
.right-slider {
    order: -1;
    @include make-col-ready();
    padding: 0 20px;

    @include media-breakpoint-up(md) {
        display: flex;
        order: 9;
        @include make-col(4);
        @include make-col-offset(1); 
    }

    .contanier {
        padding: 23px 0;
        text-align: left;

        @include media-breakpoint-down(md) {
            padding: 32px 0;
        }
    }

    .top {
        display: block;
        font-size: 12px;
        font-family:$font_family-primary-bold;
        color: #b8b8b8;
        text-transform: uppercase;

        @include media-breakpoint-down(md) {
            padding-bottom: 0;
        }
    }

    a {
        font-size: 10px;
        color: #b8b8b8;
        border-bottom: 1px solid #b8b8b8;
        line-height: 13px;
        display: inline-block;
    }

    .media-links {
        display: flex;
        justify-content: flex-start;
        padding:20px 0 55px;

        @include media-breakpoint-down(md) {
            padding: 20px 0;
            margin: 0;
        }
        li{
            &:after {
                display: inline-block;
                @include font-icon('\e938');
                font-size: 10px;
                border-bottom:0;
                padding: 0 10px;
                line-height: 20px;
                color: #b8b8b8;
            }
            &:last-child:after {
                display: none;
            }
        }
    }

    h3 {
        display: block;
        @include font-style(32, 1.41, 1px);
        font-weight: normal;
        padding:0 20% 22px 0;
        text-transform: uppercase;
        font-family: $font_family-primary;

        @include media-breakpoint-down(md) {
            padding: 0 0 13px!important;
            font-size: 24px;
        }
    }

    p {
        @include font-style(16, 1.88, normal);
        font-weight: normal;
        margin-bottom: 35px;
        padding-right: 20%;

        @include media-breakpoint-down(md) {
            margin-bottom: 24px;
            font-size: 12px;
            padding-right:0;
        }
    }

    .view-btn {
        font-weight: bold;
        margin-bottom: 56px;
        width: 93px;

        @include media-breakpoint-down(md) {
            margin-bottom: 0;
            width: 105px;
        }

        span {
            padding: 0;
            width: 144px;
        }
    }
}
   


/* ============================================ *
 * Blog Home block
 * ============================================ */

.top-blog-link {
    color: #b8b8b8;
    display: block;
    font-size: 12px;
    padding: 0 0 20px;
    font-family: $font_family-primary-bold;
    text-transform: uppercase;
}

.blog-home-container {
    min-height: 523px;

    @include media-breakpoint-down(md) {
        background-position: center bottom !important;
        background-size: auto 345px !important;
        margin: 0 14px !important;
    }
}
.banner-section{
    .shop-btn{
        display: block;
        //width: 109px;

        .button-black {
            //width: 100%;
            border: 2px solid #000;
            line-height: 40px;
            transition: all ease 0.3s;
            a {
                padding: 0 20px;
                display: block;
            }            
            span {
                padding:0;
                font-family: $font_family-primary-bold;
                text-align: center;
                font-size: 16px;         
            }
            &:hover {
                background:#000;
                span {
                    color: #fff;
                }
            }
        }
        .button-white {
            //width: 100%;
            border: 2px solid #fff;
            line-height: 40px;
            transition: all ease 0.3s;
            a {
                padding: 0 20px;
                display: block;
            }
            span {
                padding:0;
                font-family: $font_family-primary-bold;
                text-align: center;
                font-size: 16px;  
                color: #fff;       
            }
            &:hover {
                background:#fff;
                span {
                    color: #000;
                }
            }
        }
    }
}

.home-blog {

    a {
       color: #b8b8b8;
        border-bottom: 1px solid #b8b8b8;
        display: inline-block;
        line-height: 13px;
        margin-right: 25px;
        position: relative;
        font-size: 10px;

        &:first-of-type:after{
            @include font-icon('\e938');
            font-size: 10px;
            line-height: 16px;
            position: absolute;
            left: 47px;
            text-decoration:none !important;
        }
    }

    h2 span {
        font-weight: normal;
        font-family: $font_family-primary;
        line-height: 1.41;
        text-transform: uppercase;

        @include media-breakpoint-down(md) { 
            font-size: 24px !important;
        }
    }

    p span {
        padding-bottom: 20px;
        line-height: 30px;
        display: block;

        @include media-breakpoint-down(md) {
            font-size: 12px !important;
            padding-bottom: 7px;
            line-height: 22px;   
        }
    }

    .shop-btn{
        display: block;
        //width: 109px;

        .button {
            //width: 100%;
            border: 2px solid #000;
            line-height: 40px;
            transition: all ease 0.3s;
            span {
                padding:0;
                font-family: $font_family-primary-bold;
                text-align: center;
                font-size: 16px; 
                color: #000;        
            }
            a{
                display: block;
                padding: 10px 20px;
                border-bottom: none;
                margin-right: 0;
                &:first-of-type:after{
                    content: "";
                }
            }
            &:hover {
                background:#000;
                span {
                    color: #fff;
                }
            }
        }
    }

    .text-content {

        @include media-breakpoint-down(md) { 
            padding: 0;
        }

        p {
            display: block;
            padding: 14px 0 42px;
            @include media-breakpoint-down(sm) {
                padding: 13px 0 0;
            }
        }
        /*About sections*/
        &.about{
            p,span{
                padding: 0;
                span{
                    padding-bottom: 0;
                }
            }
        }
        
    }
    /*About sections*/
    .about{
        p,span{
            padding: 0;
            span{
                padding-bottom: 0;
            }
        }
    }

    @include media-breakpoint-down(md) {  
        margin: 0 14px !important;

        .pagebuilder-column.blog-links > div {
            padding-bottom: 18px !important;
        }
    }
}

.home-product-slider {  

    @include media-breakpoint-down(md) {   
        padding: 0 20px !important;
        margin-bottom: 0!important;
    }
    .price-box.price-final_price >a.minimal-price-link span.price-container.price-final_price.tax.weee {
        display: flex;
        flex-direction: row;
    }
    .tooltip{
        display: none;
    }
    .product-item-details .price-box .price {
        font-size: 15px;
    }
    .minimal-price-link .price-label{
        display: none;
    }
    span.price-label {
        font-family: "CeraPro-Regular", Times, serif;
    }
    .product-item-link {
        @include media-breakpoint-up(sm){
            min-height: 38px;
        }
       
    }
    .product-item {
    	padding: 0 7px;	
    }
    span.price-container.price-final_price.tax.weee .price-wrapper{
        display: flex;
        align-items: center;
        &:after{
            content: "each";
            margin-left: 5px;
            font-size: 14px;
        }
    }
    span.old-price {
        display: none;
    }

    .tocart {
        display: none;
    }
    .product-item-link{
        cursor: pointer;
    }

    .product-item {
        overflow: hidden;

        .view-product {
            display: none;
            font-size: 14px;
            font-family: $font_family-primary-bold;
            text-align: center;
            margin: 0 auto;
            border-bottom: 2px solid $primary-colour;
            line-height: 11px;
            position: absolute;
            left: 0;
            right: 0;
            width: 48%;
        }

        &:hover {
            .view-product {
                display: block;
                width: 100%;
                border: 0;
                text-decoration: underline;
            }
        }
    }

    .swatch-attribute{
        display: none !important;  
    }

    .price-final_price {
        margin-top: 0 !important;
    }

    .product-item-photo {
        display: block;
        overflow: hidden;
        margin-bottom: 20px;
        max-height: 180px;
    }

    .product-att {
        padding: 4px 0 9px;
        font-size: 12px;
    }
}

.empty-col {
    @include media-breakpoint-down(sm) {
        display: none !important; 
    }
}


/* ============================================ *
 * Product Slider Home block
 * ============================================ */

.home-product-bg {
    background: #eeeeee;

    @include media-breakpoint-down(md) {
        background: $secondary-colour;
    }

    .product-item-info {
        //min-height: 610px;
    }
}

.left-link {

    @include media-breakpoint-down(xs) {
        padding: 64px 30px 11px !important;
    }

    @include media-breakpoint-between(sm,md) {
        padding: 154px 30px 11px !important;
    } 

    .top-link {
        color: #b8b8b8;
        display: block;
        font-size: 18px;
        padding: 0 0 20px;
        font-family: $font_family-primary-bold;
        text-transform: uppercase;
    }

    a {
        font-size: 12px !important;
        color: #b8b8b8;
        border-bottom: 1px solid #b8b8b8;
        display: inline-block;
        line-height: 13px;
        margin-right: 20px;
        position: relative;
        font-size: 10px;

        &:after {
            @include font-icon('\e938');
            font-size: 10px;
            line-height: 16px;
            position: absolute;
            right: -18px;
            text-decoration: none !important;
        }

        &:last-of-type:after{
            display: none;
        }
    }
}
.stock-items {

    @include media-breakpoint-down(md) {
        padding: 5px 30px !important;
        background: #eee;
        margin-top: 4px;
    }

    p {

        span {
            line-height: 1.56;
            letter-spacing: normal;   
            font-weight: normal !important;
            text-transform: uppercase;

            @include media-breakpoint-down(md) {
                font-size: 24px !important;
            }
        }

        @include media-breakpoint-down(md) {
            display: flex;
        }

        @include media-breakpoint-between(sm,md){
            display: inline-block !important;
        }
    }

}

.pagebuilder-product-slider.product-home-slider {

    @include media-breakpoint-down(md) {
        background: #eee;
        padding: 15px 20px 130px;
    }
    .not-loggedin{
        // pointer-events: none;
    }

    .actions-primary .qty-block {
        width: 34%;
    }
}
.cms-index-index{
    .stock-details .qty-number{
        border-right: none;
    }
    .modal-content{
        span {
            font-size: 22px;
            display: block;
        }
    }
    @media (max-width: 1199.98px){
        .slick-dots{top: 80% !important;}
    }

}



/* ============================================ *
 * Home Bottom Home block
 * ============================================ */

.home-bottom {

    a{
        color: #b8b8b8;
        border-bottom: 2px solid #b8b8b8;
        display: inline-block;
        line-height: 11px;
        margin-right: 22px;
        position:relative;

        &:after {
            position: absolute;
            right: -16px;
            top: 2px;
            @include font-icon('\e938');
            display: inline-block;
            font-size: 10px;
            padding-left: 8px;
            color: #b8b8b8;
        }
    }

    .text-about {

        h3 {
            font-family: $font_family-secondary;
            font-weight: normal !important;
            padding-bottom: 10px;

            span {
                font-family: $font_family-secondary;
                font-weight: normal !important;
            }
        }

        p {
            font-family: $font_family-primary;
            display: block;
            padding-bottom: 15px;

            @include media-breakpoint-down(md) {
                font-size: 24px !important
            }

            span {
                font-family: $font_family-primary;
            }
        }
    }

    .top-slider-link {

        @include media-breakpoint-down(md) {
            display: flex !important;
        }
    }

    .last-btn {
        display: block;

        .pagebuilder-button-primary {
            font-family: $font_family-primary-bold;
            font-size: 16px;
            color: $primary-colour;
            border: 2px solid $primary-colour;
            height: 40px;
            line-height: 36px;
            width: 146px;
            text-align: center !important;
        }
    }
}

/* ============================================ *
 * Wordpress Home block
 * ============================================ */

.download-btn{
    display: block;
    text-align: center;
    max-width: 145px;
    height: 40px;
    color: #000;
    padding: 10px 0px;
    margin-top: 20px;
    text-decoration: underline;

    &:hover {
        text-decoration:none;
        border: 2px solid #000;
    }
}

.custom-right-slider{

    .pagebuilder-collage-content{
        margin-top: 0;
        padding-right: 65px;
        background: transparent;

        span {
            padding-bottom: 0;
            color: #b8b8b8;
        }
    }

    .pagebuilder-overlay {
        padding: 0;
    }

    .pagebuilder-slide-wrapper {
        padding: 0 40px!important;
    }

    .pagebuilder-slide-button {
        color: $primary-colour;
        border: 1px solid $primary-colour;
    }

    .slick-dots {
        bottom: 52px !important;
        right: 29% !important;

        li {

            button {

                &:before {
                    color: $primary-colour !important;
                    border: 2px solid $primary-colour !important;
                }
            }

            &.slick-active {  
                button {

                    &:before {
                        background: $primary-colour !important;      
                    }
                }
            }
        }
    }
}

/* ============================================ *
 * Pagebuilder Product Slider Home block
 * ============================================ */

.pagebuilder-product-slider {

    .product-items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 0 !important;
    }

    li.product-item {
        @include make-col-ready();
        @include make-col(4);
    }
}

/* ============================================ *
 * About Home Bottom Home block
 * ============================================ */

.about-home-section {

    @include media-breakpoint-down(md) {
        margin: 48px 0 75px !important;
    }
}

.about-home {
    @include make-row();
    display: flex;   

    @include media-breakpoint-down(md) {
        margin: 0;
    }
    @include media-breakpoint-down(sm) {     
        &:not(.slick-initialized) {
            display: none;      
        }
    }

    .item {
        @include make-col(4);
        @include make-col-ready();
        padding: 15px;

        img {
            width: 349px;
            height: 400px;
            margin-bottom: 26px;
            object-fit: cover;

            @include media-breakpoint-down(md) {
                margin-bottom: 0;
                max-height: 200px;
            }
        }

        h3 {
            @include media-breakpoint-up(md) {
                height: 90px;
                overflow: hidden;
            }
        }

        h3 a {
            font-family: $font_family-secondary;
            font-size: 32px;
            text-align: left;
            border: 0;
            font-weight: normal;
            font-style: italic;
            line-height: 1.41;
            display: block;
            padding-bottom: 16px;
            color: $primary-colour;

            &:after{
                display: none;
            }

            @include media-breakpoint-down(md) {
                font-size: 18px;
                padding-bottom: 10px;
            }

            @include media-breakpoint-between(sm,md){
                min-height: 60px;
            }
        }
        @include media-breakpoint-down(sm) {
            margin-bottom: 60px;
        }
    }

    .top-slider-link {
        display: flex;
        flex-wrap: wrap;

        span {
            font-size: 16px;
        }

        li {
            position:static;
            padding: 0 4px 14px 0;
            height: auto;
            margin: 0;

            @include media-breakpoint-down(md) {
                height: 33px;
            }

            &:after {
                display: none;
            }

            &:last-child{
                a:after {
                    display: none;
                }
            }
        }

        a {
            margin-right: 20px;
            margin-bottom: 0;
            text-transform: capitalize;
            font-family: $font_family-primary-bold;
            font-size: 10px;
            @include media-breakpoint-down(md) {
                margin: 12px 20px 12px 0;
            }
        }
    }

    .post-excerpt {
        font-family: $font_family-primary;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.88;
        display: block;
        color: $primary-colour;
        text-align: left;
        min-height: 130px;

        @include media-breakpoint-down(md) {
            font-size: 12px;
        }
    }

    .post-link {
        font-family: $font_family-primary_bold;
        text-align: left;
        display: block;
        margin-top: 33px;
        width: 146px;
        @include media-breakpoint-down(md) {
            margin-top: 16px;
        }

        &:after{
            display: none;
        }

        span {
            padding:0;
            text-align: center;

            @include media-breakpoint-down(sm) {
                width: 157px;
                height: 35px;
                line-height: 30px;
                max-width: 100%;
            }
        }
    }

    .pagebuilder-column-group {
        @include make-row();
    }
}

.about-home-block {
    //@include make-col(8);
    max-width: 66.66%;
    margin: 0 auto !important;
    text-align: center;

    @include media-breakpoint-down(sm) {
        @include make-col(10);
        display: block;
        margin-top: 24px !important;
        padding-bottom: 0;
    }

    h3 {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 32px;
        padding-bottom: 4px;
        font-family: $font_family-primary;
        font-weight: normal !important;

        @include media-breakpoint-down(md) {
            font-size: 24px !important;
            padding-bottom: 9px;
        }
    }

    p {
        font-family: $font_family-primary-bold;
        font-size: 24px;
        line-height: 1.88;

        @include media-breakpoint-down(md) {
            font-size: 16px !important;
            font-family: $font_family-primary-bold;
        }
    }
}

.about-title {
    line-height: 48px;
    font-weight: normal;
    font-family: $font_family-primary;
    font-size: 32px;

    @include media-breakpoint-down(md) {
        font-size: 24px !important;
    }
}

.about-subtitle {   
    line-height: 45px;
    font-size: 22px;
    font-weight: bold;

    @include media-breakpoint-down(md) {
        font-size: 16px !important;
        line-height: 30px;
    }
}

.cms-index-index{
    .pagebuilder-slide-wrapper {
        //height: calc(100vh - 94px);
    }
    .pagebuilder-slide-wrapper .pagebuilder-slide-button{
       
    }
 
    
    .pagebuilder-collage-content{
        bottom:auto;
    }
    .widget-product-grid .slick-dots {
        bottom: -5px;
        left: 0;

        @include media-breakpoint-between(sm,md){
            bottom: 145px;
            left: 25px;
            right: inherit;
        }

        @include media-breakpoint-down(sm) {
            bottom: 50px;
        }
    }

    .product-att {
        padding-bottom:12px;
    }

    .product-items {
        &:not(.slick-initialized) {
            display: none;
        }
        @include media-breakpoint-down(sm) {
            margin: 10px 0 0;   
        }
    }

    .tocart {
        @include media-breakpoint-down(sm) {

            span {
                text-align: center;
                margin: 0 auto;
                width: 122px !important;
                padding: 0; 
            }
        }

        @include media-breakpoint-between(md,lg){
            padding-left: 14px !important;
        }
    }

    .actions-primary {

        .tocart {
            
            @include media-breakpoint-down(sm) {
                padding-left: 10px;
            }
        }
    }
}
.boxes-section{
    @include media-breakpoint-up(sm){

        div[data-content-type="text"] {
            height: 50px;
            span, strong ,p{
                font-size: 12px;
            }
        }
        
    }
    
}   
/****Spacing *******/
@include media-breakpoint-down(sm){
  
    .cms-index-index{

        .home-slider-wrap {
            margin-bottom: 0 !important;
        }

        .brands {
            padding: 0 !important;
        }

        .brand-image {
            margin-bottom: 0px !important;
        }
        
        .title-mob {
            margin-top: 20px !important;
        }

        .slick-dotted.slick-slider {
            margin-bottom: 0 !important;
        }

        .home-product-slider .product-item {
            min-height: 370px;
        }

        .boxes-section .pagebuilder-column{
            padding: 0;
        }

            



        [data-content-type='row'][data-appearance='full-width'].home-blog {
            padding: 20px 0 !important;
        }
        .home-blog .pagebuilder-column{
            padding-bottom: 0 !important;
        }
        .colored-section {
            padding: 20px 0 0 !important;
        }       





    }
}