/* ============================================ *
 * QTY input
 * ============================================ */
.quantity-wrap {
	position: relative;
	text-align: left;
	display: flex;
	justify-content: flex-end;

	#qty-error {
		order: 9;
		align-self: center;
		margin-left: 10px;
	}

	.qty-controler {
		width: 32px;
		height: 32px;
		line-height: 34px;
		display: block;
		margin: auto 0;
		text-align: center;
		border: solid 1px #ededed;
		cursor: pointer;
		font-size: 18px;
		background: #ededed;

		&:after {
			display: inline-block;
		}
	}

	.plus {
		border-left: 0;
		line-height: 28px;

		&:after {
			content: "+";
		}
	}

	.minus {
		border-right: 0;
		font-size: 27px;
		line-height: 27px;

		&:after {
			content: "-";
		}
	}

	input {
		width: 36px !important;
		height: 32px;
		line-height: 20px;
		border: solid 1px #ededed;
		padding: 0;
		-webkit-appearance: textfield;
		-moz-appearance: textfield;
		appearance: textfield;

		&.mage-error {
			border-color: #f00;
		}
	}

	.label {
		margin-right: 15px;
	}

	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
	}
}

/* ============================================ *
 * Product Page
 * ============================================ */




.product-view {

	.magnifier-preview {
		top: 0 !important;
		left: 540px !important;
		border: 1px  solid #bbbbbb !important;
	}

	.page-title-wrapper {
		display: block;
		order: -3;
		h1 {
			@include font-style(32, 1.25, 1px);
			color: $primary-colour;
			font-family: $font_family-primary;
			display: block;
			padding-bottom: 24px;
			text-transform: uppercase;
			margin-right: 30px;

			@include media-breakpoint-down(md) {
				font-size: 18px;
				font-weight: normal;
				margin-right: 10px;
				padding: 15px 0  0;
			}
		}
	}
	.product-price {
		order: -1;
		.price-wrapper {
			.price{
				font-family: $font_family-primary-bold;
				font-size: 18px !important;
			}
			&:after{
				font-family: $font_family-primary-bold;
				@include font-size(18);
			}
		}
	}

	.column {
		@include make-container();
		min-height: inherit !important;
	}

	.product-wrapper-top {
		@include make-row();
	}

	.other-details {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		align-items: center;
		text-align: left;
		margin-right: auto;
		@include font-style(12, $font_line-height-default, $font_letter-spacing);
		font-family: $font_family-primary-bold;
		color: #b8b8b8;
		padding-right: 30px;

		@include media-breakpoint-down(md) {
			padding: 15px 0 0;
			flex-wrap: nowrap;
			justify-content: space-between;
			> * {
					padding: 0;
			}
		}

	}

	.sku {
		width: auto;
		border: 0;
		padding-right: 34px;

	}

	.actions-primary form {
		display: flex;
		align-items: center;
	}

	.qty-number {
		width: auto;
		padding: 0 40px 0 0;

	}
	span.buyfor {
		display: flex;
		flex-direction: column;
		@include font-size(18);
		span.price-container.price-tier_price.tax.weee{
			font-family: $font_family-primary-bold;
			font-size: 18px;
			.price:after {
				content: "each";
				margin-left: 3px;
				@include font-size(18);
			}
		}
	}
	.product-prices-holder {
		display: flex;
		align-items: flex-start;
	}
	.special-price{
		.price-label {
			font-size: 15px;
			padding-bottom: 11px;
		  }
	  }
	.prices-tier {
		color: #000;
		font-size: 24px;
		font-weight: normal;
		margin-bottom: 10px;
		order: -2;
		margin-right: 25px;

		+ .product-price {
			.price-label {
				height: auto;
				position: relative;
				width: auto;
				font-size: 15px;
				padding-bottom: 11px;
			}
			.price {
				font-size: 18px;
				font-weight: normal;
			}
		}
		.benefit {
			color: green;
		}
	}
	.pack-qty {
		width: auto;
		padding-right: 30px;

		@include media-breakpoint-down(md) {
			padding-right: 0;
		}
	}

	// .product-row-top {
	//   @include make-row();
	// }
	.swatch-option {
		min-width: 24px;
		height: 24px;
	}

	.swatch-attribute.color {
		&:before {
			font-size: 12px;
			padding-right: 10px;
			line-height: 34px;
			display: none;
		}
	}

	.product-info-main {
		order: 2;
		@include make-col-ready;
		//padding: 0 !important;
		text-align: left;
		display: flex;
		flex-flow: column;

		@include media-breakpoint-up(sm) {
			@include make-col(6);
		}

		.product-header {
			@include media-breakpoint-down(md) {
				//padding: 0 14px;
				display: block;
			}
		}
	}

	.media {
		order: 1;
		@include make-col-ready;

		@include media-breakpoint-up(sm) {
			@include make-col(6);
			margin-bottom: 30px;
		}

		.action-skip-wrapper {
			@include visuallyhidden;
		}
	}

	.info {
		order: 4;
		@include make-col-ready;
		margin-bottom: $margin*3;
	}

	.product-att {
		display: block;
		padding: 25px 30% 25px 0;
		@include font-style(16, 1.88, normal);
		border-bottom: 2px solid #ededed;

		@include media-breakpoint-down(md) {
			padding: 10px 14px;
			border-top: 2px solid #ededed;
		}
	}

	.product-addto-links {
		display: flex;
	}

	.block-requisition-list {
		margin-left: 15px;
	}

	.actions {
		.tocart {
			width: 50%;
			margin-right: 15px;

			span {
				padding: 0;
			}
		}
	}

	.split.button.wishlist.active .items {
		top: 44px;
	}

	button.label.action.split {
		margin-right: 15px;

		span {
			font-size: 14px;
		}
	}

	.split.button span {
		&:after {
			display: none;
		}
	}

	.split.button.wishlist {
		display: flex;

	}

	.requisition-list-action .items .list-items {
		width: 216px;
	}

	.block.block-requisition-list.social-button {
		width: 100%;
		display: block;
		clear: both;
		margin: 20px 0;
	}

	.login-price {
		@include media-breakpoint-down(md) {
			width: 50%;

			.tocart {
				width: 100% !important;
				margin: 0 !important;
			}
		}
	}

	.swatch-opt {
		@include media-breakpoint-down(md) {
			display: block;
			padding: 0 14px;
		}
	}

	.swatch-attribute-label {}

	.product-info-price {
		.price-box {
			.price {
				font-family: $font_family-primary-bold;
				@include font-style(16, normal, normal);
				color: $primary-colour;
			}
		}
	}


	.product-reviews-summary {
		display: block;
		margin-bottom: $margin;
	}

	.product-info-stock-sku {
		margin-top: 30px;
		@include media-breakpoint-down(md) {
			margin-top: 0;
		}
		.sku {
			font-size: $font-sm;

			strong {
				display: inline-block;
				font-weight: normal;

				&:after {
					content: ":";
				}
			}

			div {
				display: inline-block;
			}
		}

		.stock {
			display: flex;
			margin-top: 1px;
		}
	}

	.product {
		&.overview {}
	}

	.field.qty {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		@include media-breakpoint-down(md) {
			width: 45%;
		}

		label {
			padding-right: 12px;
			margin: 0;
			width: auto;
		}

		input {
			font-family: $font_family-primary-bold;
		}
	}

	.material-att {
		display: flex;
		justify-content: flex-start;

		@include media-breakpoint-down(md) {
			//width: 55%;
			//padding: 0 15px;
			margin-top: 15px;
		}

		span {
			&:not(:first-child){
				padding-left: 32px;
			}
			@include font-style(12, 1.67, normal);
		}

		p {
			font-family: $font_family-primary-bold;
			padding-left: 5px;
		}
	}

	.box-tocart {
		.fieldset {
			@include media-breakpoint-down(md) {
				padding: 0;
			}
		}
	}
	.actions.alert  {
		button {
			margin: 0 0 15px;
		}
	}
	.actions {
		display: flex;
		justify-content: flex-start;
		margin-top: 25px;
		width: 100%;
		span {
			font-size: 16px;


			&:hover{
				a{
					color: #fff;
				}
			}
		}
		.action.tocart {
			span {
				@include media-breakpoint-down(md) {
					font-size: 14px;
					color: $secondary-colour;
					background: $primary-colour;
				}
			}

		}

		.product-social-links {
			@include media-breakpoint-down(md) {
				@include make-col-ready;
				@include make-col(6);
				margin: 0 15px 10px 0;
				padding: 0;
			}
		}
		.tocart{
			@include media-breakpoint-down(md) {
				position: relative;
				width: 100%;
				margin: 0 10px 10px 0;
			}
		}
		&.wishlist-added {
			@include media-breakpoint-down(md) {
				flex-direction: column;
				.tocart{
					margin: 0 0 10px 0;
				}
			}
		}
		.block-requisition-list {
			@include media-breakpoint-down(md) {
				margin: auto;
			}
		}
	}

	.towishlist {
		position: static;
		line-height: 36px;
		width: 100%;

		span {
			font-size: 16px;
			border-bottom: 2px solid $primary-colour;
			line-height: 15px;

			@include media-breakpoint-down(md) {
				font-size: 14px;
				color: $primary-colour;
				background: $secondary-colour;
				text-decoration: none;
				display: block;
				border: 2px solid $primary-colour;
				height: 40px;
				line-height: 37px;
				text-align: center;
			}
		}

		&:after {
			display: none;
		}
	}

	.product-note {
		font-family: $font_family-primary;
		@include font-style(12, normal, normal);
		color: #b8b8b8;
		display: block;
		margin: 25px 0;

		@include media-breakpoint-down(md) {
			padding: 0 14px;
		}
	}

	.block.related {
		margin: 0 0 33px 0;
	}
	.fotorama__wrap--css3 .fotorama__nav{
		width: 485px !important;
	}
	.fotorama__stage {
		@include media-breakpoint-up(md) {
			margin-bottom: 60px;
		}
	}
	.fotorama__arr {
	    display: block !important;
	}
	.fotorama__th__arr {
		background: transparent;
		color: #000;

	}
	.fotorama__arr--disabled {
		@include visuallyhidden;
	}

	.fotorama__nav__frame {
		padding: 0 20px;
	}

	.fotorama__thumb__arr--left {
		.fotorama__thumb--icon {}
	}

	.fotorama__arr--prev {
		.fotorama__arr__arr {
			background: none;
			@include font-icon-before('\e937');

			&:hover {
				@include font-icon-before('\e936');
			}

			font-size: 30px;
		}
	}

	.fotorama__arr--next {
		.fotorama__arr__arr {
			background: none;
			@include font-icon-before('\e939');

			&:hover {
				@include font-icon-before('\e938');
			}

			font-size: 30px;
		}
	}

	.fotorama__thumb__arr--left {
		.fotorama__thumb--icon {
			background: none;
			@include font-icon-before('\e936');
			font-size: 20px;
			padding-top: 30px;
			display: block;
		}
	}

	.fotorama__thumb__arr--right {
		.fotorama__thumb--icon {
			background: none;
			@include font-icon-before('\e938');
			font-size: 20px;
			padding-top: 30px;
			display: block;
		}
	}

}

.fotorama {
	z-index: 999999 !important;
}

.fotorama__zoom-in.fotorama__zoom-in--disabled,
.fotorama__zoom-out.fotorama__zoom-out--disabled {
	display: block !important;
}

.product-add-form {
	.box-tocart {
		padding-top: 20px;

		@include media-breakpoint-down(md) {
			padding: 15px 0;
		}
	}
}

.not-login {
	font-size: 14px;

	a {
		text-decoration: underline;
	}
}


/* ============================================ *
 * Magento Swatches
 * ============================================ */
.swatch-opt {
	margin: 0 !important;
	padding: 10px 0 16px;
	border-bottom: $border-width $border-type $border-light-colour;
}

.swatch-attribute {

	/*&:first-child {
    margin-bottom: $margin;
  }*/
	.swatch-attribute-label {}

	.swatch-attribute-selected-option {}

	.swatch-option {

		&.selected {
			background-color: $secondary-colour;
			outline: 2px solid $primary-colour;
		}

		&.text {
			text-align: center;
			line-height: 22px;
			min-width: 34px;
			height: 34px;
			padding: $padding/3;
		}
	}

	.swatch-attribute {
		&.size {
			.swatch-option {
				background-color: lighten(#000, 94%) !important;
			}
		}
	}
}

/* ============================================ *
 * Related Swatches
 * ============================================ */
.related-swatches {
	overflow: hidden;
	padding: $padding 0;
	border-bottom: $border-width $border-type $border-light-colour;

	.swatch-attribute {
		overflow: hidden;
		margin-bottom: 0;
	}

	li {
		text-align: center;
		line-height: 22px;
		width: 34px;
		height: 34px;
		padding: $padding/3;

		img {
			max-width: 100%;
		}
	}
}

/* ============================================ *
 * Social Links
 * ============================================ */
/*.product-social-links {
  padding: $padding 0;
  overflow: hidden;
}*/
/* ============================================ *
 * product-tabs
 * ============================================ */
.product.detailed {
	display: block;
	margin: 30px 0;
	padding: 0;

	@include media-breakpoint-down(md) {
		margin: 10px 0 30px;
		//width: 92%;
	}
}

.data.content {
	display: none;
	padding: 20px 0;
}

.data.table {
	.col.label {
		padding: 0 10px !important;
		line-height: 40px;
		text-align: left;
		font-family: $font_family-primary-bold;

	}

	&.technical {
		.col.label {
			max-width: 100%;
		}
		.color-val {
			width: 24px;
    		height: 24px;
		}
	}
}

.data.title {
	cursor: pointer;
	display: block;
	width: 100%;
	padding: 25px 0;
	border-bottom: 2px solid #ededed;
	color: $primary-colour;
	font-family: $font_family-primary;
	position: relative;
	outline: none;
	@include font-style(16, normal, normal);

	&:first-child {
		border-top: 2px solid #ededed;
	}

	&:after {
		@include font-icon('\e934');
		font-size: 16px;
		color: $primary-colour;
		position: absolute;
		right: 14px;
		top: 32px;
	}
}

/* ============================================ *
 * Product Image and gallery
 * ============================================ */
.fotorama__thumb-border {
	border: $border-width $border-type $border-light-colour !important;
}

.fotorama__stage__frame {
	.fotorama__img {
		top: 0;
		right: 0;
		left: 0;
		margin: 0 auto;
		transform: none;
		-webkit-transform: none;
		-moz-transform: none;
		-o-transform: none;
	}
}


/* ============================================ *
 * Product bottom tabs
 * ============================================ */
.product {

	&.data {
		&.items {

			>.item.title {

				// title
				>.switch {


					.active,
					&:active {
						>.switch {}

					}
				}
			}

			// tab content
			>.item.content {

				.active {}
			}

		}
	}

}

/* ============================================ *
 * Upsell & Related Products list
 * ============================================ */
.upsell,
.related {
	.block-title {
		font-family: $font_family-primary-bold;
		@include font-style(32, 1.88, normal);
		text-align: left;
		display: block;
		margin-bottom: 15px;
		position: relative;

		strong {
			font-weight: normal;
		}

		@include media-breakpoint-down(md) {
			h2 {
				font-size: 18px;
				font-weight: normal;
			}
		}
	}
}

.upsell {
	.block-title {
		display: flex;
		justify-content: flex-start;
	}

	.slick-dots {
		position: absolute;
		margin-top: auto;
		align-self: flex-end;
		text-align: right;
		height: 20px;
		right: 0;
		width: 50%;
		bottom: -2px;

		@include media-breakpoint-down(md) {}

		button {
			display: inline-block;

			&:before {
				content: "";
				display: block;
				border: 2px solid #000;
				border-radius: 50%;
				width: 16px;
				height: 16px;
				line-height: 15px;
				opacity: 1 !important;
			}
		}

		.slick-active button:before {
			background: #000;
		}
	}
}

.products-upsell,
.products-related {
	.actions-secondary {
		position: absolute;
		color: $primary-colour;
		font-size: 16px;
		width: 20px;
		height: 20px;
		top: 0px;
		right: 17px;
		z-index: 999;
		height: auto;

		.towishlist {
			top: 15px;
			line-height: 5px;
		}
	}

	.product-item-link {
		height: 68px;
	}
}

/* ================Upsell=============== */
.block.upsell {
	margin-bottom: 130px;

	@include media-breakpoint-up(sm) {
		margin-bottom: 77px;
	}

	.slick-prev {
		transform: rotate(90deg);
		height: initial;
		width: initial;

		@include media-breakpoint-up(md) {
			left: -60px;
		}

		&:before {
			@include font-icon('\e911');
			font-size: 12px;
			color: #000;
		}
	}

	.slick-next {
		transform: rotate(-90deg);
		height: initial;
		width: initial;

		@include media-breakpoint-up(md) {
			right: -60px;
		}

		&:before {
			@include font-icon('\e911');
			font-size: 12px;
			color: #000;
		}
	}
}

/* ================Related=============== */
.block.related {
	background: #eee;
	margin: 40px 0;
	padding: 60px 0;

	@include media-breakpoint-down(md) {
		padding: 30px 0;
	}

	.product-item-link {
		padding: 0;
		min-height: 30px;
		height: 100%;
	}

	.product-item {
		.product-image-photo {
			top: 28px;
		}
	}

	.container {
		display: flex;

		@include media-breakpoint-down(md) {
			display: block;
		}
	}

	.block-title {
		@include media-breakpoint-up(md) {
			@include make-col(3);
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			position: relative;
		}

		@include media-breakpoint-down(md) {
			width: 100%;
			max-height: 60px;
			@include make-col-ready();
			display: flex;
			justify-content: flex-start;
			margin-bottom: 0;

			h2 {
				font-size: 18px;
			}

			.slick-dots {
				@include media-breakpoint-down(md) {
					position: static;
				}
			}
		}
	}

	.block-content {
		@include make-col(9);

		@include media-breakpoint-down(md) {
			max-width: 100%;
			display: block;
		}
	}

	.product-item-info {
		background: #fff;
		min-height: 470px;
	}

	.product-item-details {
		padding: 0 20px;
		overflow: hidden;
	}

	.slick-dotted.slick-slider {
		margin: 0;
	}

	.slick-dots {
		position: absolute;
		margin-top: auto;
		align-self: flex-end;
		text-align: left;
		height: 20px;
		left: 0;
		bottom: 10px;

		@include media-breakpoint-down(md) {}

		button {
			display: inline-block;

			&:before {
				content: "";
				display: block;
				border: 2px solid #000;
				border-radius: 50%;
				width: 16px;
				height: 16px;
				line-height: 15px;
				opacity: 1 !important;
			}
		}

		.slick-active button:before {
			background: #000;
		}
	}
}

.catalog-product-view {
	.add-to-quote {
		margin-left: 30px;
	}

	.product-add-form .box-tocart {
		display: flex;
		flex-wrap: wrap;

		.fieldset {
			display: flex;
			width: 100%;
			flex-wrap: wrap;
		}

		.towishlist {
			display: inline-block;
			width: auto;
			cursor: pointer;
			padding: 0 20px;
			white-space: nowrap;
			text-align: center;
			text-transform: none;
			color: #000;
			font-size: 16px;
			height: 42px;
			line-height: 36px;
			border: 2px solid #000;
			background: transparent;
			transition: all ease 0.3s;
			&.added {
				color: #fff;
				background: #000;
				@include media-breakpoint-down(md) {
					width: 100%;
				}
				&:hover {
					color: #000;
					background: #fff;
				}				
			}

			&:hover {
				color: #fff;
				background: #000;
			}

			&:before {
				@include font-icon('\e92c');
				font-size: 16px;
				display: inline-block;
				vertical-align: middle;
				margin: -1px 5px 0;
			}
		}
	}
}
/******TOOLTIP*********/
.catalog-product-view{
	.special-price{
		position: relative;
	}
	.tooltip {
		display: flex !important;
		position: relative;
		display: inline-block;
		border: 1px solid black;
		border-radius: 34px;
		width: 15px;
		height: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 3px;
		right: -2px;
		position: absolute;
		cursor: pointer;
	}

	.tooltip .tooltiptext {
	  visibility: hidden;
	  width: 150px;
	background-color: #e3e3e3;
	color: #000;
	text-align: center;
	border-radius: 3px;
	padding: 5px 0;

	  /* Position the tooltip */
	  position: absolute;
	  z-index: 1;
	  top: -5px;
	  left: 140%;
	}

	.tooltip:hover .tooltiptext {
	  visibility: visible;
	}
}
