/* ============================================ *
 * Margins & Paddings
 * ============================================ */

$padding: 15px;
$margin: 15px;


/* ============================================ *
 * Grid
 * ============================================ */

// what is this for ?????
$screen-xs-min: 576px;
$screen-sm-min: 768px;
$screen-md-min : 992px;
$screen-lg-min : 1200px;
$screen-xl-min : 1440px;

// Containers max width
$max-widths-xs-min: 540px;
$max-widths-sm-min: 720px;
$max-widths-md-min : 960px;
$max-widths-lg-min : 1110px; // Containers max-width


$grid-columns : 12;
$grid-gutter-width : $padding * 2; //

/* ============================================ *
 * Typography
 * ============================================ */
//general settings
// $font-family: "Helvetica", Times, serif;
// $font-family-secondary: "Times New Roman", Times, serif;
// $line-height: 1.4;
$font-xs: 10px;
$font-sm: 12px;
$font-md: 14px;
$font-lg: 18px;
$font-xl: 24px;

//paragraph size
//$font-size: 14px;

//heading sizes desktop
// $h1-d-size: 55px;
// $h2-d-size: 45px;
// $h3-d-size: 35px;
// $h4-d-size: 25px;
// $h5-d-size: 18px;
// $h6-d-size: 14px;

//heading sizes mobile
// $h1-m-size: 24px;
// $h2-m-size: 24px;
// $h3-m-size: 24px;
// $h4-m-size: 18px;
// $h5-m-size: 14px;
// $h6-m-size: 12px;



//price size // remove
$price-size: 20px;
$old-price-size: 14px;

//label size
$label-size: 12px;

//input, select, textarea size
$input-size: 12px;
$select-size: 20px;
$textarea-size: 12px;

/* ============================================ *
 * Colours 
 * ============================================ */
$primary-colour: #000; // dont use
$secondary-colour: #fff; // dont use


$colour_primary: #000; // used on body font
$colour_secondary: #9b9b9b;

$border_colour-default: $colour_secondary;



// $font-colour-light: $primary-colour;

//body background-colour and selection color
$body_background-colour: #fff;
$body_selection-colour: #f00;

//footer colors
// $footer-background-colour: #000;
// $footer-text-colour: #fff;
// $footer-link-colour: #fff;

//header colors
$header-background-colour: #fff;
$header-text-colour: #000;
$header-link-colour: #000;



// Links
//$link-font: $link_font-default;
$link_color: #000;
$link_hover-color: #000;
$link_hover-text-decoration: none;
$link_active-color: #000;
$link_visited-color: #000;
$link_text-decoration: none;

// Border
$border-width: 1px;              
$border-type: solid;               
$border-colour: #000;              
$border-light-colour: #fff;

//sidebar
$sidebar-bk:#eee;

/* ============================================ *
 * Buttons
 * ============================================ */

// General
$button-height : 42px;
$button-padding : 20px; // padding left and right
$button-color: #000;
$button-background: transparent;
$button-font-size: 16px;
$button-line-height : 36px; // button Height
$button-text-transform : none; // uppercase/lowercase

$button-border-size: 2px;
$button-border-color: #000; 

// Button Sizes
$button-small-font-size: $button-font-size;
$button-small-max-width: 135px;

$button-medium-font-size: $button-font-size;
$button-medium-max-width: 230px;

$button-large-font-size: $button-font-size;
$button-large-max-width: 275px;

// Buttons Hover
$button-hover-color: #fff;
$button-hover-background: #000;
$button-hover-border-color: #000;

// Buttons Primary (CTA) EX: "add to cart" - "got to checkout"
$button-primary-colour: #fff;
$button-primary-background: #000;

// Disabled Buttons
$button-disabled-background: $button-background;
$button-disabled-border-color: $button-background;
$button-disabled-font-color: $button-color;
/*******************************/




