/* ============================================ *
* Variables 
* ============================================ */

// Global

$footer_newsletter-padding-top: 50px;
$footer_newsletter-padding-bottom: 50px;

$footer_newsletter-background-colour: #f6f6f6;
$footer_newsletter-background-imge: none;

// Global Font
$footer_newsletter-font-size: 20px;
$footer_newsletter-font-color: #000;
$colour_secondary: #fff;

// Titles
// use H2 or any other header @include heading-2; 
// $footer_newsletter-title-font-size: 40px;
// $footer_newsletter-title-font-size-MOBILE: 20px;


$footer_newsletter-title-font-color: #000;
$footer_newsletter-subtitle-font-family: "Helvetica", Times, serif; // use from variables.scss
$footer_newsletter-subtitle-font-size: 15px;
$footer_newsletter-subtitle-font-color: #000;


//input
$footer_newsletter-input-height: 40px;
$footer_newsletter-input-font-size: 14px;

//button
$footer_newsletter-btn-height: $footer_newsletter-input-height;
$footer_newsletter-btn-background-color: #000;
$footer_newsletter-btn-color: #fff;
$footer_newsletter-btn-font-size: 14px;


/* ============================================ *
* Newsletter
* ============================================ */

.footer-newsletter {
    height: 300px;
    display: flex;
    justify-content: center;      
    flex-direction: column;
    text-align: center;
    @include make-fluid-container;
    padding-right: 0;
    padding-left: 0;
	background-image: url('../images/newsletter.png');
	background-position: 50% 50%;
	background-repeat: no-repeat;
    background-size: cover;
    font-size: $footer_newsletter-font-size;
    color: $footer_newsletter-font-color;
    align-items: center;
	@include media-breakpoint-down(md) {
		height: 316px;
	}

}
.newsletter-container {
	@include make-container;
	//padding-top: $footer_newsletter-padding-top;
	//padding-bottom: $footer_newsletter-padding-bottom;

	.title {
		// shouldn't have font size here, this should follow h2 font size
		@include heading-2;
		display: block;
		font-size: 32px;
    	color: $colour_secondary;
    	line-height: 1.88;
    	font-family: $font_family-secondary;
    	@include media-breakpoint-down(md) {
    		font-size: 24px;
    	}
	}
	.sub-title {
		display: block;
		padding: 0 0 27px;   
		margin: 0 auto;
		//max-width: 500px;
		font-family: $font_family-primary; 
		font-size: 16px;
    	color: $colour_secondary;
    	@include media-breakpoint-down(md) {
    		font-size: 12px;
    		padding: 4px 0 23px;
    	}
	}

	.declaration {
		display: block;
		padding-top: 11px;
		font-size: 10px;
		color: $colour_secondary;
		@include media-breakpoint-down(md) {
			margin: 0 20%;
			line-height: 22px;
			padding-top:24px;
		}

		a {
			text-decoration: underline;
			color: $colour_secondary;
		}
	}
}

.subscribe-form {

	.newsletter-field {
		position: relative;
		max-width: 540px; 
		margin: 0 auto;
		padding-left: 30px;
		background: #fff;
		display: flex;
		flex-direction: wrap;
		border: 10px solid #fff;
		&:before {
			@include font-icon('\e91d');
	        position: absolute;
	        left: 5px;
	        top: -4px;
	        bottom: 0;
	        margin: auto;
			height: 12px;
			font-weight: 600;
	        font-size: 16px;
	        @include media-breakpoint-down(md) {
	        	top: -7px;
	        	font-size: 18px;
	        }
		}
		@include media-breakpoint-down(md) {
			border: 8px solid #fff;
		}
		label {
            @include visuallyhidden;
        }

        .control {
        	flex: 0 0 81%;
        	height: 40px;
        	@include media-breakpoint-down(md) {
        		flex: 0 0 71%;
        	}

        	div.mage-error {
    			font-size: 12px;
    			color: #f00;
    			bottom: -8px;
    			position: absolute;
    			right: 24px;
    			left: 0;
        	}
        }
        input {
        	border: 1px solid #fff;
        	width: 100%;
        	line-height: $footer_newsletter-input-height;
        	height: 44px;
        	font-size: 16px;
        	font-family: $font_family-primary;
        	@include media-breakpoint-down(md) {
        		font-size: 12px;
        	}
        	&::placeholder {
        		color: #ccc;
        	}
        	.mage-error {
        	
        		border-color: #f00
        	}

        }
        .actions {
        	flex: 0 0 19%;
        	@include media-breakpoint-down(md) {
        		flex: 0 0 29%;
        	}
        	align-self: flex-start;
			button {
				width: 100%;
	        	span {
	                padding: 0;
	                @include media-breakpoint-down(md) {
	                	font-size: 14px;
	                }
	        	}        
	        }
	    }
	} 
}