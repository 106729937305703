/**

0- Login and Register Page ---- DONE
0- Deafult Forget Password ---- DONE
0- Deafult account pages ---- DONE
0- Account Nav block ---- DONE



01- Account Dashboard ---- DONE -- EMPTY
02- Account My Orders ---- DONE
    2a- Account Order View Page ---- DONE
    2b- Account Order Print Page ---- DONE

03- Account Order by SKU ---- DONE
04- Account My Wishlist ---- DONE add new list not working
05- Account Address Book and Address Form ---- DONE
06- Account Information ---- DONE
    6a- Account 2 columns form mixin
07- Account Gift Card  -- EMPTY
08- Account Reward Points  -- EMPTY
09- Account Gift Registry
10- Account My Product Reviews
11- Account Newsletter Subscriptions ---- DONE
12- Account My Invitations ---- DONE

13- Account Logout Success

**/
$border-default: 1px solid #ccc;
$font_size-sm: 14px;
$font_size-md: 16px;
/* ============================================ *
 * Login and Register Page ---- DONE
 * ============================================ */
.customer-account-login {
    .page-title-wrapper {
        display: block;
        width: 100%;
        .page-title {
            margin: 30px 0;
        }
    }
}
.login-container {
    @include make-container();

    @include media-breakpoint-down(md){
        display: flex;
        flex-direction: column;
    }
    .page-title-wrapper {
        display: block;
        width: 100%;
        margin-bottom: 30px;
        .page-title {
            text-align:center;
        }
    }
    .login-row { // dont remove
        @include make-row();
    }
    // BLOCK
    .block {
        @include make-col-ready;
        @include media-breakpoint-up(md) {
            @include make-col(6);
            float: right;
        }
        text-align: left;
        padding-top: 30px;
        padding-bottom: 30px;
        .block-wrap {
            margin-bottom: 40px;
        }
        // Title H2
        .block-title {
            display: block;
            margin-bottom: 10px;

            strong {
                @include heading-2;
                font-weight: normal;
            }
        }
        // content
        .block-content {

        }
        &.block-customer-login {
            float: left;
            clear: left;
        }
    }
    .form {
        width: 100%;
    }
    // Block Action
    .actions-toolbar {
        margin-top: 15px;
        display: block;
        text-align: right;
        overflow: hidden;
        a.primary {
            @extend button;
        }
        .primary {
            margin-bottom: 0;
            button {
                width: 100%;
                margin-bottom: 0;
            }
        }
        .secondary {
            margin-top: 20px;
            text-align: center;
        }
    }
}
.customer-account-create, .company-account-create, .customer-account-forgotpassword,.magento_invitation-customer_account-create {
    .page-main {
        @include make-container();
        text-align: left;
    }
    .page-title-wrapper {
        display: block;
        width: 100%;
        .page-title {
            text-align:center;
            margin: 50px 0;
        }
    }
    .actions-toolbar {
        display: flex;
        margin-bottom: 40px;
        align-items: center;
        justify-content: space-between;
    }
    .fieldset > .legend {
        display: block;
        margin: 0 0 20px;
        padding: 0 0 10px;
        width: 100%;
        box-sizing: border-box;
        float: left;
        font-weight: 300;
        line-height: 1.2;
        font-size: 1.8rem;
        border-bottom: 1px solid #c6c6c6;
    }
}
/* ============================================ *
 * Deafult Forget Password ---- DONE
 * ============================================ */
.block-forgetpassword {
    margin: 0 auto;
    .note {
        text-align: center;
    }
    .captcha-image {
        text-align: center;
        display: block;
        margin: 15px 0;
    }
    .captcha-reload {
        margin-top: 15px;
        @include media-breakpoint-up(md) {
            margin-top: 5px;
            margin-left: 10px;
        }
    }
}
/* ============================================ *
 * Deafult account pages ---- DONE
 * ============================================ */
.account-pages {
    text-align: left;
    font-size: 14px;
    .page-title {
        margin: 50px 0;
    }
    .page-main {
        @include make-container;
        text-align: left;
    }
    .limiter{
        .limiter-label {
            margin-right: 5px;
        }
        .limiter-text {
            margin-left: 5px;
        }
    }

    .column.main {
        margin-bottom: 60px;
        display: flex;
        flex-direction: column;
        a {
            color: $link_color;
            font-size: $font_size-sm;
        }
    }
    div[data-bind="scope: 'messages'"] {
        position: relative;
        z-index: 905;
        width: 100%;
    }
    .page-title {
        margin: 50px 0;
        @include media-breakpoint-down(sm) {
            margin-bottom: 15px;
        }
    }
    .page-title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        > a {
            display: inline-block;
            padding: 0 20px;
            white-space: nowrap;
            text-align: center;
            text-transform: none;
            color: #000;
            font-size: 16px;
            height: 40px;
            line-height: 36px;
            border: 2px solid #000;
            background: transparent;
        }
    }
    .sidebar.sidebar-main{
        margin-bottom: 60px;
    }
    // removing account info from account dashboard
    .box.box-information{
        display: none;
    }
    .pager {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 15px 0;
    }
    .limiter {
        display: flex;
        align-items: center;
        select {
            width: initial;
            height: 30px;
            margin: 0 10px;
        }
        .limiter-text {
            white-space: nowrap;
        }
    }
    .table-wrapper {
        overflow: auto;
    }
    .table {
        white-space: nowrap;
        th {
            font-family: $font_family-primary-bold;
        }
        th, td {
            padding: 10px;
            border: 0;
        }
        tbody {
            th, td {
                border-top: 1px solid #000;
            }
        }

        .actions .action:first-child:after {
            border-left: 1px solid #000;
            content: '';
            display: inline-block;
            height: 12px;
            margin: 0 10px;
            vertical-align: -1px;
        }
    }
    .block {
        margin-bottom: 50px;
        @include media-breakpoint-down(sm){
            margin-bottom: 20px;
        }
        .block-title {
            strong {
                font-weight: normal;
            }
            @include heading-3;
            margin-bottom: 15px;
            padding-bottom: 15px;
            border-bottom: $border-default;
            line-height: 25px;
            .action {
                float: right;
                font-size: 12px;
                text-decoration: underline;
            }

            &.flexi{
                display: flex;
                justify-content: space-between;
            }
        }
        .block-content {
            @include make-row();
            // margin: 0; not sure why it is here
            > div {
                @include make-col-ready;
            }
            .empty {
               @include make-col-ready;
            }
        }
        .table-wrapper {
            @include make-col-ready;
        }
        .box {
            @include make-col-ready;
            // padding: 0; why is this here
            @include media-breakpoint-up(md) {
                @include make-col(6);

            }
            margin-bottom: 15px;
        }
        .box-title {
            display: block;
            margin-bottom: 5px;
        }
        .box-box-content {

        }
    }
    .box-actions {
        display: flex;
        margin-top: 15px;
        a {
            color: $link_color;
            margin-right: 10px;
            span {
                text-decoration: underline;
            }
            &.change-password {
                text-decoration: underline;
            }
        }
    }
    .actions-toolbar {
        width: 100%;
        @include make-row();
        flex-direction: column;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            justify-content: space-between;
        }
        margin: 30px 0;

        .primary {
            order: 1;
            @include media-breakpoint-up(md) {
                order: 2;
            }
        }
        .secondary {
            order: 2;
            text-align: center;
            @include media-breakpoint-up(md) {
                order: 1;
                text-align: left;
            }
        }

        .action.back {

        }
        button {
            @include media-breakpoint-down(sm) {
                width: 100%;
                margin-bottom: 15px;
            }
        }
    }
    .field-row label {
        margin-bottom: 10px;
        display: inline-block;
    }

    .field-attachment {
        margin: 20px 0;
    }
    // we may need to move this tables.sass to apply on all
    @include media-breakpoint-down(sm) {
        thead {
            th {
                display: none;
            }
        }
        tbody {
            tr {
                &:nth-child(even) {
                    border-top: 4px solid $border_colour-default;
                }
            }
            td {
                display: block;
                &:before {
                    padding-right: 10px;
                    content: attr(data-th) ': ';
                    display: inline-block;
                    font-weight: bold;

                }
            }
            // what is thsi for
            .rating-summary,
            ul {
                display: inline-block;
           }
        }
    }
}
/* ============================================ *
 * Last Order Related Items
 * ============================================ */
.related-order {
    order: 9;
    h3 {
        margin-bottom: 15px;
    }
    .related-order-items {
        li {
            float: left;
        }
        .name {
            display: block;
            margin: 10px 0;
        }
        .slick-next, .slick-prev {
            top:140px;
        }
    }
}

/* ============================================ *
 * Account Nav block ---- DONE
 * ============================================ */
.account-nav {

    padding: 15px;
    background-color: #fafafa;

    div{
        &:focus{
            outline: none;
        }
    }
    .account-nav-title {

        .account-title{
            position: relative;
            @include font-size(18);
            margin-bottom: 10px;
            @include media-breakpoint-down(md){
                &:after{
                    content:"--";
                    position: absolute;
                    right: 0;
                    @include font-size(22);
                }
            }
        }
        @include media-breakpoint-down(md){
            &[aria-expanded="false"]{
                .account-title{
                    &:after{
                        content:"+";
                    }
                }
            }
        }

    }
    .account-nav-content {
        @include media-breakpoint-up(md){
            display: block !important;
        }
        .item {
            margin-bottom: 10px;
            position: relative;
        }
        a,
        strong {
            font-weight: normal;
            color: $link_color;
        }
        a {
            color: $link_color;
            font-family: $font_family-primary;
        }
    }

    .current {
        a,
        strong {
            font-family: $font_family-primary-bold;
        }
    }


    .delimiter {
        border-top: $border-default;
        display: block;
    }
}



/* ============================================ *
 * Account Dashboard ---- DONE
 * ============================================ */




/* ============================================ *
 * Account My Orders ---- DONE
 * ============================================ */
.table-order-items {
    .action.view {
        padding-right: 15px;
    }
}
.sales-order-history{
    a.action.view, a.action.order{
        padding: 2px 10px;
        border: 2px solid #000;
        &:hover{
            background-color: #000;
            color: #fff;
            span{
                color: #fff;
            }
        }
        &:after{
            content:"";
            border: none !important;
            margin:  0 !important;
        }
    }
}

/* ============================================ *
 * Account Order View Page
 * ============================================ */
.orders-view-content {
    .page-title-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 30px;
        h1 {
            flex: 100%;
        }
    }
    .order-actions-toolbar {
        width: auto;
        margin: 0;
        .action.print {
            margin-left: 15px;
        }
    }
}



.items.order-links {
    display: flex;
    display: none;
    @include media-breakpoint-down(sm) {
        flex-direction: column;
    }
    li {
        float: left;
        border: 1px solid $colour_secondary;
        border-bottom: 4px solid transparent;
        padding: 15px;
        margin: 0 -1px -1px 0;
        @include media-breakpoint-down(sm) {
            margin-bottom: -2px;
        }
        &.current {
            border-bottom-color: $colour_secondary;
        }
        a {
            font-size: $font_size-sm;
        }
    }
}
.order-actions-toolbar{
    @include media-breakpoint-up(md){
        height: 40px;
    }

    .actions{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        @include  media-breakpoint-down(sm){
            justify-content: flex-start;
        }
        a{
            text-align:center;
            text-transform: $button-text-transform;
            padding: 0 20px;
            color: $button-color;
            font-size: $button-font-size;
            height: $button-height;
            line-height: $button-line-height;
            font-family: $font_family-primary-bold;
            border: $button-border-size solid $button-border-color;
            background: $button-background;
            &:hover{
                border-color: $button-hover-border-color;
                color: $button-hover-color;
                background: $button-hover-background;
            }
            &:first-child{
                margin-right: 20px;
            }
        }
        .block-requisition-list{
            @include media-breakpoint-down(xs){
                margin-top: 20px;
                margin-left: 0;
            }
        }
    }

}
.sales-order-view{
    .order-status{
		padding: 0 $button-padding;

		white-space:nowrap;

		// Text Styles
		text-align:center;
		text-transform: $button-text-transform;

		color: $button-color;
		font-size: $button-font-size;
		height: $button-height;
		line-height: $button-line-height;
		font-family: $font_family-primary-bold;
		// Custome Styles
		border: $button-border-size dashed $button-border-color;
		background: $button-background;
    }
    .box-order-billing-method {
        .payment-method {
            .title {
                padding-bottom: 10px;
            }
            tr {
                > * {
                    padding-left: 0;
                }
                &:nth-child(even) {
                    border-top: 0;
                }
            }
            td {
                @include media-breakpoint-down(sm){
                    display: table-cell;
                }
            }
        }
    }
    .table-order-items {
        @include media-breakpoint-down(sm){
           white-space: unset;
           tfoot {
                tr {
                    display: flex;
                    justify-content: space-between;
                }

            }
        }
    }
}
.order-details-items {
    border: $border-default;
    padding: 15px;

    a.action.back{
        margin-left: 10px;
        display: inline-block;
		// width: 100%;
		padding: 0 $button-padding;

		white-space:nowrap;

		// Text Styles
		text-align:center;
		text-transform: $button-text-transform;

		color: $button-color;
		font-size: $button-font-size;
		height: $button-height;
		line-height: $button-line-height;
		font-family: $font_family-primary-bold;
		// Custome Styles
		border: $button-border-size solid $button-border-color;
		background: $button-background;
        &:hover {
            border-color: $button-hover-border-color;
            color: $button-hover-color;
            background: $button-hover-background;
        }
    }

    td.image-column {
        padding-left: 0;
        img {
            max-width: 90px;
        }
    }
    td.qty {
        font-size: $font_size-sm;
        li {display: block;}
    }
    .item-options {
        font-size: $font_size-sm;
        dt {
            float:left;
            margin-right: 5px;
        }
    }

    .order-title {
        margin-bottom: 20px;
        border-bottom: $border-default;
        padding-bottom: 10px;
        @include media-breakpoint-down(sm) {
            width: auto;
            display: block;
        }
    }
}

.block {
    &.block-order-details-view {
       margin: 60px 0;
       border: $border-default;
       padding: 30px 15px;
    }
}
/* ============================================ *
 * Account Order Print Page -- DONE
 * ============================================ */
// includer print refund page


.sales-order-printcreditmemo,
.account.sales-order-print {
    .logo {
        max-width: 200px;
        margin: 30px 0 0;
    }
    .order-status,
    .order-date { width: auto; display: inline-block; }
    .order-details-items {
        margin: 30px 0;
        padding-bottom: 30px;
        .actions-toolbar {
            display: none;
            .action.back {
                display: none; // remove back button
            }
        }

    }
}


.account.sales-order-print {
    .page-main{
        padding-top: 0;
    }
    #maincontent {
       @include make-container();
       text-align: left;
    }
    .page-title-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .order-date {
            width: 100%;
            margin-top: 10px;
        }
    }
    .page-title {
        margin-right: 10px;
        @include media-breakpoint-down(xs) {
            @include font-size(24);
        }
    }
    .nav-toggle {
        @include visuallyhidden;
    }
    .order-details-items {

        .order-title {
            text-align: left
        }

        thead {
            th {
                border-bottom :1px solid #ccc;
            }
            @include media-breakpoint-down(xs) {
                @include visuallyhidden;
            }
        }
        tbody {
            tr {
                @include media-breakpoint-down(xs) {
                    display: flex;
                    flex-direction: column;
                }

            }
            td {
                padding: 10px 0;
                @include media-breakpoint-down(xs) {
                    &:before {
                        padding-right: 10px;
                        content: attr(data-th) ': ';
                        display: inline-block;
                        color: #111;
                        font-weight: 700;
                    }
                }
            }
            .items-qty {
                @include media-breakpoint-down(xs) {
                    display: inline-block;
                    vertical-align: top;
                }
            }
        }
        tfoot {
            background: #ededed;
            td,th {
                padding: 10px;
                border: 0;
                @include media-breakpoint-up(sm) {
                    text-align: right;

                }
            }
        }
    }
    .block-order-details-view {
        border: 0;
        margin: 30px 0;
        padding: 0;
        .block-title {
          @include font-size(18);
          border-bottom: 1px solid #ccc;
          margin-bottom: 15px;
          padding-bottom: 10px;
        }
        .block-content {
            display: flex;
            justify-content: space-between;
            @include media-breakpoint-down(xs) {
                flex-direction: column;
            }
        }
        .box {
            @include media-breakpoint-down(xs) {
                margin-bottom: 15px;
            }

        }
        .box-title {
            @include font-size(14);
            margin-bottom: 10px;
            display: block;
        }
        td {
            padding: 0 10px;
        }
        .payment-method {
            border: 0;
        }

    }



}


/* ============================================ *
 * Account Order by SKU ---- DONE
 * ============================================ */

.block-addbysku {
    @include make-container();
    .block-content {
        margin: 0 !important;
    }
    .form-addbysku {
       display: flex;
       flex-wrap: wrap;
    }
    .box {
        width: 100%;
        display: block;
        text-align: left;
    }
    .box-items {
        margin: 20px 0;
        .deletable-item-title {
            display: none;
        }
        .deletable-item {
            display: flex;
            align-items: flex-start;
            flex-grow: 1;
        }
        .field.sku {
            width: 100%;
            margin-right: 15px;
        }
        .field.qty {

        }
        .actions-toolbar {
            padding: 0;
            margin: 36px 0 0 10px;
        }
        .action.remove {
            display: none;
            display: inline-block;
            text-decoration: none;
        }
        input {
            border: 1px solid #cdcdcd;
            line-height: 40px;
            height: 40px;
        }
    }
    .box-upload {
        background: #eee;
        padding: 30px !important;
        .field.sku {
            width: 100%;
        }
        .label {
            @include heading-3;
        }
        .reset {
            margin-top: 15px;
        }
        .note {
            margin: 15px 0;
            display: block;
            p { display: block; }
        }

        input.action-upload{
            color: transparent;
            &::-webkit-file-upload-button {
                visibility: hidden;
            }
            &:before {
                content: 'Upload file';
                color: black;
                display: inline-block;
                background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
                border: 1px solid #999;
                border-radius: 3px;
                padding: 5px 8px;
                outline: none;
                white-space: nowrap;
                -webkit-user-select: none;
                cursor: pointer;
                text-shadow: 1px 1px #fff;
                font-weight: 700;
                font-size: 10pt;
            }

        }
    }
    .field.upload.skus {
        margin: 20px 0 0;
    }
    .form-addbysku > .actions-toolbar {
        margin: 40px 0;
    }
    .control {
        display: flex;
        flex-direction: column;
        [role="status"] {
            order: 9;
        }
    }
    .remove {

    }
}


/* ============================================ *
 * Address Book and Address Form ---- DONE
 * ============================================ */
@mixin account-forms-2columns {
    .fieldset {
        @include make-col-ready;
        @include media-breakpoint-up(md) {
            @include make-col(6);
        }
        margin-bottom: 60px;


        .legend {
            float: left;
        }
        .field {
            float: left;
            width: 100%;
            display: block;
            clear: both;
        }
        .nested {
            overflow: hidden;
            margin-top: 15px;

            div:last-child { margin-bottom: 0; } // to make space even don't remove
        }
        .message {
            clear: both;
            display: block;
        }
    }

    .actions-toolbar {
        padding: 0 15px;
    }
}

.form-address-edit {
    @include make-row();
    @include account-forms-2columns;

}



/* ============================================ *
 * Account information
 * ============================================ */
.form-edit-account {
    @include make-row();
    @include account-forms-2columns;
}


/* ============================================ *
 * Account Gift Card
 * ============================================ */



/* ============================================ *
 * Account Reward Points
 * ============================================ */
.block-reward-history {
    .block-content {
        padding: 0 15px;
    }
 }



/* ============================================ *
 * Account Gift Registry
 * ============================================ */
.magento_giftregistry-index-edit {
    .giftregistry-type {
        width: 100%;
        padding: 15px 0;
    }
    br {
        display: none;
    }
    .field.date button {
        margin-top: 15px;
    }
}
#registrant-container{
    .actions-toolbar {
        margin: 0;
    }
}


/* ============================================ *
 * Account MY product reviews
 * ============================================ */
.review-customer-view {
    .product-name {
        font-size: $font-xl;
    }
    .product-details {
        display: flex;
        margin-bottom: 50px;
    }
    .rating-average-label {
        @include visuallyhidden;
    }
    .product-reviews-summary {
        display: flex;
        margin: 10px 0;
    }
    .reviews-actions {
        margin: 0;
    }
    .review-details {
        > div {
            margin-bottom: 10px;
        }
    }
    .title {
        border-bottom: $border-default;
        margin-bottom: 25px;
        padding-bottom: 10px;
        font-size: $font-lg;
    }

}

/* ============================================ *
 * Account Newsletter Subscriptions
 * ============================================ */
.newsletter-manage-index{
    .news_customer_msg{
        a{
            font-family: "CeraPro-Regular", Times, serif;
            font-size: 16px !important;
        }
    }


    .form-newsletter-manage{
        fieldset.fieldset {
            margin-left: 50px;
            .field{
                >label{
                    font-weight: bolder;
                }
            }
        }

        li.field.no-label{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            line-height: 15px;
            height: 15px;
            margin-bottom: 10px;
        }
        label.field.label{
            font-weight: normal !important;
        }
        .fieldset .field:not(.choice) input {
            width: 20px;
        }
    }
}

/* ============================================ *
 * company_credit-history-index
 * ============================================ */
.company_credit-history-index {
    @include media-breakpoint-up(md) {
        .columns {
            flex-wrap: nowrap;
        }
        .column.main {
            padding: 0;
        }
    }
    .column.main {
        @include media-breakpoint-down(lg) {
            width: 99%;
            overflow-x: auto;
        }
    }
    #pay_balance {
        font-size: 16px;
        font-family: $font_family-primary-bold;
        line-height: 40px;
        height: 40px;
        text-align: center;
        border: 2px solid #000;
        max-width: 160px;
        display: block;
        text-transform: capitalize;
        padding: 0 20px;
    }
    .credit-balance-list {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        padding:20px;
        background:rgb(238, 238, 238);
        font-size: 14px;
        font-family: $font_family-primary-bold;
    }
    .modal-popup._inner-scroll .modal-inner-wrap {

        max-height: 100%;
    }
    .modal-header{
        width: 100%;
        text-align: center;
        padding-top: 20px;
        h1{
            width: 100%;
        }
    }

    .modal-inner-wrap {
        max-width: 620px;
        width: 100%;
        .modal-popup .modal-header {
            padding:20px;
        }
        .action-close {
            span {
                font-size: 0;
                padding:0;
                border:0;
                &:before{
                    content: "x";
                    font-size: 30px;
                    font-family: $font_family-primary-bold;
                    display: inline-block;
                    border:0;
                }
                &:hover {
                    background:transparent;
                    color: #000;
                }
            }
        }
        .modal-content{
            padding-bottom:20px;
            h4 ,h3 {
                font-size:16px;
                padding:20px 0;
                font-family: $font_family-primary-bold;
            }
            form {
                display: flex;
                justify-content: center;
                align-items:center;
                input {
                    min-width: 300px;
                    height: 40px;
                    line-height: 40px;
                    padding: 0 20px;
                    border: 2px solid #000;
                }
                button {
                    line-height: 40px;
                    height: 40px;
                    padding: 0 25px;
                    text-align: center;
                    border: 2px solid #000;
                    border-left:0;
                    text-transform: capitalize;
                    font-family: $font_family-primary-bold;
                }
            }
        }
    }
}

.paybalance-pay-available {
    .actions-toolbar {
        margin: 0 auto;
        text-align: center;
    }
    #load-dropin-form-button, .action.primary.checkout {
        margin: 20px auto;
    }
    .form-container {
        padding: 16px;
        border: 2px solid #000;
    }
    .payment-method {
        iframe {
            height: auto !important;
        }
    }
    .companycredit-opayo {
        > legend {
            font-size: 18px;
            margin: 30px auto;
        }
    }
}



/* ============================================ *
 * Account My Invitations
 * ============================================ */
.form-add-invitations {
    .fields {
        .actions-toolbar {
            width: 24%;
            float: right;
            display: inline-block;
            margin: 15px 0 0;
            border: 0;
            .secondary {
                flex: 0 0 100%;
            }
        }
        .field {
            width: 74%;
            float: left;
        }
        .secondary .remove span {
            width: initial;
        }
    }
}



/* ============================================ *
 * My Account
 * ============================================ */
.block-reviews-dashboard {
    margin: $margin 0;
    .item {
        margin: $margin 0;
    }
}

/* ============================================ *
 * Account Logout Success
 * ============================================ */
.customer-account-logoutsuccess {
    .page-main {
        text-align: center;
    }
    .page-title-wrapper{
        padding-top: 100px;
    }
    p{
        padding-top: 15px;
        padding-bottom: 50px;
    }
}


// need review
.customer-account-createpassword {
    form.reset {
        width: 100%;
        max-width: 480px;
        margin: 0 auto 80px;
        text-align: left;
    }
    .page-title {
        text-align: center;
    }
 }
 /* ============================================ *
 * Account Frequently Purchased
 * ============================================ */
.customer-frequently-purchased {
    .actions-primary {
        form {
            display: flex;
            flex-direction: column;
        }
    }
    .cart-content {
        display: flex;
    }
    .old-price {
        @include visuallyhidden();
    }
    .price-label {
        @include font-size(16);
        font-family: $font_family-primary;
    }
    .price-box.price-final_price {
        display: flex;
    }
    .price-box {
        @include media-breakpoint-up(md) {
            margin: 0;
        }
    }
    .price-wrapper {
          @include font-size(18);
        &:after {
            content: "each";
            margin-left: 3px;
            @include font-size(18);
        }
        .price {
            font-size: 18px !important;
            line-height: inherit !important;
        }
     }
    .product-price {
        @include media-breakpoint-up(md) {
            min-height: 80px;
        }
    }
    .minimal-price-link {

        .price-label {
            @include visuallyhidden();

        }
         ._title ,.price-final_price{
            @include font-size(18);
         }

    }
    .price-wrapper  {
        margin-top: 23px;
        display: block;
    }
    .price-label + .price-wrapper  {
        margin-top: 0;
    }

}


#reorder-quote-popup {
    .actions-toolbar {
        @include media-breakpoint-up(md) {
            margin: 0 -10px;
            justify-content: flex-start;
            button {
                margin: 0 10px;
            }
        }
    }
}
