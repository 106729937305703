
.toolbar {
    display: flex;
    flex-direction: row;

    .sorter {

    }
    .pages {
        .label { @include visuallyhidden; }
        ul {
            display: flex;
            flex-direction: row;
            @include media-breakpoint-down(md) {
                border-top: 2px solid #eeeeee;
                padding: 25px 0;
            }
        } 
        li {
            width: 22px;
            height: 25px;
            margin: 0 3px;
            padding: 0;
            font-size: 16px;
            line-height: 25px;
            border:0;
            a {
                display: block;
                color: #b8b8b8;
                &:hover {
                    color: $primary-colour;
                }
            }

            &.current {
                a{
                    color: $primary-colour;
                }
            }
            &:not(.pages-item-next) , &:not(.pages-item-previous) {
                @include media-breakpoint-down(sm) {
                    margin: 0 8px; 
                }
            }
            &.pages-item-next,
            &.pages-item-previous {
                width: auto;
                border: none;
                padding: 0 77px;
                @include media-breakpoint-down(sm) {
                    padding: 0 40px;
                    margin: 0;  
                }
                a {
                    font-size: 0;
                    color: $primary-colour;
                    &:before{
                        @include font-icon('\e940');    
                        font-size: 16px;
                        line-height: 26px; 
                    }
                }
            }
            &.pages-item-previous a {
                transform: rotate(-180deg);
                line-height: 18px;
            }
        }
    }
    
}
.products-grid + .toolbar-products {
    padding: 73px 0 103px; 
    @include media-breakpoint-down(sm) {
        margin: 24px 0;
        padding: 0;
    }
}
.toolbar-products {    
    margin: 24px 0 10px;
    @include media-breakpoint-down(md) {
        padding: 0 15px;
        margin: 0;
    }
    .selectric {
        .label {
            padding: 0 30px 0 16px; 
        }
    }
    &:last-of-type {
       
    }
    .toolbar-amount{
        display: block !important;
        margin-right: 0;
        font-size: 16px;
        line-height: 70px;
        font-family: $font_family-primary;
        text-align: left;
        color:#b8b8b8;
        @include media-breakpoint-down(md) {
            display: none !important;
        }
        .number-amount{
            color: $primary-colour;
            font-family: $font_family-primary-bold;
            &:last-child{
                color: #cfcfcf;
            }
        }
    }
    .sorter {
        margin-left: auto;
        text-align: right;
        @include media-breakpoint-down(sm){
            width: 100%;
            margin: 20px 10px;
        }
        .selectric-wrapper {
            width: 100%;
            margin: 0;
        }
        label {
            display: block;
            text-align: left;
            padding: 0 0 4px 0px;
            color: #ccc9c9;
            font-size: 10px;
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
        .sorter-action {        
          display: none;
        }
    }
}
// bottom toolbar
.toolbar-products {
    .products.wrapper ~ & .pages {
            margin: 0 auto 20px;
            display: block !important;
    }
}
#loadmore{
    .load-more-products{
        display: none;
    }
}
/**********************
** STYLING
********************/
.toolbar-sorter{
    .selectric {
        border: 1px solid #eeeeee;
        .label{
            color: #000;
            font-family: "CeraPro-Bold", Times, serif;
            font-size: 12px;
        }
    }
}