/* ============================================ *
 * Message Global Setting
 * ============================================ */

.message {
	@include make-container;
	margin-top:$margin;
	margin-bottom:$margin;
	padding: $padding;
	display: block;
	position: relative;
	> :first-child::before {
		margin-right: $margin;
	}
	a {
		color: #1979c3;
	}
}

/* ============================================ *
 * Error and Warning Message -Red
 * ============================================ */
.message.error {
	background: #fae5e5;
	color: #e02b27;
}
.message.warning {
	background: #fdf0d5;
	color: #6f4400;
}

/* ============================================ *
 * Success Message - Green
 * ============================================ */
.message.success {
	background: #e5efe5;
	color: #006400;
}
/* ============================================ *
 * Notice Message - Yellow
 * ============================================ */
.message.notice {
	background: #fdf0d5;
	color: #6f4400;
}
/* ============================================ *
 * Notice Info - Brown
 * ============================================ */
 .message.info {
	background: #fdf0d5;
	color: #6f4400;
}
