/* ============================================ *
 * Contact Page
 * ============================================ */
.contact-index-index{
  .page-title-wrapper{
     text-align: center;
  }
  .form-container {
    @include make-col-ready();
    margin: 0;
    padding: 0;       
    @include media-breakpoint-up(md)  {
      @include make-col(7);
      padding: 88px 0  80px 80px;
    }
  }
  .before-form {
    @include make-col-ready();
    @include media-breakpoint-down(md) {
      padding:0;

    }
    .no-label{  
      @include make-col-ready();
      margin: 0;
      padding-bottom: 37px;
      text-align: left;
      @include font-style(16, 2, 1px);
      @include media-breakpoint-down(md) {
        padding: 0;
        @include font-style(16, 1.5, normal);
      }
    }
    .button-mobile {
      display: none;
      @include media-breakpoint-down(md) {
        display: block;
        text-align: left;
        margin: 25px 0 50px;
        span{
          background: $secondary-colour;
          width: 160px;
          border: 2px solid $primary-colour;
          color: $primary-colour;
          text-align: center;
          padding: 0;
          font-weight: bold;
          font-size: 14px;
        }
      }
    }
  }
  .left-section {
      @include make-col-ready();
      padding: 30px 0;
      font-family: $font_family-primary;
      color: $primary-colour; 
      text-align: left;
      border-right: 0;
      @include media-breakpoint-up(md) {
        @include make-col(5);
        padding: 96px 82px 95px 0;
        border-right: 1px solid #cccccc;
      }
      .top {
        @include font-style(31, normal, 1px);
        padding-bottom: 35px; 
        display: block;
        text-transform: uppercase;
        @include media-breakpoint-down(md) {
          @include font-style(24, 1.5, normal);
        }
      }
      .title {
        @include font-style(16, 1.88, normal);
        display: block;
        padding-bottom: 40px;
      }
      .contact-link {
        font-family: $font_family-primary-bold;
        @include font-style(18, normal, normal);
        margin-bottom: 30px;
        display: inline-block;
        border-bottom: 2px solid $primary-colour;
        line-height: 16px;
        @include media-breakpoint-down(md) {
          margin-bottom: 50px;
        }
      }
      .open-time {
        display: none;
        @include media-breakpoint-down(md) {
          display: block;
          padding-bottom: 25px;
          @include font-style(24, 1.5, normal);
        }
      }
      .time {
        @include font-style(18, 1.67, normal);
        display: block;
        padding-bottom: 11px;
      }
      strong {
        font-family: $font_family-primary-bold;
        display: block;
      }
      .call-center {
        font-size:18px;
        font-family: $font_family-primary-bold;
        margin: 45px 0;
        display: inline-block;
        border-bottom: 2px solid $primary-colour;
        line-height: 19px;
      }
  }
  .form.contact{
    float: none !important;
    margin: auto;
    @include media-breakpoint-up(lg) {
       display: block;
       width: 100% !important;
    }
    @include media-breakpoint-up(md) {
      @include make-col-ready();
    }
    @include media-breakpoint-down(md) {
       @include make-col-ready();
       padding: 0;
    }
    .mage-error {
      color: #f54141;
    }
    input.input-text {
      line-height: 60px;
      height: 60px;
      padding: 0 10px;
      font-family: $font_family-primary-bold;
      border: 2px solid #dddddd;
      &::placeholder {
        color: #dddddd;
      }
      &:focus {
        border: 2px solid #4a4a4a;
      }
    }
    textarea {
      border: 2px solid #dddddd;
      min-height: 240px;
      padding: 21px 18px;
      &::placeholder{
        color: #dddddd;
      }
      &:focus {
        border: 2px solid $primary-colour;
      }
    }
    .selectric-below .selectric {
      .label {
        color: #000;
      }
    }
    .selectric {
        position: relative;
        text-align: left;
        border: 2px solid #dddddd;
        height: 60px;
        line-height: 60px;  
        .label {
          height: 55px;
          color: #ddd;
          line-height: 60px;
          display: block !important;
        }
        .button {
          color: #b8b8b8;
          line-height: 34px;
          top: 17px;
          &:after {
            font-size: 8px;  
          }
        }
        &:focus {
        border: 2px solid $primary-colour;
      }
    }
    .selectric-open , .selectric-hover {
      .selectric{
        border: 2px solid $primary-colour; 
      }
    }
    button {
      margin-top: 20px;
      span {
          width: 143px;
          padding: 0 17px;
          color: $primary-colour;
          font-size: 16px;
          line-height: 35px;
          border: 2px solid $primary-colour;
          font-family: $font_family-primary-bold;
          background:$secondary-colour;
      }
    }       
    .label{
      text-align: left;
      display: none;
    }
    textarea {
      width: 100%;
    }
    .required-container {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      @include media-breakpoint-down(md) {
         
      }
    }
    .field:not(.no-label):not(.comment) {
      @include make-col-ready();
      @include make-col(6);
      padding-bottom: 29px;
      @include media-breakpoint-down(md) {
        @include make-col(12);
      }
      text-align: left;
    }
    .field:not(.no-label).email{
      @include make-col(12);
      @include make-col-ready();
    }
    .comment {
      @include make-col-ready();
      padding-bottom: 18px;
    }
    .form-bottom {
      @include make-container();
      text-align: left;
      @include font-style(10, normal, normal);
      color: #b8b8b8;
      a {
        font-weight: bold;
        text-decoration: underline;
        color: #b8b8b8;
      }
    }
    .actions-toolbar:not(.button-mobile) {
       margin-bottom: 8px;
       @include media-breakpoint-down(md) {
       }
    }
    .actions-toolbar {
      .submit {
          text-align: center;
          margin: 0 auto;
        @include media-breakpoint-down(md) {
        }
      }
    }
  }
  .fieldset {
    margin-bottom: 42px !important;
  }

}

.contact-container {
  @include make-container(); 
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .follow-social {
    overflow: hidden;
    border-top: 1px solid #cccccc;
    width: 100%;
    display: block;
    padding: 20px 0;
    span {
      display: block;
      font-size: 24px;
    }
    .footer-socials a {
      font-size:35px;
    }
  }
}
.account { 
  .box-newsletter{
    padding-left:0;     
  }
  &.sales-order-view{
    .requisition-list-button{
      margin-bottom: 20px;
    }
    .action.new{
      @include media-breakpoint-up(md){
        width: 257px;
      }
      width: 212px;
      font-family: "CeraPro-Bold", Times, serif;
      padding: 0 4px;
      span{
        border:0;
      }
    }
  }
}

